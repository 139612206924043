import React from "react";
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import AssessmentIcon from '@mui/icons-material/Assessment';
import {Box, Grid} from "@mui/material";
import {Outlet, useLocation, useNavigate} from "react-router";
import {useSelector} from "react-redux";
import ErrorBox from "../Common/ErrorBox";
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
//import {TreeItem, TreeView} from "@mui/lab";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PaymentsIcon from '@mui/icons-material/Payments';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
const ADMIN_TABS = [
    {
        label: "Заказы",
        icon: <ManageSearchIcon/>,
        path: "/admin/orderHistory",
        nodes: []
    },
    {
        label: "Belcoins",
        icon: <BorderColorIcon/>,
        path: "/admin/control",
        nodes: []
    },
    {
        label: "Продукты",
        icon: <AddBusinessIcon/>,
        path: "/admin/productControl",
        nodes: []
    },
    {
        label: "История начислений",
        icon: <AssessmentIcon/>,
        path: "/admin/report",
        nodes: []
    },
    {
        label: "Неактивные сотрудники",
        icon: <NotInterestedIcon />,
        path: "/admin/inactive",
        nodes: []
    },
    {
        label: "Бюджет",
        icon: <PointOfSaleIcon />,
        nodes: [
            {
                label: "Управление бюджетом",
                icon: <PriceCheckIcon />,
                path: "/admin/budgetControl",
                nodes: []
            },
            {
                label: "История",
                icon: <PaymentsIcon />,
                path: "/admin/budgetHistory",
                nodes: []
            },
        ]
    },
    {
        label: "Seniority",
        icon: <WorkspacePremiumIcon />,
        path: "/admin/bonus",
        nodes: []
    },
]

export default function Admin(props) {
    const sessionState = useSelector((state) => state.data.UserSession.user);
    const isUserHasAdminRole = sessionState.isAdmin;
    const navigate = useNavigate();

    return (
        isUserHasAdminRole ?
        <Box className={"adminTab"}>
            <Grid container spacing={0.1}>
                <Grid item xs={2}>
                    <SimpleTreeView
                        defaultCollapseIcon={<ExpandMoreIcon />}
                        defaultExpandIcon={<ChevronRightIcon />}
                        sx={{ flexGrow: 1, maxWidth: 250, overflowY: 'auto', overflowX: 'clip', "&& .Mui-selected": {
                                backgroundColor: "#ed6c022e"
                            }}}
                    >
                        {
                            ADMIN_TABS.map((menu, index) => {
                                if (menu.nodes.length !== 0) {
                                    const mainIndex = index;
                                    return  (
                                   <TreeItem itemId={`main ${mainIndex + 1}`} label={menu.label}>
                                       {
                                           menu.nodes.map((object, index) => {
                                               return <TreeItem itemId={`menu ${index + 1}`} label={object.label} onClick={() => navigate(object.path)}/>
                                           })
                                       }
                                   </TreeItem>)
                                } else {
                                    return <TreeItem itemId={`item ${index + 1}`} label={menu.label} onClick={() => navigate(menu.path)}/>
                                }
                            })
                        }
                    </SimpleTreeView>
                </Grid>
                <Grid item xs={10}>
                    <Outlet/>
                </Grid>
            </Grid>

        </Box> :
            <ErrorBox error={"Доступ запрещен"}/>
    );

}
