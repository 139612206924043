import {Box} from "@mui/material";
import LinkTabs from "../../Common/LinkTabs";
import {Outlet, useLocation} from "react-router";
import * as React from "react";
import {getTabState} from "../../../core/utils/utils";
import {useSelector} from "react-redux";
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import PaymentsIcon from '@mui/icons-material/Payments';

const BUDGET_TAB = [
    {
        label: "Управление бюджетом",
        icon: <PriceCheckIcon/>,
        path: "budgetControl"
    },
    {
        label: "История",
        icon: <PaymentsIcon/>,
        path: "budgetHistory"
    },
];
export default function Budget() {
    const location = useLocation();
    let rulesTabState = getTabState(BUDGET_TAB, location.pathname)
    const sessionState = useSelector((state) => state.data.UserSession)
    const isUserHasAdminRole = sessionState.user.isAdmin;
    const isUserHasModeratorRole = sessionState.user.isManager;

    return (
        <Box>
            <LinkTabs
                tabs={BUDGET_TAB}
                state={rulesTabState}
                hideAdminTab={!isUserHasAdminRole}
                hideRequestTab={!isUserHasModeratorRole}
            />
            <Outlet/>
        </Box>

    );
}