import {Box, CircularProgress, Typography} from "@mui/material";
import React from "react";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {useGetAllAccrualRequestsQuery} from "../../core/api/accrual-request-api";
import ActionAccrualRequestButton from "./ActionAccrualRequestButton";
import UserIdsListComponent from "./UserIdsListComponent";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'authorName', headerName: 'ФИО автора', width: 150},
    {field: 'category', headerName: 'За что начисляем', width: 150},
    {field: 'period', headerName: 'Период', width: 90},
    {field: 'receivers', headerName: 'Получатели', width: 200,
        renderCell: (params) => {
            return <UserIdsListComponent data={params.row.receivers}/>;
    }
    },
    {field: 'sum', headerName: 'Belcoins', width: 80,},
    {field: 'status', headerName: 'Статус', width: 200,
        renderCell: (params) => {
            if (params.row.status === 'APPROVED') {
                return <div>Начислено</div>;
            } else if (params.row.status === 'REJECTED') {
                return <div>Отклонено</div>;
            }
            return <ActionAccrualRequestButton request={params.row}/>;
        }
    },
    {field: 'reason', headerName: 'Комментарий', width: 200}

];
export default function RequestOfAccrualAdmin() {

    const {data: accrualRequest = [], isSuccess} = useGetAllAccrualRequestsQuery();

    return(
        isSuccess ?
            <Box>
                <Typography variant="h6" sx={{mb: 2, fontWeight: 'bold'}}>
                    Заявки на начисление белкоинов
                </Typography>
                <Box sx={{width: '100%', height: 600, mt: 2}}>
                    <DataGrid
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rowHeight = {85}
                        rows={accrualRequest}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Box>
            </Box> :
            <CircularProgress/>
    );

}