import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions, DialogContent} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {useDeleteProductMutation} from "../../core/api/products-api";
import {useEffect, useState} from "react";
import ResponseDialog from "../ResponseDialog";
import prod from "../../config/prod";

export * as React from 'react'

export default function DeleteProductDialog (props) {
    const {onClose, open, productId, productName} = props;
    const [deleteProduct, deleteResponse] = useDeleteProductMutation()

    const [state, setState] = useState({
        isDeleteResponseDialogOpen: false,
        isDeleteResponseReady: false
    })

    useEffect(() => {
        const isReadyToDialog = (deleteResponse.status === 'rejected' || deleteResponse.status === 'fulfilled')
        if (isReadyToDialog) {
            setState({
                ...state,
                isDeleteResponseReady: true,
                isDeleteResponseDialogOpen: true
            })
        }
    }, [deleteResponse])

    const handleOnClose = () => {
        setState({
            ...state,
            isDeleteResponseReady: false,
            isDeleteResponseDialogOpen: false
        })
        onClose(true);
    }
    const handleDeleteProduct = async () => {
      deleteProduct({productId: productId})
    }
    return <Dialog onClose={handleOnClose}
                   open={open}>
        <DialogTitle>
            {"Удаление продукта"}
        </DialogTitle>
        <DialogContent>
            Удалить продукт {productName}?
        </DialogContent>
        <DialogActions>
            <Button
                size="small"
                color="secondary"
                onClick={handleOnClose}>
                Отмена
            </Button>
            <LoadingButton
                size="small"
                onClick={handleDeleteProduct}
                loading={state.isRequestLoading}
                variant="outlined"
                color="warning"
            >
                Удалить
            </LoadingButton>

        </DialogActions>

        {state.isDeleteResponseReady &&
            <ResponseDialog onClose={handleOnClose}
                            open={state.isDeleteResponseDialogOpen}
                            responseData={deleteResponse}
                            label={"Удаление продукта"}
                            refetch={props.refetchProduct}/>
        }

    </Dialog>
}

DeleteProductDialog.propTypes ={
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    productId: PropTypes.string.isRequired,
    productName: PropTypes.string.isRequired,
    refetchProduct: PropTypes.func.isRequired,

}