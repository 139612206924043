import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, Button, CardActionArea, CardActions, Chip, Tooltip} from '@mui/material';
import PropTypes from "prop-types";
import {useNavigate} from "react-router";
import EditIcon from '@mui/icons-material/Edit';
import PublicIcon from '@mui/icons-material/Public';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateProductDialog from "../Admin/CreateProductDialog";
import {useState} from "react";

import {useSelector} from "react-redux";
import DeleteProductDialog from "../Shop/DeleteProductDialog";
import ProductPrice from "../Common/PriceComponent";
import ResponseDialog from "../ResponseDialog";
import ProductCard from "../Shop/ProductCard";
import {Alert} from "@mui/lab";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {ThumbUpOffAlt} from "@mui/icons-material";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import {useSetHideForProductMutation, useUpdateProductMutation} from "../../core/api/products-api";

export default function AdminProductCard(props) {
    const [state, setState] = useState({
        isResponseDialogOpen: false,
        responseData: {
            isUpdate: false,
            responseData: {}
        },
        isEditProductDialog: false,
        isCopyProductDialog: false,
        isDeleteProductDialogOpen: false,
    });
    const [setHideForProduct, updateResponse] = useSetHideForProductMutation();
    const navigate = useNavigate();

    const createInStockInfo = (product) => {
        let infoContent ;
        if (product.variations === null || product.variations.length === 0) {
            if (product.storageAmounts.length === 0) {
                infoContent = getInStockInfo("","Товар закончился на складе");
            } else {
                let empty = [];
                let inStok = [];
                product.storageAmounts.forEach(item => {
                    if(item.amount === 0) {
                        empty.push(item.location);
                    } else {
                        inStok.push(item.location + ": " + item.amount)
                    }
                })
                let emptyInLocation = "";
                let inStockInLocation = "";

                if (empty.length !== 0) {
                    emptyInLocation = "Закончился - " + empty.join(", ");
                }
                if (inStok.length !== 0) {
                    inStockInLocation = "Есть на складе - " + inStok.join(" шт, ") + " шт";
                }
                infoContent = getInStockInfo(inStockInLocation,
                    emptyInLocation);
            }
        } else {
            if (product.storageAmounts === null || product.storageAmounts.length === 0) {
                infoContent = getInStockInfo(" ", "Закончились все размеры");
            } else {
                let emptyInLocation = [];
                let inStockInLocation = [];
                product.storageAmounts.forEach(item => {
                    let empty = [];
                    let inStok = [];
                    if (Object.keys(item.variationsAmountMap).length !== 0 && item.variationsAmountMap!== null && item.variationsAmountMap.size !== 0) {
                        item.variationsAmountMap.forEach((value, key) => {
                            if (value === 0) {
                                empty.push(key)
                            } else {
                                inStok.push(key + "-" + value + "шт")
                            }
                        })
                    }
                    if (empty.length !== 0) {
                        emptyInLocation.push(item.location + ": " + empty.join(", "));
                    }
                    if (inStok.length !== 0) {
                        inStockInLocation.push(item.location + ": " + inStok.join(", "));
                    }
                })
                let emptyWarning = "";
                if (emptyInLocation.length !== 0) {
                    emptyWarning = "Закончились - " + emptyInLocation.join(", ");
                }
                let inStockMessage = "";
                if (inStockInLocation) {
                    inStockMessage = "На складе - " + inStockInLocation.join(", ");
                }
                infoContent = getInStockInfo( inStockMessage, emptyWarning);
            }
        }
        return infoContent;
    }

    const getInStockInfo = (textInfo, textWarning) => {
            return<Box>
                <Tooltip placement={"top"}
                         title={<Typography fontSize={16}>{textInfo + " " +textWarning}</Typography>}>
                    <Alert className={"admin-instock-info"}
                           icon={textWarning !== "" ? <ReportProblemOutlinedIcon
                               style={{ color: '#ff0000' }}
                               fontSize="inherit" /> :
                               <CheckCircleOutlineIcon
                                   style={{ color: '#03907f' }}
                                   fontSize="inherit" />
                    }
                           severity={textWarning !== "" ? "warning": "success"}>
                        {(textWarning !== "" ? textWarning + ". " + textInfo : textInfo)}
                    </Alert>
                </Tooltip>
            </Box>;

    }


    //TODO переделать на компонет линк
    const handleCardClick = () => {
        navigate('/products/' + props.product.id, {state: props.product})
    }
    const handleHideButton = () => {
        setHideForProduct({
            objectId: props.product.id,
            isHidden: true
        })
    }
    const handleShowButton = () => {
        setHideForProduct({
            objectId: props.product.id,
            isHidden: false
        })
    }

    const handleDeleteProduct = () => {
        setState({
            ...state,
            isDeleteProductDialogOpen: true
        })
    }
    const handleEditButton = () => {
        setState({
            ...state,
            isEditProductDialog: true,
            isCopyProductDialog: false,
        })
    }
    const handleCopyButton = () => {
        setState({
            ...state,
            isEditProductDialog: true,
            isCopyProductDialog: true,
        })
    }

    const handleClose = (responseData) => {
        setState({
            ...state,
            isResponseDialogOpen: responseData.isResponseReady,
            responseData: responseData.data,
            isEditProductDialog: false,
            isDeleteProductDialogOpen: false
        })
    };

    const handleResponseDialogClose = () => {
        setState({
            ...state,
            isResponseDialogOpen: false
        })
    }


    return (
        <Box sx={{p: 1}} className={"product-card admin-product-card"}>
            <Card sx={{display: 'flex'}}>
                <Box sx={{display: 'flex', flexDirection: 'column'}}>
                </Box>
                <CardMedia
                    component="img"
                    height="300"
                    src={props.product.images[0]}
                    image={props.product.images[0]}
                    alt={props.product.title}
                    onClick={handleCardClick}
                />
                <CardContent sx={{ flex: '1 0 auto' }}>
                    <Typography
                        gutterBottom
                        component="div">
                        {props.product.title +"" +(props.product.isSeniority ? " (seniority)" : "")}
                    </Typography>
                    <Chip
                        icon={<PublicIcon/>}
                        label={"Доступен в локациях: " + (props.product.availability ? props.product.availability.join(', ') : "")}
                        variant={"outlined"}
                        size={"small"}
                        color="secondary"/>
                    {
                        createInStockInfo(props.product)
                    }
                </CardContent>
                <CardActions sx={{display: 'flex', alignItems: 'end'}}>
                            <Box sx={{mb: 2}}>
                                { props.product.isHidden ?
                                    <Chip
                                        label="Show"
                                        color="warning"
                                        icon={<VisibilityIcon/>}
                                        onClick={handleShowButton}/>:
                                    <Chip
                                        label="Hide"
                                        color="secondary"
                                        icon={<VisibilityOffIcon/>}
                                        onClick={handleHideButton}/>

                                }
                                <Chip
                                    sx={{ml: 1}}
                                    label="Edit"
                                    color="primary"
                                    icon={<EditIcon/>}
                                    onClick={handleEditButton}
                                />
                                <Chip
                                    sx={{ml: 1}}
                                    label="Copy"
                                    color="info"
                                    icon={<ContentCopyIcon/>}
                                    onClick={handleCopyButton}
                                />
                                <Chip
                                    sx={{ml: 1}}
                                    label="Delete"
                                    color="error"
                                    onDelete={handleDeleteProduct}/>
                            </Box>
                </CardActions>
            </Card>
            <CreateProductDialog
                open={state.isEditProductDialog}
                onClose={handleClose}
                isEditMode={true}
                isCopyMode={state.isCopyProductDialog}
                product={props.product}
                refetchProduct={props.refetchProduct}
            />
            <DeleteProductDialog onClose={handleClose}
                                 open={state.isDeleteProductDialogOpen}
                                 productId={props.product.id}
                                 productName={props.product.title}
                                 refetchProduct={props.refetchProduct}
            />
            {state.responseData &&
                <ResponseDialog
                    onClose={handleResponseDialogClose}
                    open={state.isResponseDialogOpen}
                    responseData={state.responseData.responseData ? state.responseData.responseData : {}}
                    label={state.responseData.isUpdate ? "Редактирование продукта" : "Создание продукта"}
                    refetch={props.refetchProduct}
                />
            }
        </Box>

    );
}
ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
    isAdminMode: PropTypes.bool.isRequired,
    refetchProduct: PropTypes.func.isRequired,
};
