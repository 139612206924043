import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions, DialogContent, TextField} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {useApproveRequestMutation, useRejectRequestMutation} from "../../core/api/accrual-request-api";
import ResponseDialog from "../ResponseDialog";


export * as React from 'react'

export default function ApproveConfirmDialog(props) {
    const {onClose, open, requestId} = props;
    const [approveRequest, approveRequestResponse] = useApproveRequestMutation();
    const [rejectRequest, rejectRequestResponse] = useRejectRequestMutation();

    const [state, setState] = useState({
        comment: "",
        isConfirmResponseDialogOpen: false,
        isConfirmResponseReady: false,
        isRequestLoading: false,
        errors: {}
    })

    useEffect(() => {
        const isReadyToDialog = (approveRequestResponse.status === 'rejected' ||
            approveRequestResponse.status === 'fulfilled') || (rejectRequestResponse.status === 'rejected' ||
            rejectRequestResponse.status === 'fulfilled')
        if (isReadyToDialog) {
            props.refetch();
            setState({
                ...state,
                isConfirmResponseReady: true,
                isConfirmResponseDialogOpen: true,
                isRequestLoading: false
            })
        }
    }, [approveRequestResponse])

    const handleOnClose = () => {
        setState({
            ...state,
            isConfirmResponseReady: false,
            isConfirmResponseDialogOpen: false
        })
        onClose(true);
    }

    const handleApproveRequest = async () => {
        setState({
            ...state,
            isRequestLoading: true
        })
        approveRequest({
            requestId: requestId,
            comment: state.comment ? state.comment : ""
        })
    }

    const handleRejectRequest = async () => {
        if (validateRequiredFields()) {
            setState({
                ...state,
                isRequestLoading: true
            })
            rejectRequest({
                requestId: requestId,
                comment: state.comment
            })
        }
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (!props.isApprove && state.comment.trim() === '') {
            errors = {...errors, comment: true}
        }
        setState({
            ...state,
            errors: errors,
        })

        return Object.keys(errors).length === 0;
    }

    return <Dialog onClose={handleOnClose}
                   open={open}>
        <DialogTitle>
            {"Подтверждение действия"}
        </DialogTitle>
        <DialogContent>
            {
                props.isApprove ? "Вы действительно хотите одобрить запрос?" :
                "Вы действительно хотите отклонить запрос?"
            }
            <TextField
                sx={{mt: 2}}
                value={state.sum}
                id="reject-reason"
                label={props.isApprove? "Здесь можно указать комментарий к начислению": "Причина отмены"}
                variant="outlined"
                color="warning"
                onChange={(event) => {
                    setState({
                        ...state,
                        errors: {},
                        comment: event.target.value
                    });
                }}
                fullWidth
                error={state.errors.comment}
                helperText={state.errors.comment
                    ? "Поле не может быть пустым"
                    : ""}
            />
        </DialogContent>
        <DialogActions>
            <Button
                size="small"
                color="secondary"
                onClick={handleOnClose}>
                Отмена
            </Button>
            {props.isApprove ?
                <LoadingButton
                    size="small"
                    onClick={handleApproveRequest}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    Да
                </LoadingButton> :
                <LoadingButton
                    size="small"
                    onClick={handleRejectRequest}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    Да
                </LoadingButton>
            }

        </DialogActions>

        {state.isConfirmResponseReady &&
            <ResponseDialog onClose={handleOnClose}
                            open={state.isConfirmResponseDialogOpen}
                            responseData={approveRequestResponse}
                            label={"Подтверждение начисления"}/>
        }

    </Dialog>
}

ApproveConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    requestId: PropTypes.string.isRequired,
    isApprove: PropTypes.bool.isRequired,
    refetch: PropTypes.func.isRequired,
}