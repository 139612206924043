import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

const getStorageMap = (id, productLocationStorage) => {
    let storageAmounts = [];
    console.log(id)
    if (productLocationStorage != null && productLocationStorage.length !== 0) {
        productLocationStorage.forEach(item => {
            storageAmounts.push({
                location: item.location,
                amount: item.amount === null ? 0: item.amount,
                variationsAmountMap: item.variationsAmountMap === null ? new Map() : new Map(Object.entries(item.variationsAmountMap))
            })
        })

    }
    return storageAmounts;
}

export const productsApi = api.injectEndpoints({
    endpoints: (build) => ({
        getProductById: build.query({
            query: (args) => {
                return `products/${args.productId}?location=${args.userSelectedLocation}`
            },
        }),
        getProductAvailability: build.query({
            query: (args) => {
                return `products/availability/${args.productId}?location=${args.userSelectedLocation}`
            },
        }),
        getAllProducts: build.query({
            query: (location) => `products/all?location=${location}`,
            providesTags: ['Product'],
            transformResponse: (response, meta, arg) => {
                return response.map((product, index) =>
                    Object.assign({}, product, {storageAmounts: getStorageMap(product.id, product.locationStorageAmounts)})
                )
            }
        }),
        getAllProductsAdmin: build.query({
            query: (location) => `products/allAdmin?location=${location}`,
            providesTags: ['Product'],
            transformResponse: (response, meta, arg) => {
                return response.map((product, index) =>
                    Object.assign({}, product, {storageAmounts: getStorageMap(product.id, product.locationStorageAmounts)})
                )
            }
        }),
        updateAllProducts: build.mutation({
            query: (body = {}) => ({
                url: `products/all`,
                method: "POST",
                body: body
            })
        }),
        createProduct: build.mutation({
            query: (body = {}) => ({
                url: `products/create`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Product']

        }),
        updateProduct: build.mutation({
            query: (body = {}) => ({
                url: `products/update`,
                method: "PATCH",
                body: body
            }),
            invalidatesTags: ['Product']

        }),
        setHideForProduct: build.mutation({
            query: (body = {}) => ({
                url: `products/hide`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Product']
        }),
        deleteProduct: build.mutation({
            query: (body) => ({
                url: `products/delete`,
                method: "DELETE",
                body: body
            }),
        }),
        getSizes: build.query({
            query: () => `products/sizes`,
            transformResponse: (response) => {
                let sizeList = [];
                response.map((size, index) => sizeList.push(Object.values(size)[0]))
                return sizeList;
            },
        }),
        createSize: build.mutation({
            query: (body = {}) => ({
                url: `products/create_size`,
                body: body,
                method: "POST",
            }),
            //invalidatesTags: ['Product']
        }),
    }),
})

export const {
    useGetProductByIdQuery,
    useGetProductAvailabilityQuery,
    useGetAllProductsQuery,
    useGetAllProductsAdminQuery,
    useCreateProductMutation,
    useUpdateProductMutation,
    useSetHideForProductMutation,
    useDeleteProductMutation,
    useUpdateAllProductsMutation,
    useGetSizesQuery,
    useCreateSizeMutation
} = productsApi