import {applyMiddleware, configureStore, createStore, isRejectedWithValue} from "@reduxjs/toolkit"
import api from "../api/base-api"
import root from "../reducers/index"
import * as Sentry from "@sentry/react";
import {parseFormDataTextToJsonString} from "./utils";
import {CONFIGS} from "../../constants";
import { enableMapSet } from 'immer';

enableMapSet();

Sentry.init({
    dsn: 'https://68ac8340e82c4615837a436785a6c1a0@cross-sentry.belka-games.com/26',
    environment: CONFIGS.environment
});


const myLoggingMiddleware = storeAPI => next => action => {
    if (isRejectedWithValue(action)) {
        const jsonError = JSON.stringify(action.payload.data);
        action.meta.baseQueryMeta.request.body.getReader().read().then(response =>{
            const jsonRequest = JSON.stringify({body:parseFormDataTextToJsonString(new TextDecoder().decode(response.value))});
            Sentry.captureMessage(
                `Ошибка при отправке запроса\n${jsonError}\n${jsonRequest}`,
                Sentry.Severity.Error
            )
        });
    }
    return next(action)
}

export const store = configureStore({
    reducer: {
        [api.reducerPath]: api.reducer,
        data: root
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(api.middleware).concat(myLoggingMiddleware)
})
