import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import {Box, Button, Checkbox, DialogActions, DialogContent, FormControlLabel, TextField} from "@mui/material";
import * as React from "react";
import {useBelcoinsMutation} from "../../core/api/seniority-api";
import ResponseDialog from "../ResponseDialog";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";
import {useNavigate} from "react-router";
import {useSelector} from "react-redux";

export default function ChooseBelcoinsDialog (props) {
    const {onClose, open, voucher} = props;
    const sessionState = useSelector((state) => state.data.UserSession);
    const userSelectedLocation = sessionState.usersDetails.location ? sessionState.usersDetails.location : "";
    const navigate = useNavigate();
    const [state, setState] = useState({
        isResponseDialogOpen: false,
        belcoinsResponse: {},
        isRequestLoading: false,
        isYesButtonClick: false,
        isNeedToGetAddress: (userSelectedLocation === 'Польша' ||
            userSelectedLocation === 'Россия' ||
            userSelectedLocation === 'Украина') &&
            (voucher.type === 'YEAR_5' ||
                voucher.type === 'YEAR_7' ||
                voucher.type === 'YEAR_10' ||
                voucher.type === 'YEAR_15'),
        phone:"",
        country: userSelectedLocation,
        postcode:"",
        city:"",
        address:"",
        agreementForDataProcessing: false,
        errors: {}

    });
    const [chooseBelcoins, response] = useBelcoinsMutation();

    useEffect(()=> {
        const isResponseLoadingDialog = state.isYesButtonClick && (response.status !== 'rejected' || response.status !== 'fulfilled');
        const isReadyToDialog = (response.status === 'rejected' || response.status === 'fulfilled');
        setState({
            ...state,
            isRequestLoading: isResponseLoadingDialog,
            isYesButtonClick: false
        })

        if (isReadyToDialog) {
            setState({
                ...state,
                isResponseDialogOpen: true,
                isRequestLoading: false,
                belcoinsResponse: response
            })
        }
    }, [response])


    const handleClose = () => {
        onClose(true);
    }
    const handleConfirm = () => {

        if (validateRequiredFields()) {
            chooseBelcoins({
                seniorityVoucher: props.voucher,
                phone: state.phone,
                address: state.postcode + ", " + state.country + ", " + state.city + ", " + state.address
            })
        }
    }

    const handleResponseDialogClose = () => {
        setState({
            ...state,
            isYesButtonClick: false,
            isResponseDialogOpen: false
        })
        onClose(true)
        navigate("/products")
    }

    const handlePostcodeChange = (event) => {
        setState({
            ...state,
            postcode: event.target.value
        })
    }
    const handleCountryChange = (event) => {
        setState({
            ...state,
            country: event.target.value
        })
    }
    const handleCityChange = (event) => {
        setState({
            ...state,
            city: event.target.value
        })
    }
    const handleAddressChange = (event) => {
        setState({
            ...state,
            address: event.target.value
        })
    }
    const handleCustomUserPhoneChange = (event) => {
        setState({
            ...state,
            phone: event.target.value
        })
    }
    const handleCheckboxChange = (event) => {
        setState({
            ...state,
            agreementForDataProcessing: event.target.checked
        })
    }

    const validateRequiredFields = () => {

        if (!state.isNeedToGetAddress) {
            setState({
                ...state,
                isYesButtonClick: true
            })
            return true;
        }
        let errors = {};
        if (state.phone.trim() === "") {
            errors = {...errors, phone: true}
        }
        if (!state.agreementForDataProcessing) {
            errors = {...errors, agreementForDataProcessing: true}
        }
        if (state.postcode.trim() === "") {
            errors = {...errors, postcode: true};
        }
        if (state.country.trim() === "") {
            errors = {...errors, country: true};
        }
        if (state.city.trim() === "") {
            errors = {...errors, city: true};
        }
        if (state.address.trim() === "") {
            errors = {...errors, address: true};
        }

        setState({
            ...state,
            isYesButtonClick: true,
            errors: errors,
        })

        return Object.keys(errors).length === 0;
    }

    return (
        <Box>
            <Dialog onClose={handleClose}
                    open={open}
            >
                <DialogTitle>
                    <Typography gutterBottom
                                variant="h5"
                                component="div">
                       Подтверждение действия
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <Typography gutterBottom
                                variant="h6"
                                component="div"
                                sx={{mb: 2, mt: 2, fontWeight: 'bold'}}>
                        Получить 10 белкоинов на свой счет, отказавшись от подарка?
                    </Typography>
                    {
                        state.isNeedToGetAddress &&
                        <Box>
                            <Typography variant="body1" sx={{mb: 2, mt: 2}}>
                                Для локации {userSelectedLocation} необходимо заполнить адрес доставки наградной статуэтки:
                            </Typography>
                            <TextField
                                sx={{mb: 2}}
                                required
                                value={state.phone}
                                id="user-phone"
                                label="Контактный телефон"
                                variant="outlined"
                                color="warning"
                                onChange={handleCustomUserPhoneChange}
                                fullWidth
                                name="phone"
                                error={state.errors.phone}
                                helperText={state.errors.phone
                                    ? "Поле не должно быть пустым."
                                    : ""}
                            />
                            <TextField
                                required
                                value={state.country}
                                id="user-country"
                                label="Страна"
                                variant="outlined"
                                color="warning"
                                onChange={handleCountryChange}
                                fullWidth
                                name="country"
                                disabled={userSelectedLocation}
                                error={state.errors.country}
                                helperText={state.errors.country
                                    ? "Поле не должно быть пустым."
                                    : ""}

                                sx={{mb: 2}}
                            />
                            <TextField
                                required
                                value={state.postcode}
                                id="user-postcode"
                                label="Индекс"
                                variant="outlined"
                                color="warning"
                                onChange={handlePostcodeChange}
                                fullWidth
                                name="postcode"
                                error={state.errors.postcode}
                                helperText={state.errors.postcode
                                    ? "Поле не должно быть пустым."
                                    : ""}

                                sx={{mb: 2}}
                            />
                            <TextField
                                required
                                value={state.city}
                                id="user-city"
                                label="Город"
                                variant="outlined"
                                color="warning"
                                onChange={handleCityChange}
                                fullWidth
                                name="city"
                                error={state.errors.city}
                                helperText={state.errors.city
                                    ? "Поле не должно быть пустым."
                                    : ""}

                                sx={{mb: 2}}
                            />
                            <TextField
                                required
                                value={state.address}
                                id="user-address"
                                label="Улица, дом, квартира"
                                variant="outlined"
                                color="warning"
                                onChange={handleAddressChange}
                                fullWidth
                                name="country"
                                error={state.errors.address}
                                helperText={state.errors.address
                                    ? "Поле не должно быть пустым."
                                    : ""}

                                sx={{mb: 2}}
                            />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    {
                        state.isNeedToGetAddress &&
                        <Box>
                            <FormControlLabel
                                control={<Checkbox checked={state.agreementForDataProcessing}
                                                   onChange={handleCheckboxChange}
                                                   inputProps={{'aria-label': 'controlled'}}/>}
                                label="Согласен на обработку персональных данных"
                            />
                            {state.errors.agreementForDataProcessing &&
                                <p className="error-helper-text-center">
                                    *Необходимо дать согласие на обработку данных
                                </p>}
                        </Box>
                    }
                    <Button
                        size="small"
                        color="secondary"
                        onClick={handleClose}>
                        {"Отмена"}
                    </Button>
                    <LoadingButton
                        size="small"
                        onClick={handleConfirm}
                        loading={state.isRequestLoading}
                        variant="outlined"
                        color="warning"
                    >
                        Да
                    </LoadingButton>
                </DialogActions>
                <DialogActions>

                </DialogActions>
            </Dialog>
            { state.belcoinsResponse &&
                <ResponseDialog
                    onClose={handleResponseDialogClose}
                    open={state.isResponseDialogOpen}
                    responseData={state.belcoinsResponse ? state.belcoinsResponse : {}}
                    label={"Начисление belcoins"}
                />
            }
        </Box>
            );
}