import {Box} from "@mui/material";
import {useSelector} from "react-redux";
import ErrorBox from "../Common/ErrorBox";
import React from "react";
import RequestOfAccrualAdmin from "./RequestOfAccuralAdmin";
import RequestOfAccrualManager from "./RequestOfAccrualManager";
import {Outlet} from "react-router";

export default function RequestOfAccrual() {

    const sessionState = useSelector((state) => state.data.UserSession)
    const user = sessionState.user;
    const isUserAdmin = user.isAdmin;
    const isUserModerator = user.isManager;

    return(
        <Box>
            {isUserAdmin ? <Box>
                    <RequestOfAccrualAdmin/>
                </Box> :
                isUserModerator ?
                    <Box>
                        <RequestOfAccrualManager/>
                    </Box> :
                    <ErrorBox error={"Доступ запрещен"}/>
            }
            <Outlet/>
        </Box>)

}