import React, {useEffect, useState} from "react";
import {Box, Button, FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import CreateProductDialog from "./CreateProductDialog";
import ResponseDialog from "../ResponseDialog";
import AdminShop from "./AdminShop";
import CreateSizeDialog from "./CreateSizeDialog";
import {COUNTRIES} from "../../constants";

const MenuProps = {
    PaperProps: {
        sx: {
            maxWidth: "fit-content"
        },
    },
};

export default function ProductControl() {

    const getLocationsArray=() => {
        let array = [];
        array[0] = "-";
        COUNTRIES.forEach(item => {
            array.push(item)
        })
        return array;
    }

    const [state, setState] = useState({
        isResponseDialogOpen: false,
        responseData: {
            isUpdate: false,
            responseData: {}
        },
        isProductDialogOpen : false,
        isSizeDialogOpen : false,
        isCreateSizeButtonSlick: false,
        locationFilter: "",
        locations: getLocationsArray()
    });



    const handleClose = (responseData) => {
        setState({
            ...state,
            isResponseDialogOpen: responseData.isResponseReady,
            responseData: responseData.data,
            isProductDialogOpen: false
        })
    };
    const handleCreateProduct = () => {
        setState({
            ...state,
            isCreateSizeButtonSlick: false,
            isProductDialogOpen: true
        })
    }
    const handleResponseDialogClose= () => {
        setState({
            ...state,
            isResponseReady: false,
            isRequestCreateLoading: false,
            isActionButtonProductClick: false,
            errors: {}
        })
        setState({
            ...state,
            isResponseDialogOpen: false
        })
    }
    const handleCreateSize = () => {
        setState({
            ...state,
            isCreateSizeButtonSlick: true,
            isSizeDialogOpen : true
        })
    }
    const handleCloseCreateSize = (isResponseReady, responseData) => {
        setState({
            ...state,
            isResponseDialogOpen: isResponseReady,
            responseData: responseData,
            isSizeDialogOpen : false
        })
    }
    const handleLocationFilterChange = (event) => {
        setState({
            ...state,
            locationFilter: event.target.value
        })
    }

    return (
        <Box>
            <Button
                sx={{mt: 2}}
                color="warning"
                size="small"
                onClick={handleCreateProduct}
                variant={'contained'}
            >
                Создать новый продукт
            </Button>
            <Button
                sx={{ml: 2, mt: 2}}
                variant="contained"
                    color="error"
                    onClick={handleCreateSize}
            >
                Добавить размер
            </Button>
            <FormControl className="control__location"
                         sx = {{ml: 2, mt: 1}}
                         fullWidth>
                <InputLabel
                    color="warning"
                    id="location-select-label">
                    Фильтр по локации
                </InputLabel>

                <Select
                    color="warning"
                    labelId="location-select-label"
                    id="location-select"
                    value={state.locationFilter ? state.locationFilter : ''}
                    label="location"
                    onChange={handleLocationFilterChange}
                    MenuProps={MenuProps}
                >
                    {state.locations.map((option, index) => (
                        <MenuItem key={`location_select_image_${index}`}
                                  value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <CreateProductDialog
                open={state.isProductDialogOpen}
                onClose={handleClose}
                isCopyMode={false}
                isEditMode={false}
            />
            <CreateSizeDialog
                open={state.isSizeDialogOpen}
                onClose={handleCloseCreateSize}
            />
            {state.responseData &&
                <ResponseDialog
                    onClose={handleResponseDialogClose}
                    open={state.isResponseDialogOpen}
                    responseData={state.isCreateSizeButtonSlick ? state.responseData :
                        state.responseData.responseData ? state.responseData.responseData : {}}
                    label={state.isCreateSizeButtonSlick ? "Создание размера" :
                        state.responseData.isUpdate ? "Редактирование продукта" : "Создание продукта"}
                />
            }

            <AdminShop locationFilter = {state.locationFilter !== "-" ? state.locationFilter : "" }/>
        </Box>
    );
}