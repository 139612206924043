import * as React from 'react';
import {Box, CircularProgress, Typography} from "@mui/material";
import {useGetProductAvailabilityQuery} from "../../core/api/products-api";
import PropTypes from "prop-types";
import {useEffect} from "react";

export default function GiftLocation(props) {
    const {data: response, isFetching, isSuccess} = useGetProductAvailabilityQuery({
        productId: props.productId,
        userSelectedLocation: props.receiverLocation
    })
    useEffect(() => {
        if (isSuccess && response) {
            props.setAvailability(response.availableInLocation);
        }
    }, [response])
    return (
        <Box sx={{mt: 2}}>
            {props.receiverLocation !== "" ?
                <Box>
                    {isFetching ?
                        <CircularProgress/> :
                        <Box>
                            {response && response.availableInLocation  ?
                                <Typography gutterBottom variant="body1" component="div">
                                    Локация для доставки : <b>{props.receiverLocation}</b>
                                </Typography> :
                                <Typography>
                                    Продукт недостуен в локации коллеги
                                </Typography>
                            }
                        </Box>
                    }
                </Box> :
                <Box>
                    <Typography gutterBottom variant="body1" component="div">
                        Необходимо выбрать коллегу, чтобы узнать возможно ли доставить подарок
                    </Typography>
                </Box>
            }
        </Box>
    );
}

GiftLocation.propTypes = {
    receiverLocation: PropTypes.string.isRequired,
    productId: PropTypes.string.isRequired,
    setAvailability: PropTypes.func.isRequired
};