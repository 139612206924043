import {
    Button, Chip, DialogActions, DialogContent,
    MenuItem, Popper, TextField,
} from "@mui/material";
import * as React from "react";
import {Autocomplete, LoadingButton} from "@mui/lab";
import * as PropTypes from "prop-types";
import {useGetAllUsersDetailsQuery} from "../../core/api/users-api";
import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import {useCreateRequestMutation, useGetCategoriesQuery} from "../../core/api/accrual-request-api";
import DialogTitle from "@mui/material/DialogTitle";
import {ThumbUpOffAlt} from "@mui/icons-material";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {findCategoryLimit} from "../../core/utils/utils";

const PERIODS = [
    "Январь",
    "Февраль",
    "Март",
    "Апрель",
    "Май",
    "Июнь",
    "Июль",
    "Август",
    "Сентябрь",
    "Октябрь",
    "Ноябрь",
    "Декабрь"
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function RequestCreateDialog(props) {
    const {onClose, open} = props;
    const [state, setState] = useState({
        selectedEmployee: [],
        usersList: [],
        category: "",
        isOtherCategory: false,
        reason:"",
        sum: 0,
        period: "",
        errors: {},
        isRequestLoading: false,
        isCreateButtonClick: false
    });

    const {data: usersDetails = [], isFetching, isSuccess} = useGetAllUsersDetailsQuery();
    const {data: categories = []} = useGetCategoriesQuery();
    const [createRequest, createRequestResponse] = useCreateRequestMutation();


    useEffect(() => {
        const isResponseLoadingDialog = state.isCreateButtonClick && (createRequestResponse.status !== 'rejected' ||
            createRequestResponse.status !== 'fulfilled');
        const isReadyToDialog = (createRequestResponse.status === 'rejected' || createRequestResponse.status === 'fulfilled');
        setState({
            ...state,
            isRequestLoading: isResponseLoadingDialog,
            isCreateButtonClick: false
        })
        if (isReadyToDialog) {
            handleClose(true, createRequestResponse);
        }

    }, [createRequestResponse])

    const handleCategoryChange = (event) => {
        setState({
            ...state,
            category:  event.target.value,
            isOtherCategory:  event.target.value === "Другое" || event.target.value === "Участие в Cross проектах"
        })
    }
    const handleChangeUsersList = (newValue) => {
        setState( {
            ...state,
            selectedEmployee: newValue,
        });
    };

    const handleChangeReason = (event) => {
        setState({
            ...state,
            reason: event.target.value
        });
    }

    const handleChangeSum = (event) => {
        setState({
            ...state,
            sum: event.target.value
        });
    }

    const handleCreateAccrualRequest = () => {
        setState({
            ...state,
            isRequestLoading: true,
            isCreateButtonClick: true
        })
        if (validateRequiredFields()) {
            let selectedEmployee = [];
            state.selectedEmployee.forEach(employee => {
                selectedEmployee.push(employee.fullName)
            });
            createRequest({
                sum: state.sum,
                categoryName: state.category,
                reason: state.reason,
                period: state.period,
                receivers: selectedEmployee,
            })
        }
    }

    const handleClose = (responseReady, responseData) => {
        setState({
            ...state,
            selectedEmployee: [],
            usersContent: [],
            category: "",
            isOtherCategory: false,
            reason:"",
            sum: 0,
            isRequestLoading: false,
            isCreateButtonClick: false,
            errors: {},
        })
        onClose({
            isResponseReady: responseReady === true,
            data: responseData
        });
    };

    const validateRequiredFields = () => {
        let errors = {};
        if (state.selectedEmployee.length === 0) {
            errors = {...errors, employee: true}
        }
        if (state.sum <= 0) {
            errors = {...errors, sum: true}
        }
        if (state.category.trim() === "" ) {
            errors = {...errors, category: true}
        }

        if (state.isOtherCategory  && state.reason === "") {
            errors = {...errors, reason: true}
        }

        if (state.period.trim() === "") {
            errors = {...errors, period: true}
        }

        setState({
            ...state,
            errors: errors,
            isCreateButtonClick: true
        })

        return Object.keys(errors).length === 0;
    }

    return (
        <Dialog onClose={handleClose} open={open}
                PaperProps={{ sx: { width: "50%" } }}>
            <DialogTitle>
                Создание заявки на начисление
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    required
                    multiple
                    sx={{mt: 2}}
                    id="employee-select"
                    options={usersDetails}
                    getOptionLabel={(option) => option.fullName}
                    defaultValue={state.selectedEmployee}
                    onChange={(event, newValue) => handleChangeUsersList(newValue)}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            variant="outlined"
                            color="warning"
                            label="Сотрудники"
                        />
                    )}
                />
                {
                    state.errors.employee &&
                    <p className={"error-helper-text"}>
                       *Список сотрудников не может быть пустым</p>
                }
                <TextField
                    required
                    style={{
                        textAlign: "left"
                    }}
                    select
                    fullWidth
                    sx={{mt: 2}}
                    label="Категория начисления"
                    name="category"
                    variant="outlined"
                    color="warning"
                    value={state.category}
                    helperText={state.errors.category ? "Поле не должно быть пустым" : ""}
                    error={state.errors.category}
                    SelectProps={{"MenuProps": MenuProps}}
                    onChange={handleCategoryChange}
                >
                    {categories.map((option, index) => (
                        <MenuItem
                            key={`category_select_image_${index}`}
                            value={option.name}>
                            {option.name}
                        </MenuItem>
                    ))}
                </TextField>
                {(state.category !== "") &&
                    <Chip label={"Лимит по категории: " + findCategoryLimit(state.category, categories)}
                          size="small"
                          color={"warning"}
                          icon={<ErrorOutlineIcon/>}
                          variant={"contained"}
                        />
                }
                {   state.isOtherCategory &&
                    <TextField
                        required
                        sx={{mt: 2}}
                        value={state.reason}
                        id="new-product-description"
                        label="За что начисляются белкоины"
                        variant="outlined"
                        color="warning"
                        onChange={handleChangeReason}
                        fullWidth
                        error={state.errors.reason}
                        helperText={state.errors.reason
                            ? "Поле не может быть пустым"
                            : ""}
                    />
                }
                <TextField
                    required
                    style={{
                        textAlign: "left"
                    }}
                    select
                    fullWidth
                    sx={{mt: 2}}
                    label="Период начисления"
                    name="period"
                    variant="outlined"
                    color="warning"
                    value={state.period}
                    helperText={state.errors.period ? "Поле не должно быть пустым" : ""}
                    error={state.errors.period}
                    SelectProps={{"MenuProps": MenuProps}}
                    onChange={(event) => {
                        setState({
                            ...state,
                            period: event.target.value
                        })
                    }}
                >
                    {PERIODS.map((option, index) => (
                        <MenuItem
                            key={`category_select_image_${index}`}
                            value={option}>
                            {option}
                        </MenuItem>
                    ))}
                </TextField>
                <TextField
                    required
                    sx={{mt: 2}}
                    value={state.sum}
                    id="new-product-description"
                    label="Количество белкоинов"
                    variant="outlined"
                    color="warning"
                    onChange={handleChangeSum}
                    fullWidth
                    error={state.errors.sum}
                    helperText={state.errors.sum
                        ? "Указанно некорректное количество"
                        : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}>
                    Отмена
                </Button>
                <LoadingButton
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={handleCreateAccrualRequest}
                    loading={state.isRequestLoading}>
                    Отправить
                </LoadingButton>
            </DialogActions>

    </Dialog>);
}

RequestCreateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
};
