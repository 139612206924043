import * as React from 'react'
import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {Box, DialogActions, DialogContent, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";


export default function UsersListDialog(props) {
    const {onClose, open, data, name} = props;

    const handleClose = () => {
        onClose(true);
    };

    const handleConfirm = (event) => {
        onClose(true)
    };

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                <Typography gutterBottom
                            variant="h5"
                            component="div">
                    {name}
                </Typography>
            </DialogTitle>
            <DialogContent>
                {data.map((item, index) =>
                    <Box>
                       {item}
                    </Box>
                    )
                }
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleConfirm}>
                    {"Ок"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

UsersListDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
};
