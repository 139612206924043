import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useCreateOrderMutation} from "../../core/api/order-api";
import {useSelector} from "react-redux";
import {Alert, LoadingButton} from "@mui/lab";
import ProductPrice from "../Common/PriceComponent";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import {useCreatePresentOrderMutation} from "../../core/api/seniority-api";
import {getSeniorityButtonImage, getSeniorityResentTitle} from "../../core/utils/utils";


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

export default function CreateOrderDialog(props) {
    const {onClose, open, product, variation, inStock, isPresent, presents, voucher} = props;
    const sessionState = useSelector((state) => state.data.UserSession);
    const [crateOrder, createOrderResponse] = useCreateOrderMutation();
    const [cratePresentOrder, createPresentOrderResponse] = useCreatePresentOrderMutation();

    const userSelectedLocation = sessionState.usersDetails.location;

    const isCountryWithoutOffice =  (country) => {
        return country === "Россия" || country === "Польша" || country === "Украина";
    }
    const isDeliveryToOffice = userSelectedLocation !== "Другое" &&
        !isCountryWithoutOffice(userSelectedLocation);


    const [state, setState] = useState({
        isProductHasVariation: isPresent? presents.variations.size >0 : variation,
        deliveryAddress: "",
        postcode: "",
        country: userSelectedLocation !== "Другое" ? userSelectedLocation : "",
        city: "",
        address: "",
        phone: "",
        isCreateButtonClick: false,
        isRequestLoading: false,
        agreementForDataProcessing: false,
        errors: {},
        isCharity: product.isCharity
    })


    useEffect(() => {
        const response = isPresent ? createPresentOrderResponse : createOrderResponse;
        const isResponseLoadingDialog = state.isCreateButtonClick && (response.status !== 'rejected' || response.status !== 'fulfilled');
        const isReadyToDialog = (response.status === 'rejected' || response.status === 'fulfilled');
        setState({
            ...state,
            isRequestLoading: isResponseLoadingDialog,
            isCreateButtonClick: false
        })
        if (isReadyToDialog) {
            let dialogResponseInformationElement = null;
            let dialogResponseInformation = "";
            if (response.data) {
                dialogResponseInformationElement = response.data.asanaLink.includes('http') ?
                    createElement('a', {href: response.data.asanaLink}, response.data.asanaLink) :
                    createElement('p', null, response.data.asanaLink);
                dialogResponseInformation = response.data.asanaLink.includes('http') ? "Ссылка на задачу с заказом:" : "Спасибо ❤️"
                setState({
                    ...state,
                    isRequestLoading: false,
                })

            } else {
                setState({
                    ...state,
                    isRequestLoading: false,
                });
            }
            handleClose(true, {
                response: createOrderResponse,
                dialogResponseInformationElement: dialogResponseInformationElement,
                dialogResponseInformation: dialogResponseInformation,
            });
        }

    }, [createOrderResponse, createPresentOrderResponse])

    const getTitle = () => {
        if (isPresent) {
            if (presents) {
                return getSeniorityResentTitle(presents.voucherType)
            }
        } else {
            return product.title;
        }
    }
    const getVariation = () => {
        if (isPresent && presents) {
            let element = "";
            presents.products.forEach(product => {
                element += `<strong>${product.title}</strong> - размер: ${presents.variations.get(product.id)}`;
                element += '<br/>';
            });
            return element;
        } else {
            return `<strong>Размер: </strong>${variation}`;
        }

    }

    const getDescription = () => {
        if (!isPresent) {
            return product.description;
        }

    }
    const getImage = () => {
        if (isPresent) {
            return voucher ? getSeniorityButtonImage(voucher): "";
        } else {
            return product.images[0];
        }
    }

    const isProductHasVariation = () => {
       return isPresent? presents.variations.size > 0 : variation;
    }

    const handleClose = (orderResponseReady, responseData) => {
        setState({
            ...state,
            isRequestLoading: false,
            isCreateButtonClick: false,
            errors: {},

        })
        onClose({
            isResponseReady: orderResponseReady === true,
            data: responseData
        });
    };

    const getPresentInfo = (presents) => {
        let presentInfo = [];
        presents.products.map(product => {
            presentInfo.push({
                productId: product.id,
                productTitle: product.title,
                variation: presents.variations.get(product.id)
            })
        })
        return presentInfo;
    };

    const handleCreateOrder = () => {
        if (validateRequiredFields()) {
            state.deliveryAddress = state.postcode + ", " + state.country + ", " + state.city + ", " + state.address;
            if (!isPresent) {
                crateOrder({
                    productId: product.id,
                    deliveryAddress: isDeliveryToOffice ? userSelectedLocation : state.deliveryAddress,
                    phone: state.phone,
                    variation: variation,
                    isDeliveryToOffice: isDeliveryToOffice,
                    productLink: window.location.href,
                    isGift: false,
                    isProductInStock: inStock,
                    isCharity: state.isCharity
                })
            } else {
                cratePresentOrder({
                    seniorityVoucher: voucher,
                    products: getPresentInfo(presents),
                    deliveryAddress: isDeliveryToOffice ? userSelectedLocation : state.deliveryAddress,
                    phone: state.phone,
                    isDeliveryToOffice: isDeliveryToOffice,
                })
            }
        }
    }
    const handlePostcodeChange = (event) => {
        setState({
            ...state,
            postcode: event.target.value
        })
    }
    const handleCountryChange = (event) => {
        setState({
            ...state,
            country: event.target.value
        })
    }
    const handleCityChange = (event) => {
        setState({
            ...state,
            city: event.target.value
        })
    }
    const handleAddressChange = (event) => {
        setState({
            ...state,
            address: event.target.value
        })
    }
    const handleCustomUserPhoneChange = (event) => {
        setState({
            ...state,
            phone: event.target.value
        })
    }

    const validateRequiredFields = () => {

        let errors = {};
        if (!state.agreementForDataProcessing) {
            errors = {...errors, agreementForDataProcessing: true}
        }
        if (!state.isCharity) {
            if (state.phone.trim() === "") {
                errors = {...errors, phone: true}
            }
            if (!state.agreementForDataProcessing) {
                errors = {...errors, agreementForDataProcessing: true}
            }
            if (!isDeliveryToOffice) {
                if (state.postcode.trim() === "") {
                    errors = {...errors, postcode: true};
                }
                if (state.country.trim() === "") {
                    errors = {...errors, country: true};
                }
                if (state.city.trim() === "") {
                    errors = {...errors, city: true};
                }
                if (state.address.trim() === "") {
                    errors = {...errors, address: true};
                }
            }
        }
        setState({
            ...state,
            errors: errors,
            isCreateButtonClick: true
        })

        return Object.keys(errors).length === 0;
    }

    const handleCheckboxChange = (event) => {
        setState({
            ...state,
            agreementForDataProcessing: event.target.checked
        })
    }

    return (
        <Dialog onClose={handleClose} open={open}
                PaperProps={{
                    style: {
                        minWidth: '680px',
                    },
                }}>
            <DialogTitle>
                Оформление заказа
            </DialogTitle>
            <DialogContent>

                <Grid container>
                    <Grid item xs={4}>
                        <div className="create-order__image-wrap">
                            <Img
                                key={`product_image`}
                                sx={{width: "100%"}}
                                alt={getTitle()}
                                src={getImage()}/>
                        </div>

                    </Grid>
                    <Grid item xs={8}>
                        {!isPresent &&
                            <Typography sx={{mb: 3}} gutterBottom variant="body1" component="div">
                                Будет списано <span className={"inline-price"}>
                            <ProductPrice price={product.isSale ? product.salePrice : product.price}/>

                            </span> белкоинов

                            </Typography>
                        }
                        <Typography gutterBottom variant="body1" component="div">
                            {getTitle()}
                        </Typography>
                        <Typography gutterBottom variant="body1" component="div">
                                <div dangerouslySetInnerHTML={{ __html: getDescription()}}/>
                        </Typography>
                        {
                            isProductHasVariation() ?
                                <div dangerouslySetInnerHTML={{ __html: getVariation() }} />
                                : null
                        }
                        {   !state.isCharity ?
                            (!isPresent && !inStock) &&
                            <Alert
                                icon={<ReportProblemOutlinedIcon
                                    style={{ color: '#ff0000' }}
                                    fontSize="" />}
                                severity="warning">
                                Этого товара нет на складе. <br/>
                                Оформив заказ администратор магазина сообщит о возможных сроках реализации заказа.
                                Ориентировочное время ожидания оформления и доставки может составить 2-4 недели. <br/>
                                <strong>Важно!</strong><br/>
                                При заказе необходимо учитывать, что выбранный товар может быть доступен в ограниченном количестве/партии (без возможности повторного изготовления).
                                В таком случаи ваш заказ может быть отменён администратором, а вы получите уведомление.<br/>
                                *время ожидания изготовления и доставки мерча составляет 2-3 месяца (если нет ограничений в данной партии).<br/>
                                *заказ техники оформляется индивидуально.<br/>
                            </Alert> :
                            null
                        }
                        {!state.isCharity && <TextField
                            sx={{mt: 2}}
                            required
                            value={state.phone}
                            id="user-phone"
                            label="Контактный телефон"
                            variant="outlined"
                            color="warning"
                            onChange={handleCustomUserPhoneChange}
                            fullWidth
                            name="phone"
                            error={state.errors.phone}
                            helperText={state.errors.phone
                                ? "Поле не должно быть пустым."
                                : ""}
                        />
                        }
                        {
                            !state.isCharity ?
                            isDeliveryToOffice ?
                                <Box sx={{mt: 3}}>
                                    <Typography gutterBottom variant="body1" component="div">
                                        Доставка в офис: <strong>{userSelectedLocation}</strong>
                                    </Typography>
                                </Box> :
                                <Box>
                                    <Typography variant="body1" sx={{mb: 2, mt: 2, fontWeight: 'bold'}}>
                                        Адрес доставки:
                                    </Typography>
                                    <TextField
                                        required
                                        value={state.country}
                                        id="user-country"
                                        label="Страна"
                                        variant="outlined"
                                        color="warning"
                                        onChange={handleCountryChange}
                                        fullWidth
                                        name="country"
                                        disabled={isCountryWithoutOffice(userSelectedLocation)}
                                        error={state.errors.country}
                                        helperText={state.errors.country
                                            ? "Поле не должно быть пустым."
                                            : ""}

                                        sx={{mb: 2}}
                                    />
                                    <TextField
                                        required
                                        value={state.postcode}
                                        id="user-postcode"
                                        label="Индекс"
                                        variant="outlined"
                                        color="warning"
                                        onChange={handlePostcodeChange}
                                        fullWidth
                                        name="postcode"
                                        error={state.errors.postcode}
                                        helperText={state.errors.postcode
                                            ? "Поле не должно быть пустым."
                                            : ""}

                                        sx={{mb: 2}}
                                    />
                                    <TextField
                                        required
                                        value={state.city}
                                        id="user-city"
                                        label="Город"
                                        variant="outlined"
                                        color="warning"
                                        onChange={handleCityChange}
                                        fullWidth
                                        name="city"
                                        error={state.errors.city}
                                        helperText={state.errors.city
                                            ? "Поле не должно быть пустым."
                                            : ""}

                                        sx={{mb: 2}}
                                    />
                                    <TextField
                                        required
                                        value={state.address}
                                        id="user-address"
                                        label="Улица, дом, квартира"
                                        variant="outlined"
                                        color="warning"
                                        onChange={handleAddressChange}
                                        fullWidth
                                        name="country"
                                        error={state.errors.address}
                                        helperText={state.errors.address
                                            ? "Поле не должно быть пустым."
                                            : ""}

                                        sx={{mb: 2}}
                                    />
                                </Box> : null
                        }
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Box>
                    <FormControlLabel
                        control={<Checkbox   checked={state.agreementForDataProcessing}
                                             onChange={handleCheckboxChange}
                                             inputProps={{ 'aria-label': 'controlled' }}/>}
                        label="Согласен на обработку персональных данных"
                    />
                    {state.errors.agreementForDataProcessing &&
                        <p className="error-helper-text-center">
                            *Необходимо дать согласие на обработку данных
                        </p>}
                </Box>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}>
                    Отмена
                </Button>
                <LoadingButton
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={handleCreateOrder}
                    loading={state.isRequestLoading}>
                    Оформить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

CreateOrderDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    product: PropTypes.object.isRequired,
    variation: PropTypes.string.isRequired,
    inStock: PropTypes.bool.isRequired,
    isPresent: PropTypes.bool.isRequired,
    presents: PropTypes.object.isRequired,
};
