import * as React from 'react'
import {useEffect, useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions, DialogContent, TextField, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";

export default function ResponseDialog(props) {
    const {onClose, open, responseData, label} = props;
    const [state, setState] = useState({
        responseMessage: ""
    });

    useEffect(() => {
      if (responseData.error &&
            responseData.error.data &&
            responseData.error.data.error) {
          setState({
              ...state,
              responseMessage: responseData.error.data.error
          })
        } else {
          setState({
              ...state,
              responseMessage: props.information
          })
      }
    }, [responseData])

    const handleClose = () => {
        onClose(true);
    };

    const handleConfirm = (event) => {
        if (props.refetch) {
            props.refetch();
        }
        onClose(true)
    };

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                <Typography gutterBottom
                            variant="h5"
                            component="div">
                    {label}
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Typography gutterBottom
                            variant="h6"
                            component="div">
                    {responseData.isSuccess ||  (props.element !== undefined &&  props.element !== null) ? "Принято!" : "Ошибка :("}
                </Typography>
                <Typography gutterBottom
                            variant="h6"
                            component="div">
                    {state.responseMessage}
                </Typography>
                {props.element && props.element}
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleConfirm}>
                    {"Ок"}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ResponseDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    responseData: PropTypes.object.isRequired,
    label: PropTypes.string.isRequired,
    refetch: PropTypes.func,
    information: PropTypes.string,
    element: PropTypes.element
};
