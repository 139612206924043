import * as React from 'react';
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {Box, CircularProgress} from "@mui/material";
import {useGetEmployeeBalanceQuery} from "../../core/api/balance-api";
import {useSelector} from "react-redux";
import {format} from "date-fns";
import "./Balance.css"
import PropTypes from "prop-types";
import {getDateInServerTimeZone} from "../../core/utils/utils";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'ind', headerName: 'ID', width: 50},
    {
        field: 'createdAt', headerName: 'Дата', width: 100,
        renderCell: (params) =>
            <div>{format(getDateInServerTimeZone(params.row.createdAt), "dd.MM.yyyy")}</div>
    },
    {
        field: 'sum', headerName: 'Начисление/Списание', width: 200,
        renderCell: (params) => {
            if (params.row.sum > 0) {
                return <div className={"balance-accrual"}>+{params.row.sum}</div>;
            }
            return params.row.sum;
        }
    },
    {field: 'comment', headerName: 'Комментарий', width: 400},
    {field: 'authorName', headerName: 'Автор', width: 400},

];
export default function Balance(props) {
    const sessionState = useSelector((state) => state.data.UserSession)
    let employeeEmail;
    let isAdminMode = false;
    if (props.employeeEmail) {
        isAdminMode = true;
        employeeEmail = props.employeeEmail;
    } else {
        employeeEmail = sessionState.authenticated ? sessionState.user.username : ''
    }
    const {data: balanceHistory = [], isFetching} = useGetEmployeeBalanceQuery({
        isAdminMode: isAdminMode,
        employeeEmail: employeeEmail
    })

    const getNameFromEmail = (email) => {
        if (email) {
            const nameEndIndex = email.indexOf('@');
            return email.substring(0, nameEndIndex).replace('_', ' ').toUpperCase();
        }
        return "";
    }

    return (
        <Box className='page-main-box'>
            {isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box sx={{width: '100%'}}>
                    <div>
                        <h2>
                            История начислений {getNameFromEmail(props.employeeEmail)}
                        </h2>
                    </div>
                    <Box sx={{width: '100%', height: 600}}>
                        <DataGrid
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            sx={{m: 2}}
                            rows={balanceHistory}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Box>
                </Box>
            }
        </Box>

    );
}

Balance.propTypes = {
    employeeEmail: PropTypes.string,
}