import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import {DialogActions, DialogContent, TextField} from "@mui/material";
import {useCreateSizeMutation} from "../../core/api/products-api";
import {useEffect, useState} from "react";
import {LoadingButton} from "@mui/lab";

export default function CreateSizeDialog(props) {
    const {onClose, open} = props;
    const [createSize, createSizeResponse] = useCreateSizeMutation();
    const [state, setState] = useState({
        sizeName: "",
        isResponseOpenDialog: false,
        isActionButtonProductClick: false,
        isResponseReady: false,
        isRequestCreateLoading: false,
        errors: {}
    });
    useEffect(() => {
        const isRequestLoadingDialog = state.isActionButtonProductClick &&
            (createSizeResponse.status !== 'rejected' || createSizeResponse.status !== 'fulfilled');
        setState({
            ...state,
            isRequestCreateLoading: isRequestLoadingDialog,
            isActionButtonProductClick: false
        })
        if (createSizeResponse.status === 'rejected' || createSizeResponse.status === 'fulfilled') {
            setState({
                ...state,
                isResponseReady: true,
                isResponseOpenDialog: true
            })
            handleClose(true, createSizeResponse)
        }
    }, [createSizeResponse])

    const handleChangeSize = (event) => {
        setState({
            ...state,
            sizeName: event.target.value
        })
    }


    const handleClose = (isResponseReady, responseData) => {
        onClose(
            isResponseReady === true,
            responseData
        );
    }

    const handleSaveButton = () => {
        if (validateRequiredFields()) {
            createSize(
                {sizeName: state.sizeName});
        }
        setState({
            ...state,
            isActionButtonProductClick: true
        })
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.sizeName.trim() === "") {
            errors = {...errors, sizeName: true}
        }
        setState({
            ...state,
            errors: errors
        })
        return Object.keys(errors).length === 0;
    }
    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                Новый размер
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{mt: 3}}
                    required
                    value={state.sizeName}
                    id="new-size-title"
                    label="Название размера"
                    variant="outlined"
                    color="warning"
                    onChange={handleChangeSize}
                    fullWidth
                    name="title"
                    error={state.errors.sizeName}
                    helperText={state.errors.sizeName
                        ? "Поле не должно быть пустым."
                        : ""}
                />
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    size="small"
                    onClick={handleSaveButton}
                    loading={state.isRequestCreateLoading}
                    variant="outlined"
                    color="warning"
                >
                    Сохранить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}