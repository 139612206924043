import * as React from 'react'
import {Box, CardActionArea, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import PropTypes from "prop-types";
import "./LocationCard.css"
import ButtonBase from "@mui/material/ButtonBase";
import {useSetUserLocationMutation} from "../../core/api/users-api";
import {useNavigate} from "react-router";
import {store} from "../../core/utils/configureStore";
import {useSelector} from "react-redux";

export default function LocationCard(props) {
    const state = useSelector((state) => state.data.UserSession)
    const employeeEmail = state.authenticated ? state.user.username : ''
    const [setLocation] = useSetUserLocationMutation();
    const navigate = useNavigate();
    const handleSelectLocation = (event) => {
        const selectedLocation = event.target.innerText;
        setLocation({
            employeeEmail: employeeEmail,
            location: selectedLocation
        })
        navigate("products");
        store.dispatch({
            type: 'LOCATION_CHANGE', payload: {
                data: {
                    location: selectedLocation,
                }
            }
        })
    };

    return (
        <Card sx={{ display: 'flex', alignItems: 'center'}}>
            <ButtonBase
                sx={{width: '100%'}}
                id={props.locationId}
                onClick={handleSelectLocation}>
                <CardMedia
                    sx={{width: 55, height: 55}}
                    className={props.locationIconPath}
                    component="div"
                />
                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    <CardContent sx={{flex: '1 0 auto'}}>
                        <Typography
                            gutterBottom
                            variant="h5"
                            component="div">
                            {props.locationName}
                        </Typography>

                    </CardContent>
                </Box>
            </ButtonBase>

        </Card>
    );
}

LocationCard.propTypes = {
    locationName: PropTypes.string.isRequired,
    locationId: PropTypes.string.isRequired,
    locationIconPath: PropTypes.string.isRequired,
};