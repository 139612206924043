import * as React from 'react';
import {Box, Chip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {useNavigate} from "react-router";
import PropTypes from "prop-types";

export default function EditOrderButton(props) {
    const navigate = useNavigate();

    return <Box>
        <Chip label="Edit"
              size="small"
              icon={<EditIcon/>}
              variant="outlined"
              onClick={() => navigate('/admin/orderHistory/' + props.orderId, {state: props.orderId})} />
    </Box>;
}

EditOrderButton.propTypes = {
    orderId: PropTypes.string.isRequired,
};