import React from 'react';
import {Box, CircularProgress} from "@mui/material";

const Loading = () => {


    return (
        <Box sx={{ flexGrow: 1 }}>
            <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <CircularProgress/>
            </Box>
        </Box>

    );
}

export default Loading;
