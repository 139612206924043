import * as React from 'react';
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {Box, CircularProgress} from "@mui/material";
import {useSelector} from "react-redux";
import {format} from "date-fns";
import {getDateInServerTimeZone, isAdminMode, mapOrderStatus} from "../../../core/utils/utils";
import {useLocation} from "react-router";
import EditOrderButton from "./EditOrderButton";
import ConfirmDeliveryButton from "../DeliveryConfirm/ConfirmDeliveryButton";
import {useGetMyOrdersQuery} from "../../../core/api/order-api";

const columns = [
    {field: 'id', headerName: 'id'},
    {field: 'ind', headerName: 'ID', width: 50},
    {
        field: 'edit', headerName: 'Редактирование заказа', width: 80, sortable: false, filterable: false,
        renderCell: (params) => {
            return <EditOrderButton orderId={params.row.id}/>;
        },
    },
    {
        field: 'createdAt', headerName: 'Дата', width: 100,
        renderCell: (params) =>
            <div>{format(getDateInServerTimeZone(params.row.createdAt), "dd.MM.yyyy")}</div>
    },
    {field: 'fullName', headerName: 'ФИО', width: 200,},
    {field: 'productTitle', headerName: 'Товар', width: 200},
    {field: 'price', headerName: 'Цена', width: 80},
    {
        field: 'status', headerName: 'Состояние', width: 130, sortable: false, filterable: false,
        renderCell: (params) => {
            return <ConfirmDeliveryButton orderId={params.row.id}/>;
        },
    },
    {
        field: 'statusAdmin', headerName: 'Состояние', width: 110, sortable: false, filterable: false,
        renderCell: (params) => {
            return  <div>{mapOrderStatus(params.row.status)}</div>;
        },
    },
    {
        field: 'confirmed', headerName: 'Получен?', width: 110, sortable: false, filterable: false,
        renderCell: (params) => {
            return params.row.isDeliveryConfirmed ? "Да" : "Нет";
        },
    },
    {field: 'phone', headerName: 'Телефон', width: 120},
    {field: 'deliveryAddress', headerName: 'Адрес', width: 200},
    {field: 'isGift', headerName: 'Тип заказа', width: 100,
        renderCell: (params) => {
            if (params.row.isCharity) {
                return "Благотворительность";
            } else {
                return params.row.isGift === true ?  "Подарок" : (params.row.isProductInStock === true ? "Заказ" : "Под заказ")
            }
        }},
    {
        field: 'asanaLink',
        headerName: 'Ссылка на задачу',
        width: 250,
        renderCell: (params) => {
            return params.row.asanaLink.includes('http') ?
                <a href={params.row.asanaLink}>{params.row.asanaLink}</a> :
                <p>Задача не создана</p>
        },
    },

];
export default function OrdersHistory(props) {
    const sessionState = useSelector((state) => state.data.UserSession)
    const employeeEmail = sessionState.authenticated ? sessionState.user.username : ''
    const {data: ordersHistory = [], isFetching} = useGetMyOrdersQuery();
    const location = useLocation();

    return (
        <Box className='page-main-box'>
            {props.isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box sx={{width: '100%', mt:2}}>
                    {props.filterAndDownLoad}
                    <div>
                        <h2>
                            {props.children}
                        </h2>
                    </div>
                    <Box sx={{width: '100%', height: 600}}>
                        <DataGrid
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            initialState={{
                                columns: {
                                    columnVisibilityModel: {
                                        id: false,
                                        fullName: isAdminMode(location.pathname),
                                        edit: isAdminMode(location.pathname),
                                        status: !isAdminMode(location.pathname),
                                        statusAdmin: isAdminMode(location.pathname),
                                        confirmed: isAdminMode(location.pathname),
                                    },
                                },
                            }}
                            rows={props.ordersHistory}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Box>
                </Box>
            }
        </Box>

    );
}
