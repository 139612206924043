import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

export const usersApi = api.injectEndpoints({
    endpoints: (build) => ({
        getUserDetails: build.query({
            query: () => `users/details`,
            providesTags: ['User']
        }),
        getAllUsers: build.query({
            query: () => `users/list`,
            transformResponse(response, meta, arg) {
                return response.map((employee, index) =>
                    Object.assign({}, employee, {ind: index,
                        birthDay: format(getDateInServerTimeZone(employee.birthDate), "dd.MM.yyyy")})
                )
            }
        }),
        getAllUsersDetails: build.query({
            query: () => `users/search`,
            transformResponse(response, meta, arg) {
                return response.map((employee, index) =>
                    Object.assign({}, employee, {label: employee.fullName})
                )
            }
        }),
        setUserLocation: build.mutation({
            query: (body={} ) => ({
                url: `users/country`,
                method: "POST",
                body: body
            }),
        }),
        getInactiveUsers: build.query({
            query: () => `users/inactive`,
            transformResponse(response, meta, arg) {
                return response.map((employee, index) =>
                    Object.assign({}, employee, {ind: index,
                        fireDate: format(getDateInServerTimeZone(employee.fireDate), "dd.MM.yyyy")})
                )
            },
            providesTags: ['Inactive']
        }),
    }),
})

export const {
    useGetUserDetailsQuery,
    useGetAllUsersQuery,
    useGetAllUsersDetailsQuery,
    useSetUserLocationMutation,
    useGetInactiveUsersQuery
} = usersApi