import React from 'react';
import { Route } from 'react-router-dom';
import Helmet from 'react-helmet';
import {Box, Typography} from "@mui/material";

function NotFound() {
  return (
      <Typography variant={"body1"}>
          Страницы не существует
      </Typography>
  );
}

export default NotFound;
