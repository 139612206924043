import * as React from 'react';
import {useState} from 'react';
import {Box, Button, CircularProgress, Stack, Typography} from "@mui/material";
import {useLocation, useNavigate} from "react-router";
import {isAdminMode} from "../../../core/utils/utils";
import ResponseDialog from "../../ResponseDialog";
import {useGetCategoriesQuery} from "../../../core/api/accrual-request-api";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import BudgetChangeDialog from "./BudgetChangeDialog";
import ExportGridToExcel from "../ExportGridToExcel";
import {useCreateBudgetMutation} from "../../../core/api/budget-api";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'name', headerName: 'Категория', width: 400},
    {field: 'limit', headerName: 'Остаток Belcoins', width: 250},
];


export default function BudgetControl(props) {
    const {data: categories = [], isSuccess: isSuccessCategory, isFetching, refetch} = useGetCategoriesQuery();
    const {createBudget, createResponse} = useCreateBudgetMutation();
    const location = useLocation()
    const isAdmin = isAdminMode(location.pathname);
    const [state, setState] = useState({
        budget: [],
        isResponseDialogOpen: false,
        responseData: {},
        isSaveButtonClick: false,
        isRequestLoading: false,
        selectedCategory: [],
        isBalancesChangeResponseDialogOpen: false,
        balanceChangeResponse: {},
        isBalancesChangeDialogOpen: false,
        controlButtonId: ""
    });
    const navigate = useNavigate();

    const handleResponseDialogClose = () => {
        setState({
            ...state,
            isResponseDialogOpen: false
        })
    }

    const handleUpdateBudget = (event) => {
        setState({
            ...state,
            controlButtonId: event.target.id,
            isBalancesChangeDialogOpen: true,
            isSaveButtonClick: true,
            isRequestLoading: true,
        })
    }

    const handleCreateNewCategory = (event) => {
        createBudget();
    }

    const handleClose = (balanceChangeResponse) => {
        setState({
            ...state,
            isResponseDialogOpen: balanceChangeResponse.isResponseReady,
            balanceChangeResponse: balanceChangeResponse.data,
            isBalancesChangeDialogOpen: false
        })
        refetch();
    }

    const getCategoryById = (selectedCategoriesId) => {
        let selectedCategories = [];
        categories.forEach(category => {
            selectedCategoriesId.forEach(selectedId => {
                if (category.id === selectedId) {
                    selectedCategories.push(category);
                }
            })
        })
        return selectedCategories;
    }

   const handleBackButton = () => {
       navigate('/request');
   }


    return (
        <Box className='page-main-box'>
            {isFetching ?
                <CircularProgress/> :
                    <Box sx={{display: 'block', width: '100%'}}>
                        {isAdmin &&
                            <Box>
                                <Stack spacing={2} direction="row" sx={{pt: 2, alignItems: 'center'}}>
                                    <Button id="increase-button"
                                            variant="contained"
                                            color="warning"
                                            disabled={state.selectedCategory.length === 0}
                                            onClick={handleUpdateBudget}
                                    >
                                        Начислить
                                    </Button>
                                    <Button id="decrease-button"
                                            variant="contained"
                                            color="error"
                                            disabled={state.selectedCategory.length === 0}
                                            onClick={handleUpdateBudget}
                                    >
                                        Списать
                                    </Button>

                                    <ExportGridToExcel filename={"budget"}
                                                       data={state.selectedCategory.length === 0 ?
                                                           categories :
                                                           getCategoryById(state.selectedCategory)}/>

{/*                                    <Button id="create-category-button"
                                            variant="contained"
                                            color="warning"
                                            onClick={handleCreateNewCategory}
                                    >
                                        Создать новую категорию
                                    </Button>*/}

                                    <BudgetChangeDialog onClose={handleClose}
                                                        open={state.isBalancesChangeDialogOpen}
                                                        buttonId={state.controlButtonId}
                                                        categoryIdList={state.selectedCategory}
                                                        refetchBalance={refetch}
                                    />
                                    {state.balanceChangeResponse &&
                                        <ResponseDialog
                                            onClose={handleResponseDialogClose}
                                            open={state.isResponseDialogOpen}
                                            responseData={state.balanceChangeResponse ? state.balanceChangeResponse : {}}
                                            label={"Редактирование бюджета"}/>
                                    }
                                </Stack>
                            </Box>
                        }
                        <Box sx={{width: '100%', height: 600, mt: 2}}>
                            {!isAdmin && <Button sx={{mb: 2}}
                                    variant="outlined"
                                    color="error"
                                    onClick={handleBackButton}
                                    startIcon={<ArrowBackIcon />}>
                                Назад
                            </Button>}
                            <DataGrid
                                localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                                rows={categories}
                                columns={columns}
                                pageSize={20}
                                rowsPerPageOptions={[20]}
                                checkboxSelection={isAdmin}
                                onSelectionModelChange={(newSelectionModel) => {
                                    setState({...state,
                                        selectedCategory: newSelectionModel})
                                }}
                                selectionModel={state.selectedCategory}
                            />
                        </Box>
                </Box>
            }

        </Box>
    );
}



