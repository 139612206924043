import {Box, Button, CircularProgress, Link, Typography} from "@mui/material";
import React, {useState} from "react";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {useGetAccrualRequestsQuery} from "../../core/api/accrual-request-api";
import RequestCreateDialog from "./RequestCreateDialog";
import UserIdsListComponent from "./UserIdsListComponent";
import ResponseDialog from "../ResponseDialog";
import {useNavigate} from "react-router";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'category', headerName: 'За что начисляем', width: 150},
    {field: 'period', headerName: 'Период', width: 90},
    {field: 'receivers', headerName: 'Получатели', width: 200,
        renderCell: (params) => {
            return <UserIdsListComponent data={params.row.receivers}/>;
    }
    },
    {field: 'sum', headerName: 'Belcoins', width: 80},
    {
        field: 'status', headerName: 'Статус', width: 100,
        renderCell: (params) => {
            if (params.row.status === 'WAITING') {
                return <div>Ожидание</div>;
            } else if (params.row.status === 'APPROVED') {
                return <div>Начислено</div>;
            } else {
                return <div>Отклонено</div>;
            }
        }
    },
    {field: 'reason', headerName: 'Комментарий', width: 200},
    {
        field: 'adminName', headerName: 'Администратор', width: 150,
        renderCell: (params) => {
            if (params.row.adminName) {
                return <div>{params.row.adminName}</div>;
            }
            return <div></div>;
        }
    },

];
export default function RequestOfAccrualManager() {
    const navigate = useNavigate();
    const [state, setState] = useState({
        isCreateDialogOpen: false,
        responseData: {},
        isResponseDialogOpen: false
    })
    const {data: accrualRequest = [], isSuccess, refetch} = useGetAccrualRequestsQuery();
    const handleCreateAccrualRequest = () => {
        setState({
            ...state,
            isCreateDialogOpen: true
        })
    }

    const handleClose = (responseData) => {
        setState({
            ...state,
            isCreateDialogOpen: false,
            responseData: responseData.data,
            isResponseDialogOpen: responseData.isResponseReady
        })
    }
    const handleResponseDialogClose = () => {
        refetch();
        setState({
            ...state,
            isResponseDialogOpen: false
        })
    }
    const handleLinkClick = () => {
        navigate('/request/budgetBalance')
    }
    return(
        isSuccess ?
            <Box>
                <Typography variant="h6" sx={{mb: 2, fontWeight: 'bold'}}>
                   Начисление белкоинов
                </Typography>
                <Button
                    variant="contained"
                        color="warning"
                        onClick={handleCreateAccrualRequest}>
                    Начислить belcoins
                </Button>
                <Link sx={{ml: 2, color: '#e74415', textDecorationColor: '#e74415'}}
                    component="button"
                    variant="body2"
                    onClick={handleLinkClick}
                >
                    Посмотреть остаки бюджетов
                </Link>
                <Box sx={{width: '100%', height: 600, mt: 2}}>
                    <DataGrid
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rowHeight = {85}
                        rows={accrualRequest}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Box>
                <RequestCreateDialog
                    open={state.isCreateDialogOpen}
                    onClose={handleClose}/>
                {state.responseData &&
                    <ResponseDialog
                        onClose={handleResponseDialogClose}
                        open={state.isResponseDialogOpen}
                        responseData={state.responseData ? state.responseData : {}}
                        label={"Начисление белкоинов"}
                    />
                }
            </Box> :
            <CircularProgress/>
    );

}