import {Box, Button, Chip, CircularProgress, Stack, Typography} from "@mui/material";
import {useGetAllVouchersQuery} from "../../../core/api/seniority-api";
import ExportGridToExcel from "../ExportGridToExcel";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import BalancesChangeDialog from "../Balance/BalancesChangeDialog";
import ResponseDialog from "../../ResponseDialog";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {Outlet} from "react-router";
import React, {useState} from "react";
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../../../core/utils/utils";
import {Link} from "react-router-dom";
import VoucherCreateDialog from "./VoucherCreateDialog";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'type', headerName: 'Годовщина', width: 100},
    {field: 'employeeName', headerName: 'Сотрудник', width: 250},
    {field: 'cratedDate', headerName: 'Дата создания', width: 150,
        /*renderCell: (params) => {
            return <div>{format(getDateInServerTimeZone(params.row.cratedDate), "dd.MM.yyyy")}</div>;
        }*/
    },
    {field: 'expireDate', headerName: 'Срок действия', width: 150,
        /*renderCell: (params) => {
            return <div>{format(getDateInServerTimeZone(params.row.expireDate), "dd.MM.yyyy")}</div>;
        }*/
    },
    {field: 'isActivated', headerName: 'Активирован?', width: 150,
        renderCell: (params) => {
            return <div>{params.row.isActivated ? "Да" : "Нет"}</div>;
        }
    },
    {field: 'isBelcoinsSelected', headerName: 'Выбраны belcoins', width: 150,
        renderCell: (params) => {
            return <div>{params.row.isBelcoinsSelected ? "Да" : "Нет"}</div>;
        }
    },
    {field: 'authorName', headerName: 'Админ', width: 150},
];

export default function SeniorityControl(props) {
    const {data: vouchers, refetch, isFetching} = useGetAllVouchersQuery();
    const [state, setState] = useState({
        isVoucherChangeDialogOpen: false,
        isBalancesChangeResponseDialogOpen: false,
        balanceChangeResponse: {}
    })
    const handleChangeBalanceButton = (event) => {
        setState({
            ...state,
            isVoucherChangeDialogOpen: true
        })
    }

    const handleClose = (balanceChangeResponse) => {
        console.log("balanceChangeResponse")
        console.log(balanceChangeResponse)
        setState({
            ...state,
            isBalancesChangeResponseDialogOpen: balanceChangeResponse.isResponseReady,
            balanceChangeResponse: balanceChangeResponse.data,
            isVoucherChangeDialogOpen: false
        });
    };

    const handleChangeResponseDialogClose = () => {
        setState({
            ...state,
            isBalancesChangeResponseDialogOpen: false,
            isVoucherChangeDialogOpen: false
        });
    };
    return (
        <Box className='page-main-box'>
            {isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box sx={{display: 'block', width: '100%'}}>
                    <Box>
                        <Stack spacing={2} direction="row" sx={{pt: 2, alignItems: 'center'}}>
                            <Button id="increase-button"
                                    variant="contained"
                                    color="warning"
                                    onClick={handleChangeBalanceButton}
                            >
                                Создать
                            </Button>
                        </Stack>
                    </Box>

                    <VoucherCreateDialog onClose={handleClose}
                                         open={state.isVoucherChangeDialogOpen}
                                         refetchBalance={refetch}
                    />
                    {state.balanceChangeResponse &&
                        <ResponseDialog
                            onClose={handleChangeResponseDialogClose}
                            open={state.isBalancesChangeResponseDialogOpen}
                            responseData={state.balanceChangeResponse ? state.balanceChangeResponse : {}}
                            label={"Seniority program"}
                            refetch={refetch}/>
                    }
                    <Box sx={{width: '100%', height: 600, mt: 2}}>
                        <DataGrid
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            rows={vouchers}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                        />
                    </Box>
                </Box>
            }
            <Outlet/>
        </Box>
    );
}