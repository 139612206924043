import * as React from 'react';
import {
    Box,
    CircularProgress, FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from "@mui/material";
import {useGetBalancesQuery} from "../../../core/api/balance-api";
import {format} from "date-fns";
import {useState} from "react";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import ExportGridToExcel from "../ExportGridToExcel";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {DatePicker} from '@mui/x-date-pickers/DatePicker'

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'createdAt', headerName: 'Дата', width: 100},
    {field: 'fullName', headerName: 'Кому начислено', width: 200},
    {field: 'sum', headerName: 'Начисление/Списание', width: 80},
    {field: 'changeType', headerName: 'Тип', width: 200},
    {field: 'comment', headerName: 'Комментарий', width: 300},
    {field: 'authorName', headerName: 'Автор', width: 300},
    {field: 'period', headerName: 'Период', width: 300},

];
export default function BalanceReport() {
    const [state, setState] = useState({
        dateFrom: new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 5),
        dateTo: new Date(),
        isShowActive: true,
        radioState: "active"
    });

    const {data: balances = [], isFetching} = useGetBalancesQuery({
        dateFrom: format(state.dateFrom, "dd.MM.yyyy HH:mm"),
        dateTo: format(state.dateTo, "dd.MM.yyyy HH:mm"),

        isForActive: state.isShowActive
    });

    const handleChangeShowActive = (event) => {
        setState({
            ...state,
            radioState: event.target.value,
            isShowActive: event.target.value === "active"
        })
    }

    return (
        isFetching ?
            <Box sx={{display: 'flex'}}>
                <CircularProgress/>
            </Box> :
            <Box sx={{mt: 2}}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Начало периода"
                        value={state.dateFrom}
                        onChange={(newValue) => {
                            setState({
                                ...state,
                                dateFrom: newValue
                            });
                        }}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Конец периода"
                        value={state.dateTo}
                        onChange={(newValue) => {
                            setState({
                                ...state,
                                dateTo: newValue
                            });
                        }}
                        renderInput={(params) => <TextField
                            sx={{mr: 2}}
                            {...params} />}
                    />
                </LocalizationProvider>
                <FormControl sx={{mr: 2}}>
                    <FormLabel id="demo-row-radio-buttons-group-label">Сотрудники</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={state.radioState}
                        onChange={handleChangeShowActive}
                    >
                        <FormControlLabel value="active" control={<Radio />} label="Активные" />
                        <FormControlLabel value="inactive" control={<Radio />} label="Неактивные" />
                    </RadioGroup>
                </FormControl>

                <ExportGridToExcel
                    filename={"report"}
                    data={balances}/>
                <Box sx={{width: '100%', height: 600, mt: 2}}>
                    <DataGrid
                        localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                        rows={balances}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[10]}
                    />
                </Box>
            </Box>

    );
}