import React from 'react';
import BcIcon from "../../img/CoinOrange.png";
import PropTypes from "prop-types";
import {Box} from "@mui/material";

export default function ProductPrice(props) {
    return (
        <Box sx={{display: 'flex'}}>
            <div className={"product-card__price"}>
                <img src={BcIcon}/>
                <span>{props.salePrice ? props.salePrice: props.price}</span>
            </div>
            {
                props.salePrice &&
                <span className={"strikeout-price"}>{props.price}</span>
            }
        </Box>


    );
}

ProductPrice.propTypes = {
    style: PropTypes.string,
    salePrice: PropTypes.number,
};