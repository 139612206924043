import * as React from 'react';
import {Button, Chip} from "@mui/material";
import PropTypes from "prop-types";
import XLSX from "xlsx";

export default function ExportGridToExcel(props) {
    const handleDownloadData = () => {
        const ws = XLSX.utils.json_to_sheet(props.data);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, props.filename +".xlsx");
    }
    return (
    <Button variant="contained"
            color="warning"
            onClick={handleDownloadData}
            style={{ fontSize: '13px' }}
    >
        Выгрузить в файл
    </Button>
);
}
ExportGridToExcel.propTypes = {
    filename: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};