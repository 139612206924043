export const COUNTRIES = ["Россия",
    "Беларусь",
    "Украина",
    "Литва",
    "Польша",
    "Кипр",
    "Грузия",
    "Черногория",
];

function getKeycloackConfig() {

    let cfg = {}
    switch (document.location.host) {
        case "localhost:3000": {
            cfg = {
                "realm": "master",
                "url": "http://localhost:9877/auth/",
                "ssl-required": "none",
                "resource": "belcoinshop-dev",
                "public-client": true,
                "clientId": "belcoinshop-dev"
            }
            break;
        }
        case "belcoinshop.stage.belka-games.com": {
            cfg = {
                "realm": "master",
                "url": "https://auth.ita.belka-games.com/",
                "ssl-required": "none",
                "resource": "belcoinshop-dev",
                "public-client": true,
                "clientId": "belcoinshop-dev"
            }
            break;
        }
        default: {
            cfg = {
                "realm": "master",
                "url": "https://auth.ita.belka-games.com/",
                "ssl-required": "none",
                "resource": "belcoinshop",
                "public-client": true,
                "clientId": "belcoinshop"
            }
        }
    }
    return cfg;
}

function getBaseUrl() {
    switch (document.location.host) {
        case "localhost:3000": {
            return "http://localhost:8081/"
        }
        case "belcoinshop.stage.belka-games.com": {
            return "https://belcoinshop-back.stage.belka-games.com/"

        }
        default:
            return "https://belcoinshop-back.ita.belka-games.com/"
    }
}

function getEnvironment() {
    switch (document.location.host) {
        case "localhost:3000": {
            return "local"
        }
        case "belcoinshop.stage.belka-games.com": {
            return "development"
        }
        default:
            return "production"
    }
}

export const CONFIGS = {
    keycloack: getKeycloackConfig(),
    baseUrl: getBaseUrl(),
    environment: getEnvironment()
}