import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

export const balanceApi = api.injectEndpoints({
    endpoints: (build) => ({
        getEmployeeBalance: build.query({
            query: (params) => {
                return params.isAdminMode ? `balance/${params.employeeEmail}` : `balance`},
            transformResponse: (response, meta, arg) => {
                return response.map((balance, index) =>
                    Object.assign({}, balance, {ind: index})
                )
            }
        }),
        getTotalBalance: build.query({
            query: () => {
                return `balance/total`
            },
        }),
        getTotalBalanceDate: build.query({
            query: (date) => {
                return `balance/totalDate?date=${date}`
            },
        }),
        getBalances: build.query({
            query: (period) => `balance/balances?dateFrom=${period.dateFrom}&dateTo=${period.dateTo}&isForActive=${period.isForActive}`,
            transformResponse: (response, meta, arg) => {
                return  response.map((balance, index) =>
                    Object.assign({},  {
                        id: balance.id,
                        createdAt: format(getDateInServerTimeZone(balance.createdAt), "dd.MM.yyyy"),
                        changeType: balance.changeType,
                        comment: balance.comment,
                        sum: balance.sum,
                        fullName: balance.employee !== null ? balance.employee.fullName : "Сотрудник не найден",
                        authorName: balance.authorName,
                        period: balance.period
                    })
                )
            }
        }),
        updateBalance: build.mutation({
            query: (body = {}) => ({
                url: `balance/update_balance`,
                method: "POST",
                body: body
            }),
            transformResponse: (response, meta, arg) => response,
        })
    }),
})

export const {
    useGetEmployeeBalanceQuery,
    useGetBalancesQuery,
    useUpdateBalanceMutation,
    useGetTotalBalanceQuery,
    useGetTotalBalanceDateQuery
} = balanceApi