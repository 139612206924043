import * as React from 'react';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Container} from "@mui/material";

export default function LinkTabs(props) {
    const {tabs, tabState, hideAdminTab, hideRequestTab, hideSeniorityTab} = props;
    const [state, setState] = useState({
        tab: tabState
    })
    const isAdminTab = props.label === "Admin";


    const handleTabChange = (event, newTabValue) => {
        setState({
            ...state,
            tab: newTabValue})
    };

    useEffect(() => {
        handleTabChange(null, tabState)
    }, [tabState])

    return (
            <Tabs
                value={state.tab}
                onChange={handleTabChange}
                aria-label="shop-tabs"

                className={"menu"}
            >
                {
                    props.tabs.map(
                        ({label, icon, path}, index) => {
                            if (label === "Admin" && props.hideAdminTab) {
                                return;
                            }
                            if (label === "Начисление" && props.hideRequestTab) {
                                return;
                            }
                            if (label === "Seniority" && props.hideSeniorityTab) {
                                return;
                            }
                            return <Tab key={`tab_${index}`}
                                        sx={{p: 1}}
                                        label={label}
                                        iconPosition="bottom"
                                        icon={icon}
                                        component={Link}
                                        to={path}/>
                        }

                    )
                }
            </Tabs>
    );
}
LinkTabs.propTypes = {
    tabs: PropTypes.array.isRequired,
    tabState: PropTypes.number.isRequired,
    hideAdminTab: PropTypes.bool.isRequired,
    hideRequestTab: PropTypes.bool.isRequired,
    hideSeniorityTab: PropTypes.bool.isRequired
}

