import PropTypes from "prop-types";
import {Box, Button, CardContent, Chip, Grid, Typography} from "@mui/material";
import Card from "@mui/material/Card";
import StraightenIcon from "@mui/icons-material/Straighten";
import SizeTableDialog from "../Shop/SizeTableDialog";
import * as React from "react";
import {useEffect, useMemo, useState} from "react";
import ProductImageViewer from "../Shop/ProductImageViewer";
import DOMPurify from 'dompurify';


export default function PresentProduct (props) {
    const {product, selectVariations, isForSelect, selectPresent} = props;
    const isProductHasVariations = product.variations !== null && product.variations.length !== 0;
    const [state, setState] = useState({
        selectedVariant: "",
        isSizeTableOpen: false,
        sizeTableImage: "",
        variations: [],
        isForSelect: isForSelect
    });

    useEffect(() => {

        setState({
            ...state,
            sizeTableImage: product.sizeTableImage
        });

    }, [product])


    function useSanitizedHtml(dirtyHtml) {
        const cleanHtml = useMemo(() => DOMPurify.sanitize(dirtyHtml), [dirtyHtml]);
        return cleanHtml;
    }

    const handleSizeTableLinkClick = () => {
        setState({
            ...state,
            isSizeTableOpen: true
        })
    }

    const handleCloseSizeTable = () => {
        setState({
            ...state,
            isSizeTableOpen: false
        })
    };

    const handleVariantClick = (variation) => {
        selectVariations(product.id, variation)
        props.setVariationErrors(product.id)
        setState({
            ...state,
            selectedVariant: variation,
        })
    };

    function handleOrder() {
        selectPresent(product.id)
    }

    return(
        <Box sx={{m:1}}>
            <Grid container spacing={2} sx={{maxWidth: '800px'}}>

                <Grid item sm ={6} xs={8}>
                    <Typography gutterBottom variant="h3" component="div">
                        {product.title}
                    </Typography>
                    <Card>
                        <CardContent className={"product-page-price"}>
                            <ProductImageViewer images={product.images}/>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xs={5}>
                    <Grid item xs direction="column" spacing={2}>

                        <Grid item>
                            <Card sx={{mb: 2, marginTop: '34px'}}>
                                <CardContent>
                                    <div dangerouslySetInnerHTML={{ __html: useSanitizedHtml(product.description) }} />
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item>
                            {
                                isProductHasVariations ?
                                    <Box>
                                        <Typography vcomponent="div"
                                                    variant="h3"
                                        >
                                            Выберите размер
                                        </Typography>

                                        <Card sx={{mb: 3}}>
                                            <CardContent>
                                                <div className="variations">
                                                    <Box className={"product-variation"}>
                                                        {product && product.variations.map((variation, index) =>
                                                          <Chip
                                                                className={`variation__item ${state.selectedVariant === variation ? "selected" : ""}`}
                                                                key={`variations_chip_${index}`}
                                                                id={`chip_${variation}`}
                                                                variant="outlined"
                                                                onClick={() => handleVariantClick(variation)}
                                                                icon={<StraightenIcon
                                                                    sx={{pointerEvents: 'none'}}/>}
                                                                label={variation}
                                                            />
                                                        )}
                                                        {props.variationErrors &&
                                                            <p className="error-helper-text">
                                                                *Необходимо выбрать размер
                                                            </p>}
                                                    </Box>
                                                </div>
                                                {(product.sizeTableImage !== null && product.sizeTableImage !== "") &&
                                                    <Box sx={{mt: 2}}>
                                                        <Button
                                                            size="small"
                                                            color="secondary"
                                                            key={`size_table_chip`}
                                                            id={`size_table_chip`}
                                                            onClick={handleSizeTableLinkClick}
                                                            label=""

                                                        >

                                                            Таблица размеров
                                                        </Button>

                                                        <SizeTableDialog onClose={handleCloseSizeTable}
                                                                         open={state.isSizeTableOpen}
                                                                         sizeTableImageSrc={state.sizeTableImage}/>
                                                    </Box>
                                                }
                                            </CardContent>
                                        </Card>
                                    </Box>
                                    : null
                            }
                            {isForSelect &&

                                <Button
                                    sx={{mt: 2}}
                                    id="order-button"
                                    variant="contained"
                                    color="warning"
                                    onClick={handleOrder}

                                >
                                    Оформить
                                </Button>
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
}

PresentProduct.propTypes = {
    product: PropTypes.object.isRequired,
    selectVariations: PropTypes.func.isRequired,
    variationErrors: PropTypes.bool.isRequired,
    setVariationErrors: PropTypes.func.isRequired,
    isForSelect: PropTypes.bool.isRequired,
    selectPresent: PropTypes.func.isRequired,
};