import * as React from 'react';
import {
    Avatar,
    Box, Button,
    CircularProgress, Divider,
    Link,
    List,
    ListItem,
    ListItemAvatar, ListItemButton, ListItemIcon,
    ListItemText,
    Typography
} from "@mui/material";
import {useNavigate, useParams} from "react-router";
import {useGetOrderQuery} from "../../../core/api/order-api";
import SignalCellularAltIcon from '@mui/icons-material/SignalCellularAlt';
import LinkIcon from '@mui/icons-material/Link';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {mapOrderStatus} from "../../../core/utils/utils";
import {format} from "date-fns";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ResponseDialog from "../../ResponseDialog";
import OrderCancelDialog from "./OrderCancelDialog";
import {useState} from "react";
import OrderCompleteDialog from "./OrderCompleteDialog";

export default function Order(props) {
    const {orderId} = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState({
        isOpenCancelDialog: false,
        isOrderCancelResponseDialogOpen: false,
        orderCancelResponse: {},
        isOpenCompleteDialog: false,
        isOrderCompleteResponseDialogOpen: false,
        orderCompleteResponse: {}
    });
    const {data: response = {}, isFetching, isSuccess} = useGetOrderQuery(orderId)

    const handleCancelOrder = () => {
        setState({
            ...state,
            isOpenCancelDialog: true
        })
    }

    const handleCompleteOrder = () => {
        setState({
            ...state,
            isOpenCompleteDialog: true
        })
    }
    const handleCancelClose = (balanceChangeResponse) => {
        setState({
            ...state,
            isOrderCancelResponseDialogOpen: balanceChangeResponse.isResponseReady,
            orderCancelResponse: balanceChangeResponse.data,
            isOpenCancelDialog: false
        });
    };

    const handleCompleteClose = (balanceChangeResponse) => {
        setState({
            ...state,
            isOrderCompleteResponseDialogOpen: balanceChangeResponse.isResponseReady,
            orderCompleteResponse: balanceChangeResponse.data,
            isOpenCompleteDialog: false
        });
    };

    const handleChangeResponseDialogClose = () => {
        setState({
            ...state,
            isOrderCancelResponseDialogOpen: false,
            isOrderCompleteResponseDialogOpen: false
        });
        navigate("/admin/orderHistory")
    };


    return (
        isSuccess ?
            <Box sx={{mt: 2}}>

                <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <Typography variant={"body"} sx={{ml: 2, mb: 2}}>
                        Заказ <b>{response.productTitle}</b> от {response.fullName}
                    </Typography>

                    {response && !response.isCharity &&
                        <ListItem>
                            <ListItemIcon>
                                <LinkIcon />
                            </ListItemIcon>
                            <ListItemButton sx={{p: 0}} component={Link} to={response.asanaLink}>Ссылка на задачу
                            </ListItemButton>
                        </ListItem>
                    }
                    <ListItem>
                        <ListItemIcon>
                            <LocalOfferIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Цена: ${response.price}`}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <CalendarMonthIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Дата создания: ${ format(new Date(Date.parse(response.createdAt)), "dd.MM.yyyy HH:mm")}`} />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <SignalCellularAltIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Статус: ${mapOrderStatus(response.status)}`}/>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary={`Локация: ${response.deliveryAddress}`}/>
                    </ListItem>
                    { response && !response.isCharity &&
                        <Box>
                            <Button
                                sx={{m: 2}}
                                variant="contained"
                                color="warning"
                                disabled={response.status === "CANCELED"}
                                onClick={handleCancelOrder}
                            >
                                Отменить заказ
                            </Button>
                            <Button
                                sx={{m: 2}}
                                variant="contained"
                                color="warning"
                                disabled={response.status === "CANCELED"}
                                onClick={handleCompleteOrder}
                            >
                                Вручено
                            </Button>
                        </Box>
                }
                </List>

                <OrderCancelDialog onClose={handleCancelClose}
                                      open={state.isOpenCancelDialog}
                                      orderId={orderId}
                />
                <OrderCompleteDialog onClose={handleCompleteClose}
                                     open={state.isOpenCompleteDialog}
                                     orderId={orderId}
                />
                {state.orderCancelResponse &&
                    <ResponseDialog
                        onClose={handleChangeResponseDialogClose}
                        open={state.isOrderCancelResponseDialogOpen}
                        responseData={state.orderCancelResponse ? state.orderCancelResponse : {}}
                        label={"Отмена заказа"}/>
                }
                {state.orderCompleteResponse &&
                    <ResponseDialog
                        onClose={handleChangeResponseDialogClose}
                        open={state.isOrderCompleteResponseDialogOpen}
                        responseData={state.orderCompleteResponse ? state.orderCompleteResponse : {}}
                        label={"Готовность заказа"}/>
                }
            </Box> :
            <CircularProgress/>
    );
}

