import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Chip, DialogActions, DialogContent, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useUpdateBalanceMutation} from "../../../core/api/balance-api";
import ResponseDialog from "../../ResponseDialog";
import {LoadingButton} from "@mui/lab";
import {useCompleteOrderMutation} from "../../../core/api/order-api";


export default function OrderCompleteDialog(props) {
    const {onClose, open, orderId} = props;
    const [completeOrder, completeOrderResponse] = useCompleteOrderMutation();
    const [state, setState] = useState({
        reason: "",
        isCancelConfirmDialogOpen: false,
        isRequestLoading: false,
        isCancelOrderClick: false,
        isResponseReady: false,
        validateError: {}
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isCancelOrderClick && (completeOrderResponse.status !== 'rejected' || completeOrderResponse.status !== 'fulfilled');
        const isResponseReady = (completeOrderResponse.status === 'rejected' || completeOrderResponse.status === 'fulfilled')
        setState({...state,
            isRequestLoading: isResponseLoadingDialog,
            isCancelOrderClick: false})
        if (isResponseReady) {
            handleClose(true);
        }

    }, [completeOrderResponse])

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: false,
            isResponseReady: false,
            isCancelOrderClick: false,
            validateError: {},
            isCancelConfirmDialogOpen: true
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: completeOrderResponse
        });
    };

    const handleCancelOrder = (event) => {

            completeOrder({
                orderId: orderId,
                reason: state.reason
            })
            setState({...state,
                isCancelOrderClick: true
            })
    };

    const handleCommentChange = (event) => {
        setState({...state, reason: event.target.value})
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.reason === null || state.reason.trim() === "") {
            errors = {...errors, reason: true};
        }
        setState({...state, validateError: errors})
        return Object.keys(errors).length === 0;
    }

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
               Подтведить готовность заказа?
            </DialogTitle>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена</Button>
                <LoadingButton
                    size="small"
                    onClick={handleCancelOrder}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    Подтвердить
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
}

OrderCompleteDialog.propTypes = {
    orderId: PropTypes.string.isRequired,
};
