import * as React from 'react';
import PropTypes from 'prop-types';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Box, DialogContent, IconButton} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import "./SizeTableDialog.css"
import {styled} from "@mui/material/styles";
import {useEffect} from "react";


const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

function BootstrapDialogTitle(props) {
    const {children, onClose, ...other} = props;

    return (
        <DialogTitle sx={{m: 0, p: 2}} {...other}>
            {children}
            {onClose ? (
                <IconButton
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon/>
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}

BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};

export default function SizeTableDialog(props) {
    const {onClose, open, sizeTableImageSrc} = props;

    const handleClose = () => {
        onClose(true);
    };

    return (
        <Dialog fullWidth
                maxWidth="sx"
                className="custom-size-table-dialog"
                onClose={handleClose}
                open={open}>
            <BootstrapDialogTitle id="customized-dialog-title"
                                  onClose={handleClose}>
                Таблица размеров
            </BootstrapDialogTitle>
            <DialogContent>
                {
                    (sizeTableImageSrc != null && sizeTableImageSrc!== "") &&
                    <Img
                        key={`size-table-img`}
                        sx={{width: "100%"}}
                        alt="Таблица размеров"
                        src={sizeTableImageSrc}/>

                }

            </DialogContent>
        </Dialog>
    );
}

SizeTableDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    sizeTableImageSrc: PropTypes.string
};
