import * as React from 'react';
import {Box, CircularProgress} from "@mui/material";
import ProductCard from "./ProductCard";
import {useGetAllProductsQuery} from "../../core/api/products-api";
import {Outlet, useLocation} from "react-router";
import {useSelector} from "react-redux";
import {isAdminMode} from "../../core/utils/utils";


export default function Shop(props) {
    const location = useLocation()
    const isAdmin = isAdminMode(location.pathname);
    const sessionState = useSelector((state) => state.data.UserSession);
    const userSelectedLocation = !isAdmin ? sessionState.usersDetails.location : "";
    const {data: products = [], isFetching, isSuccess, refetch} = useGetAllProductsQuery(userSelectedLocation ? userSelectedLocation : "");
    let sortedProducts = [...products];
    sortedProducts
        .sort(function (a,b) {
        let indexA = a.sortingOrder;
        let indexB = b.sortingOrder;
        if (indexA < indexB) {
            return -1;
        }
        return 1;
    });
    return (
        <Box className='page-main-box'>
            {isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box sx={{display: 'flex', flexWrap: 'wrap'}} className={"product-cards"}>
                    {
                        isSuccess && sortedProducts.length !== 0 ?
                            sortedProducts.map((product, index) =>
                                <ProductCard
                                    key={`product_card_${index}`}
                                    product={product}
                                    isAdminMode={isAdmin}
                                    refetchProduct={refetch}
                                />) :
                            <Box>
                                В магазине пока нет продуктов
                            </Box>
                    }
                    <Outlet/>
                </Box>
            }
        </Box>


    );
}

