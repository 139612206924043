import React from 'react';
import Type from 'prop-types';
import { Route, Router } from 'react-router-dom';

import App from '../../containers/App';
import Header from '../Common/Header';
import NotFoundPage from '../NotFound';
import {Box, Button} from "@mui/material";
import {keycloak} from "../../keycloak-config";

//import BelkaShopping from "../../img/belka_shopping.png"
import BelkaShopping from "../../img/bsh-main.png"


class Site extends React.Component {

    static propTypes = {
    };

    state = {
    };

    render() {
        return (
            <Box className="page-main-box belka-login-bg"
                 sx={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                <img src={BelkaShopping}  onClick={keycloak.login} />
            </Box>
        );
    }
}

export default Site;
