import * as React from 'react';
import Box from '@mui/material/Box';

import {useEffect, useState} from "react";
import {
    CardContent, Container,
    FormControl, FormLabel,
    InputLabel,
    MenuItem,
    Select, Tooltip, Typography
} from "@mui/material";
import {useGetUserDetailsQuery, useSetUserLocationMutation} from "../../core/api/users-api";
import LinkTabs from "../Common/LinkTabs";
import {Outlet, useLocation, useNavigate} from "react-router";
import {getTabMainState, getTabState} from "../../core/utils/utils";
import {store} from "../../core/utils/configureStore";
import {COUNTRIES} from "../../constants";
import ButtonBase from "@mui/material/ButtonBase";
import ErrorBox from "../Common/ErrorBox";
import {useSelector} from "react-redux";
import ProductPrice from "../Common/PriceComponent";
import {useCheckVoucherQuery} from "../../core/api/seniority-api";

const MenuProps = {
    PaperProps: {
        sx: {
            maxWidth: "fit-content"
        },
    },
};

const MAIN_TABS = [
    {
        label: "Seniority",
        icon: <span className={"menu__seniority"}/>,
        path: "/seniority"
    },
    {
        label: "Магазин",
        icon: <span className={"menu__shop"}/>,
        path: "/products"
    },
    {
        label: "Заказы",
        icon: <span className={"menu__history"}/>,
        path: "/history"
    },
    {
        label: "Баланс",
        icon: <span className={"menu__balance"}/>,
        path: "/Balance"
    },
    {
        label: "Правила",
        icon: <span className={"menu__rules"}/>,
        path: "/rules/shop"
    },
    {
        label: "Начисление",
        icon: <span className={"menu__admin"}/>,
        path: "/request"
    },
    {
        label: "Admin",
        icon: <span className={"menu__admin"}/>,
        path: "/admin/orderHistory"
    },

]

export default function MainTabs() {
    const {data: response = [], error: errors = {}, isError} = useGetUserDetailsQuery();
    //const {data: voucher, isSuccess} = useCheckVoucherQuery();
    const [setLocation] = useSetUserLocationMutation()
    const location = useLocation();
    const [state, setState] = useState({
        userLocation: "",
        errorData: "Доступ запрещен",
        userHasSeniorityVoucher: response.hasSeniorityVoucher
    })
    const sessionState = useSelector((state) => state.data.UserSession)
    const isUserHasAdminRole = sessionState.user.isAdmin;
    const isUserHasManagerRole = sessionState.user.isManager;

    const navigate = useNavigate();
    let adminLocation = location.pathname.includes("admin");
    let presentLocation = location.pathname.includes("present");

    useEffect(() => {

       if (!isError) {
           setState({
                ...state,
               userHasSeniorityVoucher: response.hasSeniorityVoucher,
               userLocation: response.location
            })
            changeSessionEmployeeInfo(response);
        } else {
            setState({
                ...state,
                userHasSeniorityVoucher: response.hasSeniorityVoucher,
                errorData: errors.data.error
            })
        }

    }, [response, isError])

    const changeSessionEmployeeInfo = (employeeInfo) => {
        store.dispatch({
            type: 'USER_INFO_CHANGE', payload: {
                data: {
                    fullName: employeeInfo.fullName,
                    location: employeeInfo.location,
                    phone: employeeInfo.phone,
                    balance: employeeInfo.balance,
                    hasSeniority: employeeInfo.hasSeniorityVoucher
                }
            }
        })
    }

    const handleLocationChange = (event) => {
        const newLocation = event.target.value;
        setState({
            ...state,
            userLocation: newLocation
        })

        setLocation({
            location: newLocation
        })

        store.dispatch({
            type: 'LOCATION_CHANGE', payload: {
                data: {
                    location: newLocation
                }
            }
        })
    }

    const handleBalanceClick = () => {
        navigate('/Balance');
    }
    return (
        isError ?
            <ErrorBox
                error={state.errorData
                }/> :
            <Box>

            <Container className={"navbar-container"}>
                <Box className="navbar" sx={{
                    paddingTop: 3, paddingBottom: 2, borderBottom: 0, borderColor: 'divider', display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>

                    <FormControl component="fieldset" variant="standard" className={"balance-form"}>
                        <FormLabel className={"balance-legend"}> Баланс </FormLabel>
                        <Box className="navbar__balance">
                            <Tooltip title="Твой баланс белкоинов">
                                <ButtonBase sx={{m: 1}}
                                            onClick={handleBalanceClick}>
                                    <ProductPrice price={response.balance ? response.balance : "0"}/>

                                </ButtonBase>
                            </Tooltip>

                        </Box>
                    </FormControl>

                    <LinkTabs
                        tabs={MAIN_TABS}
                        tabState={getTabMainState(MAIN_TABS, location.pathname, response.hasSeniorityVoucher)}
                        hideAdminTab={!isUserHasAdminRole}
                        hideRequestTab={!isUserHasManagerRole}
                        hideSeniorityTab={!response.hasSeniorityVoucher}/>
                    <FormControl className="navbar__location" fullWidth>
                        <InputLabel
                            color="warning"
                            id="location-select-label">
                            Локация
                        </InputLabel>
                        <Select
                            color="warning"
                            labelId="location-select-label"
                            id="location-select"
                            value={state.userLocation ? state.userLocation : ''}
                            label="location"
                            onChange={handleLocationChange}
                            MenuProps={MenuProps}

                        >
                            {COUNTRIES.map((option, index) => (
                                <MenuItem key={`location_select_image_${index}`}
                                          value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

            </Container>

                {(adminLocation || presentLocation) ?
                    <Container maxWidth={false}>
                    <Outlet/>
                </Container>:
                    <Container>
                        <Outlet/>
                    </Container>
                }


            </Box>
    );
}