import * as React from 'react'
import {Box, CircularProgress, Grid, Typography} from "@mui/material";
import LocationCard from "./LocationCard";
import {useGetUserDetailsQuery} from "../../core/api/users-api";
import {useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {COUNTRIES} from "../../constants";
import {store} from "../../core/utils/configureStore";

const LOCATIONS = [
    {
        name: "Россия",
        id: "russia",
        icon: "russia-country-icon"
    },
    {
        name: "Беларусь",
        id: "belarus",
        icon: "belarus-country-icon"
    },

    {
        name: "Украина",
        id: "ukraine",
        icon: "ukraine-country-icon"
    },

    {
        name: "Литва",
        id: "lithuania",
        icon: "lithuania-country-icon"
    },

    {
        name: "Польша",
        id: "poland",
        icon: "poland-country-icon"
    },

    {
        name: "Грузия",
        id: "georgia",
        icon: "georgia-country-icon"
    },

    {
        name: "Кипр",
        id: "cyprus",
        icon: "cyprus-country-icon"
    },

    {
        name: "Черногория",
        id: "montenegro",
        icon: "montenegro-country-icon"
    },
];

export default function MainLocation() {
    const {data: response = [], isFetching} = useGetUserDetailsQuery();
    const navigate = useNavigate();
    useEffect(() => {
        if (! isFetching) {
            if (COUNTRIES.includes(response.location)) {
                store.dispatch({
                    type: 'LOCATION_CHANGE', payload: {
                        data: {
                            location: response.location,
                        }
                    }
                })

                store.dispatch({
                    type: 'PHONE_CHANGE', payload: {
                        data: {
                            phone: response.phone,
                        }
                    }
                })
                navigate("products")
            }
        }

    }, [response])
    return (

        <Box className='page-main-box'>
            {isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box className="page-main-box"
                     sx={{flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between'}}>
                    <Box
                        width={"100%"}
                        padding={4}
                    >
                        <div className={"text-centered"}>
                            <h2 className={"colored-title"}>
                                Выбери свою локацию
                            </h2>
                        </div>
                    </Box>
                    <Box sx={{flexGrow: 1}} >
                        <Grid container spacing={{xs: 2, md: 3}} columns={{xs: 4, sm: 8, md: 12}}>
                            {LOCATIONS.map((country, index) => (
                                <Grid item xs={2} sm={4} md={4} key={index}>
                                    <LocationCard locationName={country.name}
                                                  locationId={country.id}
                                                  locationIconPath={country.icon}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            }
        </Box>


    );
}