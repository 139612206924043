import * as React from 'react';
import {Box} from "@mui/material";

export default function Rules() {
    return (
        <Box className='page-main-box'>
            <table data-number-column="false" >
                <tbody>
                <tr className={"table-header"}>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4"><span
                            className="fabric-text-color-mark" data-renderer-mark="true">Вопрос</span></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="14"><span
                            className="fabric-text-color-mark" data-renderer-mark="true">Ответ</span></p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="51">Курс BelCoin</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="67">1 BelCoin = 10$</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="88">Привязка пользователя к локации</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <ul className="ak-ul" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="125">Для каждой страны в магазине доступен свой перечень
                                    товаров, исходя из специфики региона.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="219">При первом входе в личный кабинет локация
                                    трудоустройства автоматически подтягивается из People Force.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="326">При необходимости локацию можно изменить.</p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="375">Как <strong data-renderer-mark="true"><u
                            data-renderer-mark="true">оформить заказ</u></strong>?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="398">Для оформления заказа необходимо:</p>
                        <ul className="ak-ul" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="436">Выбрать локацию, в которой вы хотите забрать заказ</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="491">Выбрать товар, доступный для данной локации</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="539">Перейти в карточку товара</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="569">Выбрать размер (если применимо)</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="604">Перейти к оформлению.</p>
                            </li>
                        </ul>
                        <p data-renderer-start-pos="630">Если товар <strong data-renderer-mark="true">есть в наличии на
                            складе</strong>, то доступное к заказу количество будет отображено при заказе.</p>
                        <p data-renderer-start-pos="730">Если товара <strong data-renderer-mark="true">в наличии
                            нет</strong>, то появится соответствующее уведомление. В таком случае можно оформить товар
                            под заказ, однако стоит учитывать, что изготовление и доставка займут более длительный
                            промежуток времени (<strong data-renderer-mark="true">2-3 месяца вместо 1-2, не относится к
                                технике</strong>).</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="994">Как <strong data-renderer-mark="true"><u
                            data-renderer-mark="true">сделать подарок?</u></strong></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="1018">Подарок оформляется по такому же принципу, как и обычный
                            заказ, только вместо кнопки <strong
                                data-renderer-mark="true">&ldquo;оформить&rdquo;</strong> необходимо нажать на
                            кнопку <strong data-renderer-mark="true">&ldquo;подарить&rdquo;</strong>.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="1159">Какой<strong data-renderer-mark="true"> <u
                            data-renderer-mark="true">процесс обработки заказа (для себя или в подарок) в отношении
                            брендированных товаров?</u></strong></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <ul className="ak-ul" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="1256">Заказ автоматически формируется в задачу в Асане и
                                    попадает в систему для дальнейшей обработки.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="1355">После того, как обработка начнется, в задаче появится
                                    статус <strong data-renderer-mark="true">&ldquo;в работе&rdquo;.</strong></p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="1431">Если в задаче указаны некорректные данные или
                                    требуются какие-то дополнения/изменения, все обсуждается в комментариях к данной
                                    задаче.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="1569">Также на данном этапе происходит определение
                                    необходимости изготовления товара, либо подтверждение наличия заказанного товара на
                                    складе.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="1709">Далее задача по заказу товара переставляется на
                                    офис-менеджера Литвы.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="1782">После того, как товар будет готов к выдаче (Литва),
                                    либо к отправке (остальные локации), в задаче появится статус&nbsp; <strong
                                        data-renderer-mark="true">&ldquo;доставка&rdquo;</strong>.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="1912">Далее задача переводится на сотрудника-заказчика (при
                                    адресной доставке), либо на офис-менеджера локации (при доставке в офис).</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="2043">Офис-менеджер при получении посылки в офис
                                    отписывается в соответствующей задаче, что заказ готов к получению, и переводит
                                    задачу на заказчика товара (или на сотрудника принимающего подарок в случае
                                    дарения).</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="2255">После вручения товара сотруднику офис-менеджер
                                    переставляет задачу в <strong
                                        data-renderer-mark="true">раздел &ldquo;готов&ldquo;</strong> и делает
                                    соответствующий комментарий в задаче.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="2389">После получения товара сотрудник-заказчик (или
                                    сотрудник принимающий подарок в случае дарения) подтверждает это из личного кабинета
                                    в Belcoin Shop. Далее бот автоматически закрывает задачу о заказанном товаре.</p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="2606">Какой<strong data-renderer-mark="true"> <u
                            data-renderer-mark="true">процесс обработки заказа (для себя или в подарок) в отношении
                            техники?</u></strong></p>
                        <p data-renderer-start-pos="2684">&nbsp;</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <ul className="ak-ul" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="2690">Заказ автоматически формируется в задачу в Асане и
                                    попадает в систему для дальнейшей обработки.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="2789">После того, как обработка начнется, в задаче появится
                                    статус <strong data-renderer-mark="true">&ldquo;в работе&rdquo;.</strong></p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="2865">Если в задаче указаны некорректные данные или
                                    требуются какие то дополнения/изменения, все обсуждается в комментариях к данной
                                    задаче.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="3003">Далее задача по заказу товара переставляется на
                                    офис-менеджера/бухгалтера локации заказанного товара.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="3108">Ответственный сотрудник запрашивает инвойс у
                                    поставщика, передает его на оплату в бухгалтерию и ждет получения товара в офис.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="3237">Офис-менеджер при получении техники в офис
                                    отписывается в соответствующей задаче, что заказ готов к получению или к отправке и
                                    переводит задачу на заказчика товара(или на сотрудника принимающего подарок в случае
                                    дарения), а также переставляет заказ в категорию <strong
                                        data-renderer-mark="true">&ldquo;доставка&rdquo;.</strong></p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="3513">После вручения техники сотруднику офис-менеджер
                                    переставляет задачу в раздел <strong
                                        data-renderer-mark="true">&ldquo;готов&ldquo;</strong> и делает соответствующий
                                    комментарий в задаче.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="3648">После получения товара сотрудник-заказчик (или
                                    сотрудник принимающий подарок в случае дарения) подтверждает это из личного кабинета
                                    в Belcoin Shop. Далее бот автоматически закрывает задачу о заказанном товаре.</p>
                            </li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="3865">Статусы заказов</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <ul className="ak-ul" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="3886"><strong data-renderer-mark="true">в работе </strong>-
                                    заказ находится в обработке у администратора проекта.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="3954"><strong data-renderer-mark="true">доставка</strong> -
                                    заказ изготовлен и передан на доставку.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="4008"><strong data-renderer-mark="true">готов</strong> -
                                    заказ вручен получателю.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="4044"><strong data-renderer-mark="true">отмена</strong> -
                                    заказ отменен.</p>
                            </li>
                        </ul>
                        <p data-renderer-start-pos="4071">Следить за статусом заказа можно в сформированной задаче в
                            Асане и на сайте магазина в личном кабинете.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4180">Можно ли заказать товар, который <strong
                            data-renderer-mark="true"><u data-renderer-mark="true">доступен в другой
                            локации?</u></strong></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4243">Оформить такой заказ можно. Но получить его можно будет только
                            в офисе, указанной страны. Данная опция подойдет, например, если вы планируете командировку
                            в данную локацию.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="4422">Когда можно оформить заказ?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4453">Заказ можно оформить в любое время.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="4494">Сроки оформления и доставки</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4525">Сроки доставки товаров зависят от типа товара, вашей локации и
                            от того, есть ли данный товар в наличии на складе в данный момент.</p>
                        <p data-renderer-start-pos="4656"><strong data-renderer-mark="true">Брендированные
                            товары</strong></p>
                        <p data-renderer-start-pos="4679"><u data-renderer-mark="true">При наличии товаров</u></p>
                        <p data-renderer-start-pos="4700"><em data-renderer-mark="true">Литва</em> &ndash; до 2 недель
                        </p>
                        <p data-renderer-start-pos="4721"><em data-renderer-mark="true">Кипр, Черногория,
                            Грузия </em>&ndash; до 2 месяцев (первый месяц сбор заказов, второй месяц - отправка по
                            локациям и растаможка)</p>
                        <p data-renderer-start-pos="4840"><em data-renderer-mark="true">РБ</em>- в зависимости от того,
                            есть ли сотрудники, которые едут по этому направлению из Литвы, точные сроки указать не
                            представляется возможным</p>
                        <p data-renderer-start-pos="4981"><em data-renderer-mark="true">Украина</em> &ndash; 3-6 недель
                        </p>
                        <p data-renderer-start-pos="5003"><em data-renderer-mark="true">Польша</em> &ndash; 3-6
                            недель&nbsp;</p>
                        <p data-renderer-start-pos="5025"><u data-renderer-mark="true">При отсутствии товаров:</u></p>
                        <ol className="ak-ol" start="1" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="5052">необходимо учитывать, что определённый товар может
                                    быть доступен в ограниченном количестве/партии (без возможности повторного
                                    изготовления).&nbsp;</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5197">время ожидания изготовления и доставки мерча
                                    составляет 2-3 месяца (если нет ограничений в данной партии).</p>
                            </li>
                        </ol>
                        <p data-renderer-start-pos="5307"><strong data-renderer-mark="true">Техника</strong></p>
                        <p data-renderer-start-pos="5316">Доставка осуществляется в течение 4-6 недель</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="5367">Доставка</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <ul className="ak-ul" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="5381"><strong data-renderer-mark="true">Литва</strong> -
                                    доставка осуществляется только в офис.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5431"><strong data-renderer-mark="true">Беларусь</strong> -
                                    доставка осуществляется только в офис.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5484"><strong data-renderer-mark="true">Кипр</strong> -
                                    доставка осуществляется только в офис.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5533"><strong
                                    data-renderer-mark="true">Черногория</strong> - доставка осуществляется только в
                                    офис.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5588"><strong data-renderer-mark="true">Украина</strong> -
                                    осуществляется адресная доставка.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5636"><strong data-renderer-mark="true">Польша</strong> -
                                    осуществляется адресная доставка.&nbsp;</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5683"><strong data-renderer-mark="true">Грузия</strong> -
                                    доставка осуществляется только в офис.&nbsp;</p>
                            </li>
                        </ul>
                        <p data-renderer-start-pos="5735">Доставка заказов в <strong data-renderer-mark="true">другие
                            страны </strong>осуществляется <u data-renderer-mark="true">собственными
                            силами</u> сотрудника. Для этого необходимо:</p>
                        <ol className="ak-ol" start="1" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="5840">Оформить заказ в локации, в которой доставка
                                    осуществляется в офис.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5911">Дождаться статуса заказа <strong
                                    data-renderer-mark="true">&ldquo;доставка&ldquo;</strong>.</p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="5951">Организовать доставку самовывозом заказа из офиса,
                                    предварительно договорившись о дате и времени забора в комментариях к задаче.</p>
                            </li>
                        </ol>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="6087">Можно ли вернуть или обменять заказ?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="6127">Обмен и возврат товара невозможен (за исключением случаев
                            неисправности товара).</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="6213">Можно ли внести изменение в заказ?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="6252">Изменение в заказ можно внести, пока заказ находится в
                            статусе &ldquo;в работе&rdquo;.</p>
                        <p data-renderer-start-pos="6328">После передачи заказа в доставку, внести изменение в заказ
                            невозможно.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="6404">Можно ли отменить заказ?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="6432">Есть 2 варианта отмены заказа:</p>
                        <ol className="ak-ol" start="1" data-indent-level="1">
                            <li>
                                <p data-renderer-start-pos="6467">Пока заказ находится в статусе <strong
                                    data-renderer-mark="true">&ldquo;в работе&rdquo;,</strong> можно его
                                    отменить <strong data-renderer-mark="true">с возвратом BelCoins</strong> на счет.
                                </p>
                            </li>
                            <li>
                                <p data-renderer-start-pos="6563">Если отмена происходит после передачи заказа для
                                    доставки, <strong data-renderer-mark="true">BelCoins на счет не
                                        возвращаются.</strong></p>
                            </li>
                        </ol>
                        <p data-renderer-start-pos="6659">Для того, чтобы отменить заказ, необходимо написать об этом в
                            комментариях к заказу с указанием причины и дождаться ответа администратора проекта. После
                            этого задача будет закрыта.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="6846">Размерная сетка</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="6865">Под каждое изделие на сайте указана своя размерная сетка,
                            которая находится на странице товара.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="6967">Возможна ли компенсация стоимости товара в денежном
                            эквиваленте?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="7035">Невозможна.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="7052">Возможно ли передавать накопленные Belcoins коллегам?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="7109">Невозможно.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="7126">Сгорают ли накопленные Belcoins</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="7161">Belcoins действительны весь срок трудоустройства
                            сотрудников.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="7228">Могу ли я получить свой заказ после увольнения?</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="7279">В случае, если вы увольняетесь и у вас есть заказанные в
                            магазине товары, пожалуйста, сообщите об этом заранее в комментариях к задаче и ваш случай
                            будет рассмотрен в индивидуальном порядке.</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4233">Администратор проекта</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="340">
                        <p data-renderer-start-pos="4258"><a className="css-tgpl01"
                                                             title="https://app.slack.com/client/T7Q18QQ0Y/D02QGF8A1U2/rimeto_profile/U03H9S3NCKT"
                                                             href="https://app.slack.com/client/T7Q18QQ0Y/D02QGF8A1U2/rimeto_profile/U03H9S3NCKT"
                                                             data-renderer-mark="true">Karolina Urbanovic</a></p>
                    </td>
                </tr>
                </tbody>
            </table>
        </Box>
    );
}