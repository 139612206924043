import * as React from 'react';
import {Box, CircularProgress} from "@mui/material";
import {useGetAllProductsAdminQuery, useGetAllProductsQuery} from "../../core/api/products-api";
import {Outlet, useLocation} from "react-router";
import {useSelector} from "react-redux";
import {isAdminMode} from "../../core/utils/utils";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {useEffect, useState} from "react";
import AdminProductCard from "./AdminProductCard";
import {useUpdateAllProductsMutation} from "../../core/api/products-api";


export default function AdminShop(props) {
    const location = useLocation()
    const isAdmin = isAdminMode(location.pathname);
    const sessionState = useSelector((state) => state.data.UserSession);
    const userSelectedLocation = !isAdmin ? sessionState.usersDetails.location : props.locationFilter;

    const {data: products = [], isFetching, isSuccess, refetch, sortingOrder} = useGetAllProductsAdminQuery(userSelectedLocation);

    const [updateAll, updateAllResponse] = useUpdateAllProductsMutation();

    const [itemList, setItemList] = useState([]);

    useEffect(() => {
        setItemList(products.map(x => {
            return {
                id : x.id,
                content : x
            }
        }).sort(function (a,b){
            let indexA = a.content.sortingOrder;
            let indexB = b.content.sortingOrder;
            if (indexA < indexB) {
                return -1;
            }
            return 1;
        }))
    }, [products]);


    const handleDrop = (droppedItem) => {
        if (!droppedItem.destination) return;
        let updatedList = JSON.parse(JSON.stringify(itemList))
        const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
        updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
        updatedList.map(x => {
            x.content.sortingOrder = updatedList.indexOf(x)
        })
        setItemList(updatedList);
        updateAll({
            productList : updatedList.map(i => ({id : i.content.id, sortingOrder : i.content.sortingOrder}))
        });
    };


    return (
        <Box>
            {isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box sx={{display: 'flex', flexWrap: 'wrap'}} className={"product-cards"}>
                    {
                        isSuccess && itemList.length !== 0 ?
                            <DragDropContext onDragEnd={handleDrop}>
                                <Droppable droppableId="list-container">
                                    {(provided) => (
                                        <div className={"droppable-field"}
                                             {...provided.droppableProps}
                                             ref={provided.innerRef}
                                        >
                                            {itemList.map((item, index) => (
                                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                                    {(provided) => (
                                                        <div
                                                            ref={provided.innerRef}
                                                            {...provided.dragHandleProps}
                                                            {...provided.draggableProps}
                                                        >
                                                            <AdminProductCard
                                                                key={`product_card_${index}`}
                                                                product={item.content}
                                                                isAdminMode={isAdmin}
                                                                refetchProduct={refetch}
                                                            />
                                                        </div>
                                                    )}
                                                </Draggable>
                                            ))}
                                            {provided.placeholder}
                                        </div>
                                    )}
                                </Droppable>
                            </DragDropContext> :
                            <Box>
                                В магазине пока нет продуктов
                            </Box>
                    }
                    <Outlet/>
                </Box>
            }
        </Box>


    );
}

