import * as React from 'react';
import {CardContent, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

export default function ErrorBox(props) {
    return (

        <Box sx={{m: 2}}
             className="page-main-box">
            <Card sx={{maxWidth: 400}}>
                <Typography className="error-header"
                    variant="h5">
                    Произошла ошибка.
                </Typography>
                <CardContent>
                    <Typography
                        variant="h6">
                        {props.error}.
                        Обратитесь за помощью к команде <b>IT automation.</b>
                    </Typography>
                </CardContent>
            </Card>
        </Box>
    );
}