import {Box, CardContent, CircularProgress, Typography} from "@mui/material";
import React, {useEffect, useState} from 'react';
import {useGetVoucherQuery} from "../../core/api/seniority-api";
import Card from "@mui/material/Card";
import ProductCard from "../Shop/ProductCard";
import SeniorityCard from "./SeniorityCard";

export default function Seniority (props) {
    const [state, setState] = useState({
        isVoucherAvailable: false,
        voucher: {}
    })
    const {data: voucherResponse, isSuccess, isLoading} = useGetVoucherQuery();

    useEffect(() => {
        if (isSuccess) {
            if (voucherResponse === null) {
                setState({
                    ...state,
                    isVoucherAvailable: false,
                    voucher: voucherResponse
                })
            } else {
                setState({
                    ...state,
                    isVoucherAvailable: !voucherResponse.isActivated,
                    voucher: voucherResponse
                })
            }
        }
    }, [voucherResponse])


    return (
        isLoading ?
                <CircularProgress/> :
                <Box>
                    {state.isVoucherAvailable ?
                    <Box>
                        <h4>
                            Поздравляем с юбилеем работы в компании Belka Games!
                            <p>Эта вкладка будет доступна в течение 1 месяца. За этот период у тебя будет возможность сделать свой выбор.
                            Если не сделать выбор самостоятельно, то система автоматически выберет начисление 10 белкоинов на твой счет!</p>

                        </h4>
                        <Box sx={{display: "flex"}}>
                            <SeniorityCard
                                voucher={state.voucher}
                                isPresent={true}
                                title={"Подарок на годовщину"}
                            />
                            <SeniorityCard
                                voucher={state.voucher}
                                isPresent={false}
                                title={"10 belcoins"}
                            />
                        </Box>

                    </Box>:
                    <Box sx={{m: 2}}>
                        В данный момент у тебя нет предложений по Seniority program
                    </Box>
                    }
                </Box>
    );
}