import React, {useState} from "react";
import {useGetAllUsersQuery,} from "../../../core/api/users-api"
import {DataGrid, ruRU} from '@mui/x-data-grid';
import {Box, Button, Chip, CircularProgress, Stack, TextField, Typography} from "@mui/material";
import BalancesChangeDialog from "./BalancesChangeDialog";
import {Outlet} from "react-router";
import {Link} from "react-router-dom";
import ExportGridToExcel from "../ExportGridToExcel";
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../../../core/utils/utils";
import ResponseDialog from "../../ResponseDialog";
import {useGetTotalBalanceQuery} from "../../../core/api/balance-api";
import ShowBalanceOnDateDialog from "./ShowBalanceOnDateDialog";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";

const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'ind', headerName: 'ID', width: 50},
    {field: 'fullName', headerName: 'ФИО', width: 200},
    {field: 'balance', headerName: 'Belcoin', width: 150},
    {field: 'birthDay', headerName: 'Дата рождения', width: 150,
        renderCell: (params) => {
         return <div>{format(getDateInServerTimeZone(params.row.birthDate), "dd.MM.yyyy")}</div>;
        }
    },
    {field: 'lastUpdate', headerName: 'Последнее начисление', width: 200},
    {
        field: 'history',
        headerName: 'История',
        rowClassName: 'super-app-theme--header',
        width: 300,
        renderCell: (params) =>
            <Link to={params.row.email}>{"Начисления " + params.row.fullName}</Link>
    },
];

export default function BalanceControl(props) {
    const {data: employeeList = [], isFetching, refetch} = useGetAllUsersQuery();
    const {data: totalBalance, isSuccess: isSuccessTotal} = useGetTotalBalanceQuery();
    const [state, setState] = useState({
        isBalancesChangeDialogOpen: false,
        isBalancesChangeResponseDialogOpen: false,
        controlButtonId: "",
        selectedEmployees: [],
        balanceChangeResponse: {},
        isShowBalanceDialogOpen: false,
    })

    const handleClose = (balanceChangeResponse) => {
        setState({
            ...state,
            isBalancesChangeResponseDialogOpen: balanceChangeResponse.isResponseReady,
            balanceChangeResponse: balanceChangeResponse.data,
            isBalancesChangeDialogOpen: false
        });
    };

    const handleCloseShowBalance = (balanceChangeResponse) => {
        setState({
            ...state,
            isShowBalanceDialogOpen: false
        });
    };

    const handleChangeResponseDialogClose = () => {
        setState({
            ...state,
            isBalancesChangeResponseDialogOpen: false
        });
    };

    const handleChangeBalanceButton = (event) => {
        setState({
            ...state,
            controlButtonId: event.target.id,
            isBalancesChangeDialogOpen: true
        })
    }

    const handleShowBalanceButton = (event) => {
        setState({
            ...state,
            isShowBalanceDialogOpen: true,
        })
    }
    const getEmployeeById = (selectedEmployeesId) => {
        let selectedEmployees = [];
        employeeList.forEach(employee => {
            selectedEmployeesId.forEach(selectedId => {
                if (employee.id === selectedId) {
                    selectedEmployees.push(employee);
                }
            })
        })
        return selectedEmployees;
    }
    const getTotalBelcoinsAmount = () => {
        let totalAmount = 0;
        employeeList.forEach(employee => {
                totalAmount += employee.balance;
            }
        )
        return totalAmount;
    }
    return (
        <Box className='page-main-box'>
            {isFetching ?
                <Box sx={{display: 'flex'}}>
                    <CircularProgress/>
                </Box> :
                <Box sx={{display: 'block', width: '100%'}}>
                    <Box>
                        <Stack spacing={2} direction="row" sx={{pt: 2, alignItems: 'center'}}>
                            <Button id="increase-button"
                                    variant="contained"
                                    color="warning"
                                    style={{ fontSize: '13px' }}
                                    disabled={state.selectedEmployees.length === 0}
                                    onClick={handleChangeBalanceButton}
                            >
                                Начислить
                            </Button>
                            <Button id="decrease-button"
                                    variant="contained"
                                    color="error"
                                    style={{ fontSize: '13px' }}
                                    disabled={state.selectedEmployees.length === 0}
                                    onClick={handleChangeBalanceButton}
                            >
                                Списать
                            </Button>

                            <ExportGridToExcel filename={"balances"}
                                               data={state.selectedEmployees.length === 0 ?
                                                   state.selectedEmployees :
                                                   getEmployeeById(state.selectedEmployees)}/>


                            <Button
                                style={{ fontSize: '13px' }}
                                    variant="contained"
                                    color="error"
                                    onClick={handleShowBalanceButton}
                            >
                                Показать баланс на дату
                            </Button>


                            <Chip
                                sx={{marginLeft: 'auto'}}
                                className="total-belcoins-amount"
                                label={ isSuccessTotal ?
                                <div>
                                    <div>
                                        <Typography variant="caption">
                                            Баланс belcoins:
                                        </Typography>
                                        <Typography variant="title">
                                            {isSuccessTotal ? totalBalance.totalActive : 0}
                                        </Typography>
                                    </div>
                                    <div>
                                        <Typography variant="caption">
                                            Баланс категории "Другое" (резерв):
                                        </Typography>
                                        <Typography variant="title">
                                            {isSuccessTotal ? totalBalance.totalInactive : 0}
                                        </Typography>
                                    </div>
                                </div> :
                                    <CircularProgress size="1rem"/>
                            }
                                icon={<CardGiftcardIcon />}
                            />



                            <ShowBalanceOnDateDialog onClose={handleCloseShowBalance}
                                                     open={state.isShowBalanceDialogOpen}/>
                            <BalancesChangeDialog onClose={handleClose}
                                                  open={state.isBalancesChangeDialogOpen}
                                                  buttonId={state.controlButtonId}
                                                  employeeIdList={state.selectedEmployees}
                                                  refetchBalance={refetch}
                            />
                            {state.balanceChangeResponse &&
                                <ResponseDialog
                                onClose={handleChangeResponseDialogClose}
                                open={state.isBalancesChangeResponseDialogOpen}
                                responseData={state.balanceChangeResponse ? state.balanceChangeResponse : {}}
                                label={"Управление балансом"}
                                refetch={refetch}/>
                            }
                        </Stack>
                    </Box>
                    <Box sx={{width: '100%', height: 600, mt: 2}}>
                        <DataGrid
                            localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                            rows={employeeList}
                            columns={columns}
                            pageSize={10}
                            rowsPerPageOptions={[10]}
                            checkboxSelection
                            onSelectionModelChange={(newSelectionModel) => {
                                setState({...state, selectedEmployees: newSelectionModel})
                            }}
                            selectionModel={state.selectedEmployees}
                        />
                    </Box>
                </Box>
            }
            <Outlet/>
        </Box>
    );

}