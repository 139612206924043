import React, { useState, useCallback } from "react";
import ImageViewer from "react-simple-image-viewer";
import PropTypes from "prop-types";

export default function ProductImageViewer(props) {
    const [currentImage, setCurrentImage] = useState(0);
    const [isViewerOpen, setIsViewerOpen] = useState(false);

    const openImageViewer = useCallback((index) => {
        setCurrentImage(index);
        setIsViewerOpen(true);
    }, []);

    const closeImageViewer = () => {
        setCurrentImage(0);
        setIsViewerOpen(false);
    };
    return (
        <div>
            <div className="imageViewer-main">
                <img
                    src={props.images[0]}
                    onClick={() => openImageViewer(0)}
                    key={0}
                    alt=""
                />
            </div>
            <div className="imageViewer-other">
                {props.images.map((src, index) => (
                    <div>
                        <img
                            src={src}
                            onClick={() => openImageViewer(index)}
                            key={`imageViewer-other_${index}`}
                            alt=""
                        />
                    </div>
                ))}
            </div>

            {isViewerOpen && (
                <ImageViewer
                    src={props.images}
                    currentIndex={currentImage}
                    onClose={closeImageViewer}
                    disableScroll={false}
                    backgroundStyle={{
                        backgroundColor: "rgba(0,0,0,0.9)"
                    }}
                    closeOnClickOutside={true}
                />
            )}
        </div>
    );
}

ProductImageViewer.propTypes = {
    images: PropTypes.array.isRequired
};