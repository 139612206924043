import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Checkbox,
    Chip,
    CircularProgress,
    DialogActions,
    DialogContent, Divider,
    FormControlLabel,
    MenuItem,
    TextField, Typography
} from "@mui/material";
import {useEffect, useState} from "react";
import {useGetTotalBalanceDateQuery} from "../../../core/api/balance-api";
import {format} from "date-fns";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


export default function ShowBalanceOnDateDialog(props) {
    const {onClose, open} = props;

    const [state, setState] = useState({
        isRequestLoading: false,
        isResponseReady: false,
        belcoinsDate: new Date()
    })

    const {data: balance, isLoading, refetch} = useGetTotalBalanceDateQuery(format(state.belcoinsDate, "dd.MM.yyyy HH:mm"));


    useEffect(() => {
        if (balance) {
            setState({
                ...state,
                isRequestLoading: false
            })
        }

    }, [balance])

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: isLoading,
            isResponseReady: false,
        })
        onClose();
    };


    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                {"Баланс белкоинов на руках сотрудников"}
            </DialogTitle>
            <DialogContent >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label="Укажите дату"
                        value={state.belcoinsDate}
                        onChange={(newValue) => {
                            setState({
                                ...state,
                                belcoinsDate: newValue,
                                isRequestLoading: true
                            });
                            refetch(newValue);
                        }}
                        renderInput={(params) => <TextField sx={{mt: 2}}
                                                            {...params} />}
                    />
                </LocalizationProvider>
                { (isLoading || state.isRequestLoading) ?
                    <CircularProgress/> :
                    <Box sx={{mt: 2}}>
                        <Typography>
                            Баланс белкоинов на руках активных сотрудников {format(state.belcoinsDate, "dd.MM.yyyy")} (время 00:00:00):
                        </Typography>
                        <Typography variant="h5" align="center">
                            {balance.totalActive}
                        </Typography>
                        <Typography>
                            Баланс категории "Другое":
                        </Typography>
                        <Typography variant="h5" align="center">
                            {balance.totalInactive}
                        </Typography>
                    </Box>
                }
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Все понятно
                </Button>

            </DialogActions>
        </Dialog>
    );
}
