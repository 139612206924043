import * as React from 'react';
import OrdersHistory from "./OrdersHistory";
import {useSelector} from "react-redux";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {TextField} from "@mui/material";
import ExportOrdersHistory from "../ExportOrdersHistory";
import {useGetUserOrdersQuery} from "../../../core/api/order-api";
import {format} from "date-fns";
import {useState} from "react";

export default function OrdersHistoryAdmin(props) {
    const isUserHasAdminRole = useSelector((state) => state.data.UserSession.user.isAdmin)
    const [state, setState] = useState({
        dateFrom: new Date(new Date().getTime() - (24 * 60 * 60 * 1000) * 90),
        dateTo: new Date()
    });
    const sessionState = useSelector((state) => state.data.UserSession)
    const employeeEmail = sessionState.authenticated ? sessionState.user.username : ''
    const {data: ordersHistory = [], isFetching} = useGetUserOrdersQuery({
        "email" : !props.isUserHistory ? employeeEmail : "",
        "period" : {
            dateFrom: format(state.dateFrom, "dd.MM.yyyy HH:mm"),
            dateTo: format(state.dateTo, "dd.MM.yyyy HH:mm")
        }
    });

    const filterAndDownLoad = isUserHasAdminRole && <>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label="Начало периода"
                value={state.dateFrom}
                onChange={(newValue) => {
                    setState({
                        ...state,
                        dateFrom: newValue
                    });
                }}
                renderInput={(params) => <TextField {...params} />}
            />
        </LocalizationProvider>

        <LocalizationProvider
            dateAdapter={AdapterDateFns}>
            <DatePicker
                label="Конец периода"
                value={state.dateTo}
                onChange={(newValue) => {
                    setState({
                        ...state,
                        dateTo: newValue
                    });
                }}
                renderInput={(params) => <TextField
                    sx={{mr: 2}}
                    {...params} />}
            />
        </LocalizationProvider>
        <ExportOrdersHistory
            filename={"orders history report" + (new Date()).getTime()}
            data={ordersHistory}/>
    </>

    return (
        <OrdersHistory filterAndDownLoad={filterAndDownLoad} ordersHistory = {ordersHistory} isFetching = {isFetching}/>
    )
}