import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, Button, CardActionArea, CardActions, Chip, Tooltip} from '@mui/material';
import PropTypes from "prop-types";
import {useNavigate} from "react-router";
import EditIcon from '@mui/icons-material/Edit';
import CreateProductDialog from "../Admin/CreateProductDialog";
import { useState} from "react";

import {useSelector} from "react-redux";
import DeleteProductDialog from "./DeleteProductDialog";
import ProductPrice from "../Common/PriceComponent";
import ResponseDialog from "../ResponseDialog";
import SaleLabel from "./SaleLabel";
import LimitedLabel from "./LimitedLabel";

export default function ProductCard(props) {
    const [state, setState] = useState({
        isResponseDialogOpen: false,
        responseData: {
            isUpdate: false,
            responseData: {}
        },
        isEditProductDialog: false,
        isDeleteProductDialogOpen: false,
    })
    const navigate = useNavigate();
    const sessionState = useSelector((state) => state.data.UserSession);
    const userBalance = sessionState.usersDetails.balance
    let isNotEnoughBelcoins = props.product.isSale? userBalance < props.product.salePrice : userBalance < props.product.price;

    const handleOrderButton = () => {
        navigate('/products/' + props.product.id, {state: props.product})
    }
    //TODO переделать на компонет линк
    const handleCardClick = () => {
        navigate('/products/' + props.product.id, {state: props.product})
    }
    const handleDeleteProduct = () => {
        setState({
            ...state,
            isDeleteProductDialogOpen: true
        })
    }
    const handleEditButton = () => {
        setState({
            ...state,
            isEditProductDialog: true,
        })
    }

    const handleClose = (responseData) => {
        setState({
            ...state,
            isResponseDialogOpen: responseData.isResponseReady,
            responseData: responseData.data,
            isEditProductDialog: false,
            isDeleteProductDialogOpen: false
        })
    };

    const handleResponseDialogClose = () => {
        setState({
            ...state,
            isResponseDialogOpen: false
        })
    }


    return (
        <Box sx={{p: 1}} className={"product-card"}>
            <Card>
                <CardActionArea>
                    {props.product && props.product.isSale &&
                        <SaleLabel
                            oldPrice={props.product.price}
                            newPrice={props.product.salePrice}
                        />
                    }
                    {props.product.isLimited &&
                        <LimitedLabel
                            labelClassName = {"product-card-limited"}
                            text={props.product.limitedText}
                        />
                    }
                    <Box style={{position:'relative'}}>
                        <CardMedia
                            component="img"
                            height="300"
                            src={props.product.images[0]}
                            image={props.product.images[0]}
                            alt={props.product.title}
                            onClick={handleCardClick}
                        />
                        <CardContent style={{ bottom:'0', width: '100%', display: 'flex', minHeight: '40px',
                            background: 'white', alignItems: 'end', paddingBottom:5, paddingTop:10}}>
                            <Typography
                                lineHeight={'18px'}
                                component="div">
                                {props.product.title}
                            </Typography>
                        </CardContent>
                    </Box>


                </CardActionArea>
                <CardActions sx={{display: 'flex', alignItems: 'end'}} >
                    {
                        props.isAdminMode ?
                            <Box sx={{mb: 2}}>
                                <Chip

                                    label="Delete"
                                    color="warning"
                                    onDelete={handleDeleteProduct}/>
                                <Chip
                                    sx={{ml: 2}}
                                    label="Edit"
                                    color="primary"
                                    icon={<EditIcon/>}
                                    onClick={handleEditButton}
                                >
                                </Chip>
                            </Box> :
                            <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                                { props.product.isSale ?
                                    <ProductPrice
                                        price={props.product.price}
                                        style={"strikeout-price"}
                                        salePrice={props.product.salePrice}
                                    />:
                                    <ProductPrice
                                        price={props.product.price}/>
                                }
                                {isNotEnoughBelcoins ?
                                    <Tooltip title="Недостаточно белкоинов">
                                        <Button
                                            sx={{ml: 2}}
                                            size="small"
                                            variant="outlined"
                                            color="secondary"
                                        >
                                            Заказать
                                        </Button>
                                    </Tooltip> :
                                    <Button
                                        sx={{ml: 2}}
                                        size="small"
                                        variant="outlined"
                                        color="warning"
                                        disabled={isNotEnoughBelcoins}
                                        onClick={handleOrderButton}
                                        title={isNotEnoughBelcoins ? "Недостаточно белкоинов" : null}

                                    >
                                        {props.product.isCharity ? "Пожертвовать" : "Заказать"}
                                    </Button>
                                }

                            </Box>}
                </CardActions>
            </Card>
            <CreateProductDialog
                open={state.isEditProductDialog}
                onClose={handleClose}
                isEditMode={true}
                isCopyMode={false}
                product={props.product}
                refetchProduct={props.refetchProduct}
            />
            <DeleteProductDialog onClose={handleClose}
                                 open={state.isDeleteProductDialogOpen}
                                 productId={props.product.id}
                                 productName={props.product.title}
                                 refetchProduct={props.refetchProduct}
            />
            {state.responseData &&
                <ResponseDialog
                    onClose={handleResponseDialogClose}
                    open={state.isResponseDialogOpen}
                    responseData={state.responseData.responseData ? state.responseData.responseData : {}}
                    label={state.responseData.isUpdate ? "Редактирование продукта" : "Создание продукта"}
                    refetch={props.refetchProduct}
                />
            }
        </Box>

    );
}
ProductCard.propTypes = {
    product: PropTypes.object.isRequired,
    isAdminMode: PropTypes.bool.isRequired,
    refetchProduct: PropTypes.func.isRequired,
};
