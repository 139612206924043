import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Chip, DialogActions, DialogContent, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useUpdateBalanceMutation} from "../../../core/api/balance-api";
import {LoadingButton} from "@mui/lab";
import {useUpdateBudgetMutation} from "../../../core/api/budget-api";


export default function BudgetChangeDialog(props) {
    const {onClose, open, buttonId, categoryIdList} = props;
    const [updateBudget, updateBudgetResponse] = useUpdateBudgetMutation();
    const [state, setState] = useState({
        belcoinsAmount: 0,
        comment: "",
        isUpdateConfirmDialogOpen: false,
        isRequestLoading: false,
        isUpdateBalanceClick: false,
        isResponseReady: false,
        validateError: {}
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isUpdateBalanceClick && (updateBudgetResponse.status !== 'rejected' || updateBudgetResponse.status !== 'fulfilled');
        const isResponseReady = (updateBudgetResponse.status === 'rejected' || updateBudgetResponse.status === 'fulfilled')
        setState({...state,
            isRequestLoading: isResponseLoadingDialog,
            isUpdateBalanceClick: false})
        if (isResponseReady) {
            handleClose(true);
        }

    }, [updateBudgetResponse])

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: false,
            isResponseReady: false,
            isUpdateBalanceClick: false,
            validateError: {},
            isUpdateConfirmDialogOpen: true
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: updateBudgetResponse
        });
    };

    const handleBelcoinsAmountChange = (event) => {
        if (validateRequiredFields()) {
            updateBudget({
                budgetIdList: categoryIdList,
                sum: buttonId === "increase-button" ? state.belcoinsAmount : -state.belcoinsAmount,
                comment: state.comment
            })
            setState({...state,
                isUpdateBalanceClick: true
            })
        }
    };
    const handleAmountChange = (event) => {
        setState({...state, belcoinsAmount: event.target.value})
    }
    const handleCommentChange = (event) => {
        setState({...state, comment: event.target.value})
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.belcoinsAmount <= 0) {
            errors = {...errors, belcoinsAmount: true};
        }
        setState({...state, validateError: errors})
        return Object.keys(errors).length === 0;
    }

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                {buttonId === "increase-button" ?
                    "Сколько белкоинов начислим в бюджет?" :
                    "Сколько белкоинов спишем из бюджета?"}
            </DialogTitle>
            <DialogContent>
                <TextField
                    sx={{mt: 3}}
                    value={state.belcoinsAmount}
                    autoFocus
                    id="belcoins-amount"
                    label="Количество белкоинов"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleAmountChange}
                    type={"number"}
                    fullWidth
                    name="belcoinsAmount"
                    error={state.validateError.belcoinsAmount}
                    helperText={state.validateError.belcoinsAmount
                        ? "Указанно некорректное количество"
                        : ""}
                />
                <TextField
                    sx={{mt: 3}}
                    value={state.comment}
                    id="belcoins-amount-comment"
                    label="Комментарий"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleCommentChange}
                    fullWidth
                />
                <Chip
                    sx={{mt: 3}}
                    size={"small"}
                    color="warning"
                    label={categoryIdList ? "Выбрано категорий: " + categoryIdList.length : 0}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена</Button>
                <LoadingButton
                    size="small"
                    onClick={handleBelcoinsAmountChange}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    {buttonId === "increase-button" ?
                        "Начислить" :
                        "Списать"}
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
}

BudgetChangeDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    buttonId: PropTypes.string.isRequired,
    categoryIdList: PropTypes.array.isRequired,
    refetchBalance: PropTypes.func.isRequired
};
