import React, {useState} from 'react';
import Type from 'prop-types';
import {BrowserRouter, Routes, Route, Link, Navigate} from 'react-router-dom';

import PropTypes from 'prop-types';
import {connect, useSelector} from 'react-redux';

import Helmet from 'react-helmet';
import config from '../config';

import { ReactKeycloakProvider } from "@react-keycloak/web";
import {keycloak} from '../keycloak-config';



import Login from '../components/Login';
import {Container} from "@mui/material";

import Header from '../components/Common/Header'
import "./App.css"
import MainTabs from "../components/MainTabs/MainTabs";
import Admin from "../components/Admin/Admin";
import Shop from "../components/Shop/Shop";
import Rules from "../components/Rules/Rules";
import History from "../components/History/History";
import Balance from "../components/Balance/Balance";
import BalanceControl from "../components/Admin/Balance/BalanceControl";
import OrdersHistory from "../components/Admin/Order/OrdersHistory";
import Product from "../components/Shop/Product";
import ProductControl from "../components/Admin/ProductControl";
import MainLocation from "../components/Site/MainLocation";
import EmployeeBalanceForAdmin from "../components/Admin/Balance/EmployeeBalanceForAdmin";
import NotFound from "../components/NotFound";
import BalanceReport from "../components/Admin/Balance/BalanceReport";
import {store} from "../core/utils/configureStore";
import Loading from "../components/Common/Loading";
import RulesTabs from "../components/Rules/RulesTabs";
import BelcoinsRules from "../components/Rules/BelcoinsRules";
import Order from "../components/Admin/Order/Order";
import InactiveEmployees from "../components/Admin/Balance/InactiveEmployees";
import OrdersHistoryAdmin from "../components/Admin/Order/OrdersHistoryAdmin";
import OrdersHistoryUser from "../components/Admin/Order/OrdersHistoryUser";
import RequestOfAccrual from "../components/Request/RequestOfAccrual";
import ErrorBox from "../components/Common/ErrorBox";
import BudgetControl from "../components/Admin/Budget/BudgetControl";
import Budget from "../components/Admin/Budget/Budget";
import BudgetHistory from "../components/Admin/Budget/BudgetHistory";
import Seniority from "../components/Seniority/Seniority";
import Present from "../components/Seniority/Present";
import SeniorityControl from "../components/Admin/Seniority/SeniorityControl";
export default function App(props) {

    const UserSession = useSelector((state) => state.data.UserSession)
    const [isUser, setIsUser] = useState(false);


    function kcEvent(event) {
        console.log(event)

        if(event === 'onAuthLogout') {
            keycloak.logout();
        }

        if(event === 'onAuthRefreshError') {

        }

        if(event === 'onAuthRefreshSuccess') {
            sessionStorage.setItem('kctoken', keycloak.token);
        }

        if(event === 'onAuthSuccess') {
            if (keycloak.authenticated) {
                sessionStorage.setItem('kctoken', keycloak.token);

                let isAdmin = false;
                let isManager = false;
                if (keycloak.realmAccess.roles.includes("BELCOINSHOP_ADMIN")) {
                    isAdmin = true;
                }
                if (keycloak.realmAccess.roles.includes("BELCOINSHOP_MANAGER")) {
                    isManager = true;
                }
                if (keycloak.realmAccess.roles.includes("BELCOINSHOP_USER")) {
                    setIsUser(true)
                }

                store.dispatch({
                    type: 'AUTH_SELF_SUCCESS', payload: {
                        data: {
                            username: keycloak.idTokenParsed.preferred_username,
                            isAdmin: isAdmin,
                            isManager: isManager
                        }
                    }
                });

            } else {
                store.dispatch({type: 'AUTH_LOGOUT'});
                //keycloak.login();
            }
        }

    }

    function Content (authenticated) {
        if(!authenticated) return <Login />
        else return <BrowserRouter>
            <Routes>
                <Route index element={<MainLocation />} />
                <Route path='/*' element={<MainTabs />} >
                    <Route path='admin/*' element={<Admin />}>
                        <Route path='control' element={<BalanceControl />} />
                        <Route path='control/:employeeEmail' element={<EmployeeBalanceForAdmin />} />
                        <Route path='orderHistory' element={<OrdersHistoryAdmin />} />
                        <Route path='orderHistory/:orderId' element={<Order />} />
                        <Route path='productControl' element={<ProductControl/>}/>
                        <Route path='report' element={<BalanceReport/>}/>
                        <Route path='inactive' element={<InactiveEmployees/>}/>
                        <Route path='inactive/:employeeEmail' element={<EmployeeBalanceForAdmin />} />
                        <Route path='budgetControl' element={<BudgetControl />} />
                        <Route path='budgetHistory' element={<BudgetHistory />} />
                        <Route path='bonus' element={<SeniorityControl />} />
                    </Route>

                    <Route path='orderHistory' element={<OrdersHistoryUser/>} />
                    <Route path='products' element={<Shop />}/>
                    <Route path='products/:productId' element={<Product />} />
                    <Route path='rules/*' element={<RulesTabs/>}>
                        <Route path='shop' element={<Rules/>}/>
                        <Route path='belcoins' element={<BelcoinsRules/>}/>
                    </Route>
                    <Route path='history' element={<OrdersHistoryUser isUserHistory={true}/>} />
                    <Route path='balance' element={<Balance />} />
                    <Route path='request' element={<RequestOfAccrual />} />
                    <Route path='request/budgetBalance' element={<BudgetControl />} />
                    <Route path='seniority' element={<Seniority/>} />
                    <Route path='present' element={<Present/>} />

                    <Route path='*' element={<NotFound />} />
                </Route>
                <Route path='/login' element={<Login />} />
            </Routes>
        </BrowserRouter>
    }


    return (

        <>
            <Helmet {...config.app}></Helmet>
            <Header />
                <ReactKeycloakProvider
                    LoadingComponent={<Loading />}
                    onEvent={kcEvent}

                    initOptions={{
                        onLoad: 'check-sso',
                        silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
                        checkLoginIframeInterval: 1000
                    }}
                    authClient={keycloak}
                >

                        {keycloak.authenticated && !isUser && <ErrorBox error={"Доступ запрещен"}/>}
                        {Content(UserSession.authenticated) }
                </ReactKeycloakProvider>
            <footer>(c) BelkaGames</footer>
        </>
    )


}
