import {Box} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";

export default function SaleLabel(props) {
    const [state, setState] = useState({
        discount: Math.floor((1 - (props.newPrice / props.oldPrice)) * 100)
    })
    return(<Box className={"sale-label"}>
        <Box className={"percents"}>
            {state.discount}%OFF
        </Box>

    </Box>)
}

SaleLabel.propTypes = {
    oldPrice: PropTypes.number.isRequired,
    newPrice: PropTypes.number.isRequired,
}