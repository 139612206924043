import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {Chip, DialogActions, DialogContent, TextField} from "@mui/material";
import {useEffect, useState} from "react";
import {useUpdateBalanceMutation} from "../../../core/api/balance-api";
import ResponseDialog from "../../ResponseDialog";
import {LoadingButton} from "@mui/lab";
import {useCancelOrderMutation} from "../../../core/api/order-api";


export default function OrderCancelDialog(props) {
    const {onClose, open, orderId} = props;
    const [cancelOrder, cancelOrderResponse] = useCancelOrderMutation();
    const [state, setState] = useState({
        reason: "",
        isCancelConfirmDialogOpen: false,
        isRequestLoading: false,
        isCancelOrderClick: false,
        isResponseReady: false,
        validateError: {}
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isCancelOrderClick && (cancelOrderResponse.status !== 'rejected' || cancelOrderResponse.status !== 'fulfilled');
        const isResponseReady = (cancelOrderResponse.status === 'rejected' || cancelOrderResponse.status === 'fulfilled')
        setState({...state,
            isRequestLoading: isResponseLoadingDialog,
            isCancelOrderClick: false})
        if (isResponseReady) {
            handleClose(true);
        }

    }, [cancelOrderResponse])

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: false,
            isResponseReady: false,
            isCancelOrderClick: false,
            validateError: {},
            isCancelConfirmDialogOpen: true
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: cancelOrderResponse
        });
    };

    const handleCancelOrder = (event) => {
        if (validateRequiredFields()) {
            cancelOrder({
                orderId: orderId,
                reason: state.reason
            })
            setState({...state,
                isCancelOrderClick: true
            })
        }
    };

    const handleCommentChange = (event) => {
        setState({...state, reason: event.target.value})
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.reason === null || state.reason.trim() === "") {
            errors = {...errors, reason: true};
        }
        setState({...state, validateError: errors})
        return Object.keys(errors).length === 0;
    }

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
               Подтверждение отмены заказа
            </DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    sx={{mt: 3}}
                    value={state.reason}
                    id="cancel-reason"
                    label="Причина отмены"
                    variant={"outlined"}
                    color="warning"
                    onChange={handleCommentChange}
                    fullWidth
                    name="reason"
                    error={state.validateError.reason}
                    helperText={state.validateError.reason
                        ? "Обязательное поле"
                        : ""}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена</Button>
                <LoadingButton
                    size="small"
                    onClick={handleCancelOrder}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                    Подтвердить
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
}

OrderCancelDialog.propTypes = {
    orderId: PropTypes.string.isRequired,
};
