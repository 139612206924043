import {Box} from "@mui/material";
import PropTypes from "prop-types";
import {useState} from "react";

export default function LimitedLabel(props) {
    const [state, setState] = useState({
        discount: Math.floor((1 - (props.newPrice / props.oldPrice)) * 100)
    })
    return(<Box className={"limited-base " + props.labelClassName}>
        <Box className={"limited-text"}>
            {props.text}
        </Box>

    </Box>)
}

LimitedLabel.propTypes = {
    text: PropTypes.string.isRequired,
}