import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import {Box, Button, CardActionArea, CardActions, Chip, DialogContent, Tooltip} from '@mui/material';
import PropTypes from "prop-types";
import {useNavigate} from "react-router";
import { useState} from "react";

import ChooseBelcoinsDialog from "./ChooseBelcoinsDialog";
import {COINS_PRESENT_LINK, getSeniorityButtonImage, YEAR_1_LINK} from "../../core/utils/utils";


export default function SeniorityCard(props) {
    const [state, setState] = useState({
        isResponseDialogOpen: false,
        responseData: {
            isUpdate: false,
            responseData: {}
        },
        isChooseBelcoinsDialog: false,
        isEditProductDialog: false,
        isDeleteProductDialogOpen: false,
    })
    const navigate = useNavigate();

    const handleChooseBelcoins = () => {
        setState({
            ...state,
            isChooseBelcoinsDialog: true
        })
    }

    const handleCloseBelcoinsDialog = () => {
        setState({
            ...state,
            isChooseBelcoinsDialog: false
        })
    }

    const handleCardClick = () => {
        if (props.isPresent) {
            navigate('/present', {state: props.voucher})
        } else {

        }
    }

    return (
        <Box sx={{p: 1}} className={"product-card"}>
            <Card>
                <CardActionArea>
                    <Box style={{position:'relative'}}>
                        <Box sx={{padding:'40px'}}>
                            <CardMedia
                                component="img"
                                height="250"
                                object-fit={"contain"}
                                src={props.isPresent ? getSeniorityButtonImage(props.voucher): COINS_PRESENT_LINK}
                                image={props.isPresent ? getSeniorityButtonImage(props.voucher) : COINS_PRESENT_LINK}
                                alt={props.title}
                                onClick={handleCardClick}
                                style={{ objectFit: 'contain' }}
                            />
                        </Box>

                        <CardContent style={{ bottom:'0', width: '100%', display: 'flex', minHeight: '40px',
                            background: 'white', alignItems: 'end', paddingBottom:5, paddingTop:10}}>
                            <Typography
                                lineHeight={'18px'}
                                component="div">
                                {props.title}
                            </Typography>
                        </CardContent>
                    </Box>


                </CardActionArea>
                <CardActions sx={{display: 'flex', alignItems: 'end'}} >
                <Box sx={{display: 'flex', justifyContent: 'space-between'}}>
                    {props.isPresent ?
                        <Button
                            sx={{float: "right"}}
                            size="small"
                            variant="outlined"
                            color="warning"
                            onClick={handleCardClick}
                        >
                            Получить
                        </Button> :
                        <Button
                            sx={{float: "right"}}
                            size="small"
                            variant="outlined"
                            color="warning"
                            onClick={handleChooseBelcoins}
                        >
                            Получить
                        </Button>
                    }
                </Box>
                </CardActions>
            </Card>
            <ChooseBelcoinsDialog open={state.isChooseBelcoinsDialog}
                                  onClose = {handleCloseBelcoinsDialog}
                                  voucher = {props.voucher} />
        </Box>

    );
}
SeniorityCard.propTypes = {
    voucher: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    isPresent: PropTypes.bool.isRequired,
};
