import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

export const budgetApi = api.injectEndpoints({
    endpoints: (build) => ({
        getBudget: build.query({
            query: (params) => {return `budget/list`},
        }),
        getBudgetHistory: build.query({
            query: (period) => {return `budget/history?dateFrom=${period.dateFrom}&dateTo=${period.dateTo}`},
            transformResponse: (response, meta, arg) => {
                return  response.map((budgetHistory, index) =>
                    Object.assign({},  {
                        id: budgetHistory.id,
                        budget: budgetHistory.budget.name,
                        sum: budgetHistory.sum,
                        changeType: budgetHistory.changeType ===  "TOP_UP" ? "Пополнение" : "Списание",
                        updatedAt: format(getDateInServerTimeZone(budgetHistory.updatedAt), "dd.MM.yyyy"),
                        authorName: budgetHistory.authorName,
                        receiverName: budgetHistory.receiverName,
                        comment: budgetHistory.comment
                    })
                )
            }
        }),
        updateBudget: build.mutation({
            query: (body = {}) => ({
                url: `budget/update`,
                method: "POST",
                body: body
            }),
            transformResponse: (response, meta, arg) => response,
        }),
        createBudget: build.mutation({
            query: (body = {}) => ({
                url: `budget/create`,
                method: "POST",
                body: body
            }),
            transformResponse: (response, meta, arg) => response,
        })
    }),
})

export const {
    useGetBudgetQuery,
    useGetBudgetHistoryQuery,
    useUpdateBudgetMutation,
    useCreateBudgetMutation,
} = budgetApi