import React from 'react';
import Type from 'prop-types';
import {connect, useDispatch, useSelector, useStore} from "react-redux";

import './styles.scss';
import {Button, Box, AppBar, Toolbar, Typography, Link} from "@mui/material";
import {keycloak} from "../../../keycloak-config";
import Logo from "../../../img/logo.png";

const Header = () => {

    const state = useSelector((state) => state.data.UserSession)

    let login
    if(state.authenticated) {
        login = <Box sx={{display: 'flex'}}>
            <div className={"header__username"}>Привет, {state.usersDetails.fullName}!</div>
            <Button color="warning" variant="contained" onClick={keycloak.logout}>Log out</Button>
        </Box>
    } else {
        login = <Button color="warning" variant="contained" onClick={keycloak.login}>Log in</Button>
    }

    return (
        <Box sx={{ flexGrow: 1, height: '70px' }}>
            <AppBar position="static" color="primary">
                <Toolbar>

                    <Link href={"/"}>
                        <Box

                            component="img"
                            sx={{
                                height: 30,
                            }}
                            alt="Belcoin Shop"
                            src={Logo}
                        />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    <Box sx={{ display: { xs: 'block', md: 'flex' } }}>
                        {login}
                    </Box>

                </Toolbar>
            </AppBar>
        </Box>

    );
}

export default Header;
