import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

export const seniorityApi = api.injectEndpoints({
    endpoints: (build) => ({
        checkVoucher: build.query({
            query: () => `seniority/check`,
            providesTags: ['Seniority']
        }),
        getVoucher: build.query({
            query: () => `seniority/voucher`,
            providesTags: ['Seniority']
        }),
        belcoins: build.mutation({
            query: (body = {}) => ({
                url: `seniority/belcoins`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Seniority'],
            transformResponse: (response, meta, arg) => response,
        }),
        getPresents: build.query({
            query: (voucherId = {}) => ({
                url: `seniority/presents?voucherId=${voucherId}`,
            }),
        }),
        createPresentOrder: build.mutation({
            query: (body = {}) => ({
                url: `seniority/present`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Seniority'],
            transformResponse: (response, meta, arg) => response,
        }),
        getAllVouchers: build.query({
            query: () => ({
                url: `seniority/all`,
            }),
            providesTags: ['Vouchers'],
        }),
        VoucherCreate: build.mutation({
            query: (body = {}) => ({
                url: `seniority/create`,
                method: "POST",
                body: body
            }),
            transformResponse: (response, meta, arg) => response,
        }),
    }),
})

export const {
    useCheckVoucherQuery,
    useGetVoucherQuery,
    useBelcoinsMutation,
    useGetPresentsQuery,
    useCreatePresentOrderMutation,
    useGetAllVouchersQuery,
    useVoucherCreateMutation
} = seniorityApi