import {Box, Chip} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import * as React from "react";
import {useEffect, useState} from "react";
import {useCancelOrdersForInactiveMutation, useKeepOrdersForInactiveMutation} from "../../core/api/order-api";
import ResponseDialog from "../ResponseDialog";

export default function InactiveOrderButton(props) {
    const {employeeEmail} = props;

    const [state, setState] = useState({
        isResponseDialogOpen: false,
        isResponseReady: false,
        isRequestLoading: false,
        response: {},
        isKeep: false,
        isCancel: false
    })

    const [keepOrdersForInActive, keepOrdersResponse] = useKeepOrdersForInactiveMutation();
    const [cancelOrdersForInActive, cancelOrdersResponse] = useCancelOrdersForInactiveMutation();

    useEffect(() => {
        const isReadyToDialog = (keepOrdersResponse.status === 'rejected' || keepOrdersResponse.status === 'fulfilled'
            || cancelOrdersResponse.status === 'rejected' || cancelOrdersResponse.status === 'fulfilled')
        if (isReadyToDialog) {
            setState({
                ...state,
                isRequestLoading: false,
                isResponseReady: true,
                isResponseDialogOpen: true,
                response: Object.keys(keepOrdersResponse).length !== 0 ? keepOrdersResponse : cancelOrdersResponse
            })
        }
    }, [keepOrdersResponse, cancelOrdersResponse])

    const handleKeepOrders = () => {
        setState({
            ...state,
            isKeep: true,
            isCancel: false,
            isRequestLoading: true
        })
        keepOrdersForInActive(employeeEmail);
    }

    const handleCancelOrders = () => {
        setState({
            ...state,
            isCancel: true,
            isKeep: false,
            isRequestLoading: true
        })
        cancelOrdersForInActive(employeeEmail);
    }
    const handleOnClose = () => {
        setState({
            ...state,
            isResponseDialogOpen: false,
            isRequestLoading: false,
            response: {},
            isKeep: false,
            isCancel: false
        })
    }
    return <Box>
        <Box sx={{display: "flex", flexDirection: "column"}}>
            <Chip
                size="small"
                label="Оставить в работе"
                color={"warning"}
                variant={"contained"}
                icon={<CheckCircleIcon/>}
                onClick={handleKeepOrders}
            >
            </Chip>

            <Chip
                size="small"
                label="Отменить"
                color={"warning"}
                variant={"outlined"}
                icon={<CancelIcon/>}
                onClick={handleCancelOrders}
            >
            </Chip>
        </Box>
        {state.isResponseDialogOpen &&
            <ResponseDialog onClose={handleOnClose}
                            open={state.isResponseDialogOpen}
                            responseData={state.response}
                            label={state.isKeep ? "Оставляем заказы в работе" : "Отмена заказов"}/>
        }
    </Box>;
}