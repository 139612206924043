import * as React from 'react';
import {Button} from "@mui/material";
import PropTypes from "prop-types";
import XLSX from "xlsx";
import {COUNTRIES} from "../../constants";

export default function ExportOrdersHistory(props) {
    const findCountryInAddress = (address) => {
        if (address.indexOf(",") !== -1) {
            let addressWithOutPostCode = address.replace(address.substring(0, address.indexOf(",") + 1), "").trim();
            if (addressWithOutPostCode.indexOf(",")!== -1) {
                return addressWithOutPostCode.substring(0, addressWithOutPostCode.indexOf(",")).trim();
            }
        }
        return "";
    }
    const handleDownloadData = () => {

        const groupedData = {};
        props.data.forEach((item) => {
            const productId = item.productId;
            if (!groupedData[productId]) {
                groupedData[productId] = [];
            }
            groupedData[productId].push(item);
        });
        console.log("groupedData")
        console.log(groupedData)
        const sortedData = []
        for (let productId in groupedData) {
            let groupedProducts = groupedData[productId]
            let clonedProductForWholeCategory = {}
            for (let clonedProp in clonedProductForWholeCategory) {
                clonedProductForWholeCategory[clonedProp] = null
            }

            clonedProductForWholeCategory["Название"] = null
            clonedProductForWholeCategory["Адрес"] = null
            clonedProductForWholeCategory["Страна"] = null
            clonedProductForWholeCategory["Размер если применимо"] = null
            clonedProductForWholeCategory["Ссылка на задачу в Асане"] = null
            clonedProductForWholeCategory["Получатель"] = null
            clonedProductForWholeCategory["Доставка офис/адресная"] = null
            clonedProductForWholeCategory["Примечание"] = null
            clonedProductForWholeCategory["Статус"] = null
            clonedProductForWholeCategory["Цена"] = null
            clonedProductForWholeCategory["Количество"] = null
            clonedProductForWholeCategory["Благотворительность"] = null


            sortedData.push(clonedProductForWholeCategory)
            let productVariation = groupedProducts[0]["variation"];
            let setName = true;
            let amount = 0;
            groupedProducts.sort((function(product1, product2) {
                if (product1.variation === null || product2.variation === null) {
                    return 1;
                }
                return product1.variation.localeCompare(product2.variation)
            }));
            for (let product in groupedProducts) {
                // let clonedProduct = {...groupedProducts[product]}
                let clonedProduct = {}

                if (setName) {
                    setName = false;
                    if (productVariation) {
                        sortedData.push({
                            "Название": "Категория: " + groupedProducts[product]["productTitle"] + " размер " + productVariation
                        })
                    } else {
                        sortedData.push({
                            "Название": "Категория: " + groupedProducts[product]["productTitle"]
                        })
                    }
                }

                clonedProduct["Название"] = groupedProducts[product]["productTitle"]
                clonedProduct["Адрес"] = groupedProducts[product]["deliveryAddress"]
                clonedProduct["Страна"] = COUNTRIES.includes(groupedProducts[product]["deliveryAddress"].trim()) ?
                    groupedProducts[product]["deliveryAddress"]: findCountryInAddress(groupedProducts[product]["deliveryAddress"])

                clonedProduct["Размер если применимо"] = groupedProducts[product]["variation"] ? groupedProducts[product]["variation"] : "Не применимо"
                clonedProduct["Ссылка на задачу в Асане"] = groupedProducts[product]["asanaLink"]
                clonedProduct["Получатель"] = groupedProducts[product]["receiverFullName"] ? groupedProducts[product]["receiverFullName"] :
                    groupedProducts[product]["fullName"]
                // clonedProduct["Доставка офис/адресная"] = null
                clonedProduct["Примечание"] = groupedProducts[product]["comment"]
                clonedProduct["Статус"] = groupedProducts[product]["status"]
                clonedProduct["Цена"] = groupedProducts[product]["price"]
                clonedProduct["Количество"] = 1
                clonedProduct["Благотворительность"] = groupedProducts[product]["isCharity"] ? "Да" : "Нет"



                if (groupedProducts[product]["variation"]) {
                    if (productVariation === groupedProducts[product]["variation"]) {
                        productVariation = groupedProducts[product]["variation"];
                        amount++;
                        sortedData.push(clonedProduct)
                    } else {

                        sortedData.push({
                            "Название": "Всего размера " + productVariation,
                            "Количество": amount
                        })
                        sortedData.push(clonedProduct)
                        productVariation = groupedProducts[product]["variation"];
                        amount = 1;
                    }
                } else  {
                   sortedData.push(clonedProduct)
                }
            }

            if (productVariation) {
                setName = true;
                sortedData.push({
                    "Название": "Всего размера " + productVariation,
                    "Количество": amount
                })
                amount = 1;
            }
            let totalQuantity = {
                ...clonedProductForWholeCategory,
                "Название": "Общее количество",
                "Количество": groupedProducts.length
            }
            sortedData.push(totalQuantity)
        }

        const ws = XLSX.utils.json_to_sheet(sortedData);


        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
        XLSX.writeFile(wb, props.filename + ".xlsx");
    }
    return (
        <Button variant="contained"
                color="warning"
                onClick={handleDownloadData}
        >
            Выгрузить в файл
        </Button>
    );
}
ExportOrdersHistory.propTypes = {
    filename: PropTypes.string.isRequired,
    data: PropTypes.array.isRequired,
};