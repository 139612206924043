import {useLocation} from "react-router";
import {Box, Chip} from "@mui/material";

import * as React from "react";
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../../../core/utils/utils";
import {Link} from "react-router-dom";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import {useGetAllUsersQuery, useGetInactiveUsersQuery} from "../../../core/api/users-api";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import {ThumbUpOffAlt} from "@mui/icons-material";
import InactiveOrderButton from "../../Common/InactiveOrderButton";
const columns = [
    {field: 'id', headerName: 'id', hide: true},
    {field: 'ind', headerName: 'ID', width: 50},
    {field: 'fullName', headerName: 'ФИО', width: 300},
    {field: 'balance', headerName: 'Belcoin', width: 150},
    {field: 'fireDate', headerName: 'Дата увольнения', width: 150},
    {
        field: 'history',
        headerName: 'История',
        rowClassName: 'super-app-theme--header',
        width: 250,
        renderCell: (params) =>
            <Link to={`${params.row.email}`}>{"Начисления " + params.row.fullName}</Link>
    },
    {
        field: 'isOrdersCanceled',
        headerName: 'Заказы',
        rowClassName: 'super-app-theme--header',
        width: 300,
        renderCell: (params) =>
            <Box >
                {(params.row.isOrdersCanceled === null || params.row.isOrdersCanceled === undefined) ?
                    <InactiveOrderButton employeeEmail = {params.row.email}/> :
                    <Box>
                        {params.row.isOrdersCanceled === false ?
                            <Box>
                            Остались в работе
                            </Box> :
                            <Box>
                                Отменены
                            </Box>}
                    </Box>
                }
            </Box>
    },
];

export default function InactiveEmployees() {
    const location = useLocation();
    const {data: inactiveEmployeeList = [], isFetching, refetch} = useGetInactiveUsersQuery();
    return (
        <Box>
            <Box sx={{width: '100%', height: 600, mt: 2}}>
                <DataGrid
                    localeText={ruRU.components.MuiDataGrid.defaultProps.localeText}
                    rows={inactiveEmployeeList}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[10]}
                />
            </Box>
        </Box>

    );
}