import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {

    DialogActions,
    DialogContent,
    FormControl,
    InputLabel,
    MenuItem, Select,
    TextField
} from "@mui/material";
import {useEffect, useState} from "react";
import { Autocomplete } from '@mui/material';
import { LoadingButton} from "@mui/lab";
import {useGetAllUsersDetailsQuery} from "../../../core/api/users-api";
import {useVoucherCreateMutation} from "../../../core/api/seniority-api";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

export default function VoucherCreateDialog(props) {
    const {onClose, open, refetchBalance} = props;
    const {data: usersDetails = [], isFetching, isSuccess} = useGetAllUsersDetailsQuery();
    const [createVoucher, voucherUpdateResponse] = useVoucherCreateMutation();

    const [state, setState] = useState({
        employeeWorkingPeriod: 1,
        employeeFullName: "",
        isUpdateConfirmDialogOpen: false,
        isRequestLoading: false,
        isUpdateBalanceClick: false,
        isResponseReady: false,
        validateError: {}
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isUpdateBalanceClick && (voucherUpdateResponse.status !== 'rejected' || voucherUpdateResponse.status !== 'fulfilled');
        const isResponseReady = (voucherUpdateResponse.status === 'rejected' || voucherUpdateResponse.status === 'fulfilled')
        setState({...state,
            isRequestLoading: isResponseLoadingDialog,
            isUpdateBalanceClick: false})
        if (isResponseReady) {
            handleClose(true);
        }

    }, [voucherUpdateResponse])


    const handleSelectEmployee = (employee) => {
        setState({
            ...state,
            employeeFullName: employee != null ? employee.fullName : ""
        })
    }

    const handleClose = (isResponseReady) => {
        setState({...state,
            isRequestLoading: false,
            isResponseReady: false,
            isUpdateBalanceClick: false,
            validateError: {},
            isUpdateConfirmDialogOpen: true
        })
        onClose({
            isResponseReady: isResponseReady === true,
            data: voucherUpdateResponse
        });
    };

    const handleBelcoinsAmountChange = (event) => {
        if (validateRequiredFields()) {
            createVoucher({
                employeeWorkingPeriod: state.employeeWorkingPeriod,
                employeeFullName: state.employeeFullName
            })
            setState({...state,
                isUpdateBalanceClick: true
            })
        }
    };

    const validateRequiredFields = () => {
        let errors = {};
        if (state.employeeWorkingPeriod <= 0) {
            errors = {...errors, employeeWorkingPeriod: true};
        }

        if (state.employeeFullName && state.employeeFullName.trim() === "") {
            errors = {...errors, employeeFullName: true};
        }
        setState({...state, validateError: errors})
        return Object.keys(errors).length === 0;
    }

    const handleVoucherTypeChange = (event) => {
        setState({
            ...state,
            employeeWorkingPeriod: event.target.value
        })
    }

    return (
        <Dialog onClose={handleClose}
                open={open}
        >
            <DialogTitle>
                Создать Seniority bonus для сотрудника
            </DialogTitle>
            <DialogContent>
                <FormControl sx={{mt:2}} fullWidth>
                    <InputLabel color="warning">
                        Годовщина
                    </InputLabel>
                    <Select
                        color="warning"
                        labelId="period-select-label"
                        id="period-select"
                        value={state.employeeWorkingPeriod}
                        label="period"
                        onChange={handleVoucherTypeChange}
                        MenuProps={MenuProps}
                    >
                        <MenuItem key={'YEAR_1'} value={1}> 1 год</MenuItem>
                        <MenuItem key={'YEAR_3'} value={3}> 3 года</MenuItem>
                        <MenuItem key={'YEAR_5'} value={5}> 5 лет</MenuItem>
                        <MenuItem key={'YEAR_7'} value={7}> 7 лет</MenuItem>
                        <MenuItem key={'YEAR_10'} value={10}> 10 лет</MenuItem>
                        <MenuItem key={'YEAR_15'} value={15}> 15 лет</MenuItem>

                    </Select>
                </FormControl>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    options={usersDetails}
                    sx={{mt: 4}}
                    onChange={(event, newValue) => {
                        handleSelectEmployee(newValue);
                    }}
                    renderInput={(params) =>
                        <TextField {...params}
                                   variant="outlined"
                                   color="warning"
                                   name="employeeFullName"
                                   error={state.validateError.employeeFullName}
                                   helperText={state.validateError.employeeFullName
                                       ? "Поле не должно быть пустым."
                                       : ""}
                                   label="Выбери сотрудника"/>}
                />
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}
                >
                    Отмена</Button>
                <LoadingButton
                    size="small"
                    onClick={handleBelcoinsAmountChange}
                    loading={state.isRequestLoading}
                    variant="outlined"
                    color="warning"
                >
                        Создать
                </LoadingButton>

            </DialogActions>
        </Dialog>
    );
}

VoucherCreateDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    refetchBalance: PropTypes.func.isRequired
};
