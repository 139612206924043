import * as React from 'react';
import PropTypes from "prop-types";
import {
    Box,
    Button,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import {useEffect} from "react";

export default function ProductVariationAmountDialog(props) {
    const {onClose, open, variation, locations, locationSizes} = props;
    const [state, setState] = React.useState({
        sizeAmount: locationSizes,
        errors: {}
    });

    useEffect(()=>{
        setState({
            ...state,
            sizeAmount: locationSizes
        })
    }, [locationSizes])
    const handleCloseButton = () => {
        handleClose(null);
    }
    const handleClose = () => {
        onClose(state.sizeAmount)
    }



    function updateAmountLocationItem(amount, location) {
        setState(prevState => {
            const updatedSizeAmount = prevState.sizeAmount.map(item => {
                if (item.location === location) {
                    return { ...item, amount: amount };
                }
                return item;
            });
            return { ...prevState, sizeAmount: updatedSizeAmount };
        });
    }

    function updateSizeMapLocationItem(variation, amount, location) {
        setState(prevState => {
            const updatedSizeAmount = prevState.sizeAmount.map(item => {
                if (item.location === location) {
                    const updatedVariationsAmountMap = new Map(item.variationsAmountMap);
                    updatedVariationsAmountMap.set(variation, amount);
                    return { ...item, variationsAmountMap: updatedVariationsAmountMap };
                }
                return item;
            });
            return { ...prevState, sizeAmount: updatedSizeAmount };
        });


    }




    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogContent>
                <Box sx={{ display: 'flex' }}>
                    <FormControl sx={{ m: 3 }} component="fieldset" variant="standard">
                        <FormLabel
                            sx={{ mb: 2 }}
                            component="legend">
                            Укажи количество в стоке для каждой локации (если все товары на центральном складе в Литве, указываем количество только для Литвы)
                        </FormLabel>
                        {state.sizeAmount.map((locationItem, locationIndex) => (
                            <Box key={locationIndex}>
                                <Typography className={"location-storage"}
                                            variant={"h6"}>
                                    {locationItem.location}:
                                </Typography>
                                <FormGroup>
                                    {variation.length !== 0 && variation.map((variationItem, variationIndex) => (
                                        <Grid container spacing={2} key={`${variationItem}_${locationItem.location}`}>
                                            <Grid item xs={4} sx={{ mt: 3, mb: 1 }}>
                                                <Typography>{variationItem}</Typography>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <FormControlLabel
                                                    id={`${variationItem}_${locationItem.location}`}
                                                    label={""}
                                                    labelPlacement={"start"}
                                                    control={
                                                        <TextField
                                                            sx={{ mb: 1 }}
                                                            value={locationItem.variationsAmountMap.get(variationItem)}
                                                            id={`${variationItem}_${locationItem.location}`} // Unique id attribute
                                                            label="Количество"
                                                            variant="outlined"
                                                            color="warning"
                                                            onChange={(event) => {
                                                                updateSizeMapLocationItem(variationItem, event.target.value, locationItem.location);
                                                            }}
                                                            type="number"
                                                            InputProps={{
                                                                inputProps: {
                                                                    max: 10000, min: 0
                                                                }
                                                            }}                                                            fullWidth
                                                            name={`amount_${variationItem}_${locationItem.location}`} // Unique name attribute
                                                        />
                                                    }
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </FormGroup>

                                <FormGroup>
                                    {
                                        variation.length === 0 &&
                                        <TextField
                                            sx={{mt: 3}}
                                            value={locationItem.amount}
                                            id={`new-product-amount-${locationItem.location}`}
                                            label="Количество"
                                            variant="outlined"
                                            color="warning"
                                            onChange={(event) => {
                                                updateAmountLocationItem(event.target.value, locationItem.location);
                                            }}
                                            type={"number"}
                                            fullWidth
                                            name={`amount_${locationItem.location}`} // Unique name attribute
                                            error={state.errors.amount}
                                            helperText={state.errors.amount
                                                ? "Некорректное значение поля количество"
                                                : ""}
                                        />
                                    }
                                    </FormGroup>

                            </Box>
                        ))}
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleCloseButton}>
                    Отмена
                </Button>
                <Button
                    variant="outlined"
                    color="warning"
                    onClick={handleClose}>
                    Сохранить
                </Button>
            </DialogActions>
        </Dialog>)
        ;
}

ProductVariationAmountDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    locations: PropTypes.array,
    variation: PropTypes.array,
    locationSizes: PropTypes.object.isRequired,
};
