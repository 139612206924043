import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import {
    Box,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
    Typography
} from "@mui/material";
import {createElement, useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import {useCreateOrderMutation} from "../../core/api/order-api";
import {useSelector} from "react-redux";
import ResponseDialog from "../ResponseDialog";
import {Alert, Autocomplete, LoadingButton} from "@mui/lab";
import BcIcon from "../../img/CoinOrange.png";
import {useGetAllUsersDetailsQuery} from "../../core/api/users-api";
import GiftLocation from "./GiftLocation";
import ProductPrice from "../Common/PriceComponent";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";

const Img = styled('img')({
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
});

export default function CreateGiftDialog(props) {
    const {onClose, open, product, variation, inStock} = props;
    const sessionState = useSelector((state) => state.data.UserSession);
    const [crateOrder, createOrderResponse] = useCreateOrderMutation();
    const {data: usersDetails = [], isFetching, isSuccess} = useGetAllUsersDetailsQuery();

    const userSelectedLocation = sessionState.usersDetails.location;

    const isCountryWithoutOffice = (country) => {
        return country === "Россия" || country === "Украина" || country === "Польша";
    }
    const isDeliveryToOffice = userSelectedLocation !== "Другое" &&
        !isCountryWithoutOffice(userSelectedLocation);

    const [state, setState] = useState({
        deliveryAddress: "",
        price: product.isSale ? product.salePrice : product.price,
        receiverFullName: "",
        receiverLocation: "",
        comment: "",
        isProductAvailable: false,
        isCreateButtonClick: false,
        isRequestLoading: false,
        errors: {}
    })


    useEffect(() => {
        const isResponseLoadingDialog = state.isCreateButtonClick && (createOrderResponse.status !== 'rejected' || createOrderResponse.status !== 'fulfilled');
        const isReadyToDialog = (createOrderResponse.status === 'rejected' || createOrderResponse.status === 'fulfilled');
        setState({
            ...state,
            isRequestLoading: isResponseLoadingDialog,
            isCreateButtonClick: false
        })
        if (isReadyToDialog) {
            let dialogResponseInformationElement = null;
            let dialogResponseInformation = "";
            if (createOrderResponse.data) {
                dialogResponseInformationElement = createOrderResponse.data.asanaLink.includes('http') ?
                    createElement('a', {href: createOrderResponse.data.asanaLink}, createOrderResponse.data.asanaLink) :
                    createElement('p', null, createOrderResponse.data.asanaLink);
                dialogResponseInformation = "Ссылка на задачу с заказом:"
                setState({
                    ...state,
                    isRequestLoading: false,
                })


            } else {
                setState({
                    ...state,
                    isRequestLoading: false,
                });
            }
            handleClose(true, {
                response: createOrderResponse,
                dialogResponseInformationElement: dialogResponseInformationElement,
                dialogResponseInformation: dialogResponseInformation,
            });
        }

    }, [createOrderResponse])


    const handleClose = (orderResponseReady, responseData) => {
        setState({
            ...state,
            isRequestLoading: false,
            isCreateButtonClick: false,
            receiverFullName: "",
            receiverLocation: "",
            errors: {},

        })
        onClose({
            isResponseReady: orderResponseReady === true,
            data: responseData
        });
    };

    const handleCreateOrder = () => {
        if (validateRequiredFields()) {
            state.deliveryAddress = state.postcode + ", " + state.country + ", " + state.city + ", " + state.address + ", ";
            crateOrder({
                productId: product.id,
                deliveryAddress: state.receiverLocation,
                variation: variation,
                isDeliveryToOffice: isDeliveryToOffice,
                productLink: window.location.href,
                receiverFullName: state.receiverFullName,
                isGift: true,
                comment: state.comment,
                isProductInStock: inStock,
                isCharity: false
            })
        }
    }

    const validateRequiredFields = () => {
        let errors = {};
        if (state.receiverFullName.trim() === "") {
            errors = {...errors, receiverFullName: true}
        }
        setState({
            ...state,
            errors: errors,
            isCreateButtonClick: true
        })

        return Object.keys(errors).length === 0;
    }

    const handleSelectEmployee = (employee) => {
        setState({
            ...state,
            receiverFullName: employee != null ? employee.fullName : "",
            receiverLocation: employee != null ? employee.location : ""
        })
    }

    const handleChangeAvailability = (value) => {
        setState({
            ...state,
            isProductAvailable: value
        })
    }

    const handleCustomUserPhoneChange = (event) => {
        setState({
            ...state,
            comment: event.target.value
        })
    }

    return (
        <Dialog onClose={handleClose} open={open}>
            <DialogTitle>
                Оформление подарка коллеге
            </DialogTitle>
            <DialogContent>

                <Grid container>
                    <Grid item xs={4}>
                        <div className="create-order__image-wrap">
                            <Img
                                key={`product_image`}
                                sx={{width: "100%"}}
                                alt={product.title}
                                src={product.images[0]}/>
                        </div>

                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={{mb: 3}} gutterBottom variant="body1" component="div">
                            <b>С твоего счета</b> будет списано <span className={"inline-price"}>
                                <ProductPrice price={state.price}/>
                            </span> белкоинов

                        </Typography>
                        <Typography gutterBottom variant="body1" component="div">
                            {product.title}
                        </Typography>
                        <Typography gutterBottom variant="body1" component="div">
                            <div dangerouslySetInnerHTML={{ __html: product.description}}/>
                        </Typography>
                        {
                            variation ?
                                <Typography gutterBottom variant="body1" component="div">
                                    Размер : {variation}
                                </Typography> : null
                        }
                        {
                            !inStock &&
                            <Alert>
                                icon={<ReportProblemOutlinedIcon
                                    style={{ color: '#ff0000' }}
                                    fontSize="inherit" />}
                                severity="warning">
                                Этого товара нет на складе. <br/>
                                Оформив заказ администратор магазина сообщит о возможных сроках реализации заказа.
                                Ориентировочное время ожидания оформления и доставки может составить 2-4 недели. <br/>
                                <strong>Важно!</strong><br/>
                                При заказе необходимо учитывать, что выбранный товар может быть доступен в ограниченном количестве/партии (без возможности повторного изготовления).
                                В таком случаи ваш заказ может быть отменён администратором, а вы получите уведомление.<br/>
                                *время ожидания изготовления и доставки мерча составляет 2-3 месяца (если нет ограничений в данной партии).<br/>
                                *заказ техники оформляется индивидуально.<br/>
                            </Alert>
                        }
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={usersDetails}
                            sx={{mt: 4}}
                            onChange={(event, newValue) => {
                                handleSelectEmployee(newValue);
                            }}
                            renderInput={(params) =>
                                <TextField {...params}
                                           variant="outlined"
                                           color="warning"
                                           name="receiverFullName"
                                           error={state.errors.receiverFullName}
                                           helperText={state.errors.receiverFullName
                                               ? "Поле не должно быть пустым."
                                               : ""}
                                           label="Выбери коллегу"/>}
                        />
                        <GiftLocation
                            productId={product.id}
                            receiverLocation={state.receiverLocation}
                            setAvailability={handleChangeAvailability}
                        />
                        <TextField
                            sx={{mt: 2}}
                            value={state.comment}
                            id="comment"
                            label="Сообщение для получателя"
                            variant="outlined"
                            color="warning"
                            onChange={handleCustomUserPhoneChange}
                            fullWidth
                            name="comment"
                        />
                    </Grid>
                </Grid>

            </DialogContent>
            <DialogActions>
                <Button
                    size="small"
                    color="secondary"
                    onClick={handleClose}>
                    Отмена
                </Button>
                <LoadingButton
                    size="small"
                    variant="outlined"
                    color="warning"
                    onClick={handleCreateOrder}
                    loading={state.isRequestLoading}
                    disabled={!state.isProductAvailable}>
                    Оформить
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
}

CreateGiftDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    product: PropTypes.object.isRequired,
    variation: PropTypes.string.isRequired,
    inStock: PropTypes.bool.isRequired
};
