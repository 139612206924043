import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import {DialogActions, DialogContent} from "@mui/material";
import {LoadingButton} from "@mui/lab";
import Button from "@mui/material/Button";
import {useEffect, useState} from "react";
import {useConfirmDeliveryMutation} from "../../../core/api/order-api";
import ResponseDialog from "../../ResponseDialog";

export * as React from 'react'

export default function DeliveryConfirmDialog(props) {
    const {onClose, open, orderId, orderTitle} = props;
    const [confirmDelivery, confirmDeliveryResponse] = useConfirmDeliveryMutation();

    const [state, setState] = useState({
        isConfirmResponseDialogOpen: false,
        isConfirmResponseReady: false,
        isRequestLoading: false,
    })

    useEffect(() => {
        const isReadyToDialog = (confirmDeliveryResponse.status === 'rejected' || confirmDeliveryResponse.status === 'fulfilled')
        if (isReadyToDialog) {
            setState({
                ...state,
                isRequestLoading: false,
                isConfirmResponseReady: true,
                isConfirmResponseDialogOpen: true
            })
        }
    }, [confirmDeliveryResponse])

    const handleOnClose = () => {
        setState({
            ...state,
            isRequestLoading: false,
            isConfirmResponseReady: false,
            isConfirmResponseDialogOpen: false
        })
        onClose(true);
    }

    const handleConfirmDelivery = async () => {
        setState({
            ...state,
            isRequestLoading: true
        })
        confirmDelivery(orderId)
    }
    return <Dialog onClose={handleOnClose}
                   open={open}>
        <DialogTitle>
            {"Получение заказа"}
        </DialogTitle>
        <DialogContent>
            Вы действительно получили заказ "{orderTitle}"?
        </DialogContent>
        <DialogActions>
            <Button
                size="small"
                color="secondary"
                onClick={handleOnClose}>
                Отмена
            </Button>
            <LoadingButton
                size="small"
                onClick={handleConfirmDelivery}
                loading={state.isRequestLoading}
                variant="outlined"
                color="warning"
            >
                Да
            </LoadingButton>

        </DialogActions>

        {state.isConfirmResponseReady &&
            <ResponseDialog onClose={handleOnClose}
                            open={state.isConfirmResponseDialogOpen}
                            responseData={confirmDeliveryResponse}
                            label={"Получение заказа"}/>
        }

    </Dialog>
}

DeliveryConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    open: PropTypes.bool.isRequired,
    orderId: PropTypes.string.isRequired,
    orderTitle: PropTypes.string.isRequired,
}