import * as React from 'react';
import {Box, Chip, CircularProgress, Tooltip} from "@mui/material";

import PropTypes from "prop-types";
import {ThumbUpOffAlt} from "@mui/icons-material";
import {useGetOrderQuery} from "../../../core/api/order-api";
import {mapOrderStatus} from "../../../core/utils/utils";
import {useEffect, useState} from "react";
import DeliveryConfirmDialog from "./DeliveryConfirmDialog";


export default function ConfirmDeliveryButton(props) {
    const {data: response = {}, isSuccess} = useGetOrderQuery(props.orderId);
    const [state, setState] = useState({
        isNeedToConfirm: false,
        isConfirmed: false,
        isConfirmDialogOpen: false,
        color: "default",
        variant: "outlined"
    })
    useEffect(()=>{
        if (response.status === 'READY') {
            if ((response.isDeliveryConfirmed !== true) || (response.isDeliveryConfirmed === undefined)) {
                setState({
                    ...state,
                    isNeedToConfirm: true,
                    color: "warning",
                    variant: "contained"
                })
            } else if (response.isDeliveryConfirmed === true) {
                setState({
                    ...state,
                    isConfirmed: true,
                    isNeedToConfirm: false,
                    color: "primary",
                    variant: "outlined"
                })
            }
        }
    }, [response])

    const handleConfirm = () => {
        if (state.isNeedToConfirm) {
            setState({
                ...state,
                isConfirmDialogOpen: true
            })
        }
    }

    const handleClose = () => {
        setState({
            ...state,
            isConfirmDialogOpen: false
        })
    }

    return isSuccess ?
        <Box>
            {state.isNeedToConfirm || state.isConfirmed ?
                <Tooltip title={state.isNeedToConfirm ? "Просим подтвердить получение заказа": "Получение подтверждено"}>
                    <Chip label="Получен"
                          size="medium"
                          color={state.color}
                          icon={state.isConfirmed ? <></> : <ThumbUpOffAlt/>}
                          variant={state.variant}
                          onClick={() => handleConfirm(response)}/>
                </Tooltip> :
                <Chip label={mapOrderStatus(response.status)}
                      size="medium"
                      color={state.color}
                      variant={state.variant}
                />
            }
            <DeliveryConfirmDialog onClose={handleClose}
                                   open={state.isConfirmDialogOpen}
                                   orderId={props.orderId}
                                   orderTitle={response.productTitle}/>

    </Box> :
        <CircularProgress/>;
}

ConfirmDeliveryButton.propTypes = {
    orderId: PropTypes.string.isRequired,
};