import api from "./base-api"

export const orderApi = api.injectEndpoints({
    endpoints: (build) => ({
        getMyOrders: build.query({
            query: () => { return "order/list" },
            transformResponse: (response) => {
                return response.map((order, index) =>
                    Object.assign({}, order, {ind: index})
                )
            },
            providesTags: ['Orders']
        }),
        getUserOrders: build.query({
            query: (data) => { return`order/list/all?dateFrom=${data.period.dateFrom}&dateTo=${data.period.dateTo}` },
            transformResponse: (response) => {
                return response.map((order, index) =>
                    Object.assign({}, order, {ind: index})
                )
            },
            providesTags: ['Orders']
        }),
        getOrder: build.query({
            query: (orderId) => {
                return `order/${orderId}`
            },
            providesTags: ['Orders']
        }),
        createOrder: build.mutation({
            query: (body = {}) => ({
                url: `order/create`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['User'],
            transformResponse: (response, meta, arg) => response,
        }),
        cancelOrder: build.mutation({
            query: (body = {}) => ({
                url: `order/cancel`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['User', 'Orders', 'OneProduct'],
            transformResponse: (response, meta, arg) => response,
        }),
        readyOrder: build.mutation({
            query: (body = {}) => ({
                url: `order/complete`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['User', 'Orders', 'OneProduct'],
            transformResponse: (response, meta, arg) => response,
        }),
        confirmDelivery: build.mutation({
            query: (orderId = {}) => ({
                url: `order/confirm/${orderId}`,
                method: "PATCH",
            }),
            invalidatesTags: ['Orders'],
            transformResponse: (response, meta, arg) => response,
        }),
        keepOrdersForInactive: build.mutation({
            query: (email = {}) => ({
                url: `order/keep_inactive/${email}`,
                method: "POST",
            }),
            invalidatesTags: ['Inactive'],
            transformResponse: (response, meta, arg) => response,
        }),
        cancelOrdersForInactive: build.mutation({
            query: (email = {}) => ({
                url: `order/cancel_inactive/${email}`,
                method: "POST",
            }),
            invalidatesTags: ['Inactive'],
            transformResponse: (response, meta, arg) => response,
        })
    }),
})

export const {
    useGetMyOrdersQuery,
    useGetUserOrdersQuery,
    useGetOrderQuery,
    useCreateOrderMutation,
    useCancelOrderMutation,
    useReadyOrderMutation: useCompleteOrderMutation,
    useConfirmDeliveryMutation,
    useKeepOrdersForInactiveMutation,
    useCancelOrdersForInactiveMutation
} = orderApi