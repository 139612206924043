import React from 'react';
import Type from 'prop-types';
import { Route, Router } from 'react-router-dom';

import App from '../../containers/App';
import Header from '../Common/Header';
import NotFoundPage from '../NotFound';

class Site extends React.Component {

    static propTypes = {
    };

    state = {
    };

    render() {
        return (
            <>
                <Header />
                <main className="layout">
                    Main
                </main>
            </>
        );
    }
}

export default Site;
