import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import {CONFIGS} from "../../constants";

const api = createApi({
    baseQuery: fetchBaseQuery({
        baseUrl: CONFIGS.baseUrl,
        prepareHeaders: (headers) => {
            const token = sessionStorage.getItem("kctoken")
            if (token) headers.set("Authorization", `Bearer ${token}`)
            return headers
        }
    }),
    endpoints: () => ({})
})

export default api
