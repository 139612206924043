import * as React from 'react';
import {Box, Button, Chip, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";

export default function ImageUploadButton(props) {
    const [state, setState] = useState({uploadedImages: {images: []}});

    const handleDeleteImage = (value) => {
        const newImagesArray = Object.assign({}, state.uploadedImages)
        newImagesArray.images = newImagesArray.images.filter((image, index) => index !== value)
        setState({...state,
            uploadedImages: newImagesArray})
    }

    const handleChangeFiles = (event) => {
        const newImagesArray = Object.assign({}, state.uploadedImages)
        const files = event.target.files
        if (files.length > 0) {
            let currentImages = newImagesArray.images
            currentImages = currentImages.concat(Array.from(files))
            newImagesArray.images = currentImages
            setState({...state,
                uploadedImages: newImagesArray})
        }
        event.currentTarget.value = null
    }

    useEffect(() => {
        props.setUploadedImagesArray(state.uploadedImages)
    }, [state.uploadedImages])

    return (
        <Box sx={{py: 2}}>
            <Box sx={{textAlign: "center", width: "300px", display: "block", margin: "0 auto"}}><label
                htmlFor={props.labelId}>
                <input
                    style={{display: "none"}}
                    id={props.labelId}
                    accept="image/*,application/pdf"
                    name="images"
                    type="file"
                    multiple
                    onChange={handleChangeFiles}
                />
                <Box>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="warning"
                        component="span">
                        {props.buttonTitle}
                    </Button>
                </Box>
            </label>
            </Box>
            {state.uploadedImages !== undefined &&
                <Stack direction="column"
                       spacing={1} sx={{textAlign: "center", alignItems: "center", marginTop: 2}}>
                    {state.uploadedImages.images.map((image, index) =>
                        <Chip key={`${props.label}_${index}`} sx={{width: "50%", justifyContent: "space-between"}}
                              label={image.name}
                              onDelete={() => handleDeleteImage(index)}/>
                    )}
                </Stack>
            }

        </Box>
    );
}

ImageUploadButton.propTypes = {
    label: PropTypes.string.isRequired,
    labelId: PropTypes.string.isRequired,
    buttonTitle: PropTypes.string.isRequired,
    setUploadedImagesArray: PropTypes.func.isRequired
};