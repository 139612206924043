import * as React from 'react';
import {Box, Typography} from "@mui/material";
import {DataGrid, ruRU} from "@mui/x-data-grid";
import LinkTabs from "../Common/LinkTabs";
import RuleIcon from '@mui/icons-material/Rule';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import {getTabState} from "../../core/utils/utils";
import {useSelector} from "react-redux";
import {Outlet, useLocation} from "react-router";


const RULES_TAB = [
    {
        label: "Правила магазина",
        icon: <RuleIcon/>,
        path: "shop"
    },
    {
        label: "Правила начисления",
        icon: <CurrencyExchangeIcon/>,
        path: "belcoins"
    },
];
export default function RulesTabs() {
    const location = useLocation();
    let rulesTabState = getTabState(RULES_TAB, location.pathname)
    const sessionState = useSelector((state) => state.data.UserSession)
    const isUserHasAdminRole = sessionState.user.isAdmin;
    const isUserHasModeratorRole = sessionState.user.isManager;

    return (
        <Box>
            <LinkTabs
                tabs={RULES_TAB}
                state={rulesTabState}
                hideAdminTab={!isUserHasAdminRole}
                hideRequestTab={!isUserHasModeratorRole}
                hideSeniorityTab={true}
            />
            <Outlet/>
        </Box>

    );
}