
import {Box, Chip, Tooltip} from "@mui/material";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useState} from "react";
import UsersListDialog from "./UsersListLinksDialog";

export default function UserIdsListComponent({data})  {
    const [state, setState] = useState({
        isDialogOpen: false
    })
    const userIds = data;
    let Content = [];

    const handleOpenList = () => {
        setState({
            ...state,
            isDialogOpen: true
        })
    }
    const handleOnClose = () => {
        setState({
            ...state,
            isDialogOpen: false
        })
    }

    if (userIds.length <= 4) {
        userIds.forEach(user => {
            Content.push(<div>{user}</div>)
        })


    } else {
        Content.push(
            <Box className={"users-chip"}>
                <Tooltip title="Показать список сотрудников">
                    <Chip icon={<MoreHorizIcon/>}
                          onClick={handleOpenList}
                    />
                </Tooltip>
            </Box>
        )
    }



    return (
        <Box sx={{mt: 1, mb: 1, display: 'flex', flexDirection: "column"}}>
            {Content}
            <UsersListDialog
                onClose={handleOnClose}
                data={userIds}
                open={state.isDialogOpen}
                name={"Список пользователей"}/>
        </Box>
    )
}

