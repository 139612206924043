import api from "./base-api"
import {format} from "date-fns";
import {getDateInServerTimeZone} from "../utils/utils";

export const accrualRequestApi = api.injectEndpoints({
    endpoints: (build) => ({
        getAllAccrualRequests: build.query({
            query: () => `requests/all`,
            providesTags: ['Requests']
        }),
        getAccrualRequests: build.query({
            query: () => `requests`,
        }),
        createRequest: build.mutation({
            query: (body={} ) => ({
                url: `requests/create`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Requests']
        }),
        approveRequest: build.mutation({
            query: (body) => ({
                url: `requests/approve`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Requests']
        }),
        rejectRequest: build.mutation({
            query: (body) => ({
                url: `requests/reject`,
                method: "POST",
                body: body
            }),
            invalidatesTags: ['Requests']
        }),
        getCategories: build.query({
            query: () => `requests/categories`,
        }),
    }),
})

export const {
    useGetAllAccrualRequestsQuery,
    useGetAccrualRequestsQuery,
    useCreateRequestMutation,
    useApproveRequestMutation,
    useRejectRequestMutation,
    useGetCategoriesQuery
} = accrualRequestApi