import * as React from 'react';
import {Box} from "@mui/material";
import Balance from "../../Balance/Balance";
import {useParams} from "react-router";

export default function EmployeeBalanceForAdmin () {
    const params = useParams()
    const employeeEmail = params.employeeEmail;
    return (
        <Box>
            <Balance employeeEmail={employeeEmail}/>
        </Box>
    );
}