import {useLocation, useNavigate, useParams} from "react-router";
import {
    Box,
    Button,
    CardContent,
    CircularProgress, FormControl,
    FormControlLabel,
    FormLabel, Radio,
    RadioGroup,
    Typography
} from "@mui/material";
import {useCheckVoucherQuery, useGetPresentsQuery, useGetVoucherQuery} from "../../core/api/seniority-api";
import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import Card from "@mui/material/Card";
import PresentProduct from "./PresentProduct";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CreateOrderDialog from "../Shop/CreateOrderDialog";
import ResponseDialog from "../ResponseDialog";
import {Alert} from "@mui/lab";

export default function Present (props) {
    const location = useLocation();
    const navigate = useNavigate();
    const {data: voucher, isError, isLoading} = useGetVoucherQuery();
    const {data: isVoucherActive} = useCheckVoucherQuery();
    const {data: presents, isLoading: isPresentLoading, isSuccess} = useGetPresentsQuery(voucher ? voucher.id: "");

    const [state, setState] = useState({
        isVoucherError: isError? isError : !isVoucherActive,
        error: {},
        isCreateOrderDialogOpen: false,
        selectedPresentVariations: new Map(),
        selectedPresentVariationsError: new Map(),
        voucher: {},
        isCreateOrderResponseOpen: false,
        createOrderResponse: {},
        selectedPresent: "",
        selected:"",
        isForSelect: presents && presents.length > 0 && presents[0].isForSelect

    })


    useEffect(()=> {
        setState({
            ...state,
            voucher: voucher,
            isVoucherError: isError? isError : !isVoucherActive,
            isForSelect: presents && presents.length > 0 && presents[0].isForSelect
        })
    }, [voucher, presents])

    const selectVariations = (productId, variation) => {
        let selectVariationsMap = state.selectedPresentVariations;
        selectVariationsMap.set(productId, variation);
        setState({
            ...state,
            selectedPresentVariations: selectVariationsMap
        })
    }
    const getPresentForOrder = () => {
        if (presents) {
            if (state.isForSelect) {
                let test = presents.filter(present => present.id === state.selected);
                return test;
            } else {
                return presents;
            }
        }
    }

  const handleSetVariationError = (productId, variation) => {
        let variationErrorMap = state.selectedPresentVariationsError;
        variationErrorMap.set(productId, false);
        setState({
            ...state,
            selectedPresentVariationsError: variationErrorMap
        })
    }

    const handleBackButton = () => {
        navigate('/seniority');
    }

    const handleOrder = (productId) => {

        presents.forEach(present => {
            if (state.isForSelect) {
                if (present.id === productId) {
                    if (present.variations !== null && present.variations.length !== 0 && state.selectedPresentVariations.get(present.id) === undefined) {
                        let variationErrorMap = state.selectedPresentVariationsError;
                        variationErrorMap.set(present.id, true);
                        setState({
                            ...state,
                            selected: productId,
                            selectedPresentVariationsError: variationErrorMap
                        })
                    } else {
                        setState({
                            ...state,
                            selected: productId,
                            isCreateOrderDialogOpen: true
                        })
                    }
                }
            } else {
                if (present.variations !== null && present.variations.length !== 0 && state.selectedPresentVariations.get(present.id) === undefined) {
                    let variationErrorMap = state.selectedPresentVariationsError;
                    variationErrorMap.set(present.id, true);
                    setState({
                        ...state,
                        selectedPresentVariationsError: variationErrorMap
                    })
                } else {
                    setState({
                        ...state,
                        isCreateOrderDialogOpen: true
                    })
                }
            }
        })
    }

    const handleCloseCreatingDialog = (createResponse) => {
       setState({
           ...state,
           isCreateOrderDialogOpen: false,
           selectedPresentVariationsError: new Map(),
           isCreateOrderResponseOpen: createResponse.isResponseReady,
           createOrderResponse: createResponse.data,
       })
    }

    const handleResponseDialogClose = () => {
        navigate("/products")
        setState({
            ...state,
            isCreateOrderResponseOpen: false,
            createOrderResponse: {}
        })
    }

    function handleSelectedPresentChange(event) {
        setState({ ...state,
            selected: event.target.value });
    }

    function handleSelectPresent(productId) {
        handleOrder(productId);
    }

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            {
                isPresentLoading ?
                    <CircularProgress/> :
                state.isVoucherError ?
                    <Box sx={{m: 2}}>
                        В данный момент у тебя нет предложений по Seniority program
                    </Box>:
                    presents.length === 0 ?
                        <Box>
                            Ничего не найдено
                        </Box>:
                        <Box>
                        <Button sx={{mb: 2}}
                                variant="outlined"
                                color="error"
                                onClick={handleBackButton}
                                startIcon={<ArrowBackIcon/>}>
                            Назад
                        </Button>
                            {state.isForSelect &&
                                <Alert
                                    severity="info">
                                    <b>Можно выбрать только один подарок</b>
                                </Alert>
                            }
                            <Box sx={{display: "flex"}}>
                                {
                                    presents && presents.map(present => (
                                        <PresentProduct
                                            product={present}
                                            selectVariations={selectVariations}
                                            variationErrors={state.selectedPresentVariationsError.get(present.id) ? state.selectedPresentVariationsError.get(present.id) : false}
                                            setVariationErrors={handleSetVariationError}
                                            isSelected={state.selected === present.id}
                                            isForSelect={state.isForSelect}
                                            selectPresent={handleSelectPresent}/>
                                    ))
                                }
                            </Box>

                            {!state.isForSelect && <Button
                                sx={{mt: 2}}
                                id="order-button"
                                variant="contained"
                                color="warning"
                                onClick={handleOrder}

                            >
                                Оформить
                            </Button>
                            }

                        <CreateOrderDialog open={state.isCreateOrderDialogOpen}
                                           onClose={handleCloseCreatingDialog}
                                           inStock={false}
                                           product={{}}
                                           variation={""}
                                           isPresent={true}
                                           presents={{
                                               products: getPresentForOrder(),
                                               variations: state.selectedPresentVariations
                                           }}
                                           voucher={state.voucher}
                        />




                    </Box>
            }
            {state.createOrderResponse &&
                <ResponseDialog
                    onClose={handleResponseDialogClose}
                    open={state.isCreateOrderResponseOpen}
                    responseData={state.createOrderResponse.response ? state.createOrderResponse.response : {}}
                    label={"Оформление заказа"}
                    information={state.createOrderResponse.dialogResponseInformation}
                    element={state.createOrderResponse.dialogResponseInformationElement}
                />
            }
                </Box>);
}