import {Box} from "@mui/material";
import * as React from "react";

export default function BelcoinsRules() {
    return (
        <Box>
            <table data-number-column="false">
                <tbody>
                <tr className={"table-header"}>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="4"><span
                            className="fabric-text-color-mark" data-renderer-mark="true"
                            data-text-custom-color="#ffffff">За что начисляются BelCoins?</span></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <p data-renderer-start-pos="36"><span
                            className="fabric-text-color-mark" data-renderer-mark="true"
                            data-text-custom-color="#ffffff">Стоимость</span></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="49"><span
                            className="fabric-text-color-mark" data-renderer-mark="true"
                            data-text-custom-color="#ffffff">Кто может начислять BelCoins?</span></p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="84">Участие в конкурсах Event-команды</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="121">до 5</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="129">Команда Event</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="148">Участие в конкурсах PR-команды</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="182">до 3</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="190">Команда PR</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="206">Спикер на Белконах</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="228">2</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="233">Команда Event</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="252">Спикер внешних ивентов</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="278">5</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="283">Команда PR</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="299">День рождения</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="316">5</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="321">Система (автоначисление)</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="340"><a className="css-tgpl01"
                                                            title="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3644981329/Belcoins#%D0%9A%D0%B0%D0%BA-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%8C-Belcoins-%D0%BD%D0%B0-%D1%81%D0%B2%D0%B0%D0%B4%D1%8C%D0%B1%D1%83"
                                                            href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3644981329/Belcoins#%D0%9A%D0%B0%D0%BA-%D0%BF%D0%BE%D0%BB%D1%83%D1%87%D0%B8%D1%82%D1%8C-Belcoins-%D0%BD%D0%B0-%D1%81%D0%B2%D0%B0%D0%B4%D1%8C%D0%B1%D1%83"
                                                            data-renderer-mark="true">Свадьба</a></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="351">10</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="357">Администратор магазина</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="376">Стаж работы в компании (2 года)</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="411">10</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="417">Система (автоначисление)</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="436">Стаж работы в компании (4 года)</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="471">10</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="477">Система (автоначисление)</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="496">Стаж работы в компании (6 лет)</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="530">10</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="536">Система (автоначисление)</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="555">Стаж работы в компании (от 8-9 лет)</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="594">10</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="600">Система (автоначисление)</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="619">Стаж работы в компании (от 11-14 лет)</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="660">10</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="666">Система (автоначисление)</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="685">Прохождение ИС</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="703">5</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="708">
                            Офис-менеджер/региональный директор локации трудоустройства</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="724"><a className="css-tgpl01"
                                                            title="/wiki/spaces/BC/pages/3204743252"
                                                            href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3204743252"
                                                            data-renderer-mark="true">Проведение внутренних лекций</a>
                        </p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="755">2</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="760">Команда HR</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="724">Написание статей на внешних ресурсах</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="764">3</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="769">Команда PR</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="837"><a className="css-tgpl01"
                                                            title="/wiki/spaces/GD/pages/1020363092"
                                                            href="https://belka-games.atlassian.net/wiki/spaces/GD/pages/1020363092"
                                                            data-renderer-mark="true">Belka Experts</a></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="854">2</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="859">Организатор конкурса</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="885"><a className="css-tgpl01"
                                                            title="/wiki/spaces/QA/pages/3643736081"
                                                            href="https://belka-games.atlassian.net/wiki/spaces/QA/pages/3643736081"
                                                            data-renderer-mark="true">Belka Bugs</a></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="899">до 2</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="907">Организатор конкурса</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="986">Спикер на <a className="css-tgpl01"
                                                                      title="/wiki/spaces/GD/pages/3739091085"
                                                                      href="https://belka-games.atlassian.net/wiki/spaces/GD/pages/3739091085"
                                                                      data-renderer-mark="true">GD Breakfast</a></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark fabric-editor-alignment css-1mg5rgz"
                             data-align="center">
                            <p data-renderer-start-pos="1012">2</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="1017">Организатор конкурса</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="933">Итервью на радио</p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark css-1mg5rgz" data-align="center">
                            <p data-renderer-start-pos="953">1</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310">
                        <p data-renderer-start-pos="958">Команда PR</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="1200"><a className="css-tgpl01"
                                                             title="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3644981329/Belcoins#%D0%A0%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D0%B5-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%BD%D0%B5%D0%B9-%D0%BA%D0%BE%D1%80%D0%BF%D0%BE%D1%80%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%BE%D0%B9-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D1%8B"
                                                             href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3644981329/Belcoins#%D0%A0%D0%B0%D0%B7%D0%B2%D0%B8%D1%82%D0%B8%D0%B5-%D0%B2%D0%BD%D1%83%D1%82%D1%80%D0%B5%D0%BD%D0%BD%D0%B5%D0%B9-%D0%BA%D0%BE%D1%80%D0%BF%D0%BE%D1%80%D0%B0%D1%82%D0%B8%D0%B2%D0%BD%D0%BE%D0%B9-%D0%BA%D1%83%D0%BB%D1%8C%D1%82%D1%83%D1%80%D1%8B"
                                                             data-testid="link-with-safety" data-renderer-mark="true">Развитие
                            внутренней корпоративной культуры</a></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark fabric-editor-alignment css-1mg5rgz"
                             data-align="center">
                            <p data-renderer-start-pos="1246">до 2</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="1254">Команда Event</p>
                    </td>
                </tr>
                <tr>
                    <td colSpan="1" rowSpan="1" data-colwidth="310" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="1282"><a className="css-tgpl01"
                                                             title="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3644981329/Belcoins#%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%B8%D0%B5-%D0%B2--cross-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0%D1%85"
                                                             href="https://belka-games.atlassian.net/wiki/spaces/BC/pages/3644981329/Belcoins#%D0%A3%D1%87%D0%B0%D1%81%D1%82%D0%B8%D0%B5-%D0%B2--cross-%D0%BF%D1%80%D0%BE%D0%B5%D0%BA%D1%82%D0%B0%D1%85"
                                                             data-testid="link-with-safety" data-renderer-mark="true">Участие
                            в cross проектах</a></p>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="140">
                        <div className="fabric-editor-block-mark fabric-editor-alignment css-1mg5rgz"
                             data-align="center">
                            <p data-renderer-start-pos="1312">до 5</p>
                        </div>
                    </td>
                    <td colSpan="1" rowSpan="1" data-colwidth="310" data-cell-background="#ffffff">
                        <p data-renderer-start-pos="1320">Руководители отделов</p>
                    </td>
                </tr>
                </tbody>
            </table>
        </Box>);
}