import * as React from 'react';
import {useState} from 'react';
import {Box, Chip, Tooltip} from "@mui/material";

import PropTypes from "prop-types";
import {ThumbUpOffAlt} from "@mui/icons-material";
import BlockIcon from '@mui/icons-material/Block';
import ApproveConfirmDialog from "./ApproveConfirmDialog";
import {useGetAllAccrualRequestsQuery} from "../../core/api/accrual-request-api";


export default function ActionAccrualRequestButton(props) {

    const {data: accrualRequest = [], isSuccess, refetch} = useGetAllAccrualRequestsQuery();

    const [state, setState] = useState({
        isConfirmDialogOpen: false,
        isApproveButtonClicked: false,
        color: "default",
        variant: "outlined"
    })


    const handleConfirm = () => {
        setState({
            ...state,
            isConfirmDialogOpen: true,
            isApproveButtonClicked: true
        })
    }

    const handleReject = () => {
        setState({
            ...state,
            isConfirmDialogOpen: true,
            isApproveButtonClicked: false
        })
    }

    const handleClose = () => {
        refetch();
        setState({
            ...state,
            isConfirmDialogOpen: false
        })
    }

    return (<Box>
        <Box>
            <Tooltip title={"Подтвердить начисление белкоинов"}>
                <Chip label="Одобрить"
                      size="small"
                      color={"warning"}
                      icon={<ThumbUpOffAlt/>}
                      variant={"contained"}
                      onClick={() => handleConfirm()}/>
            </Tooltip>
        </Box>
        <Box>
            <Tooltip sx={{mt:1}}
                     title={"Отклонить начисление белкоинов"}>
                <Chip label="Отклонить"
                      size="small"
                      color={"warning"}
                      icon={<BlockIcon/>}
                      variant={"outlined"}
                      onClick={() => handleReject()}/>
            </Tooltip>
        </Box>
        <ApproveConfirmDialog onClose={handleClose}
                              open={state.isConfirmDialogOpen}
                              requestId={props.request.id}
                              isApprove={state.isApproveButtonClicked}
                              refetch={refetch}
        />

    </Box>);
}

ActionAccrualRequestButton.propTypes = {
    request: PropTypes.object.isRequired,
};