import * as React from 'react';
import {useGetMyOrdersQuery} from "../../../core/api/order-api";
import {useSelector} from "react-redux";
import OrdersHistory from "./OrdersHistory";

export default function OrdersHistoryUser(props) {
    const {data: ordersHistory = [], isFetching} = useGetMyOrdersQuery();
    return (
        <OrdersHistory isUserHistory={props.isUserHistory}
                       ordersHistory = {ordersHistory}
                       isFetching = {isFetching}/>
    )
}